import React, { useEffect, useContext, useState } from "react";
import { BreadcrumbContext } from "./SettingsWrapper";
import { SidebarContext } from "../../components/layouts/Main/Main";
import { FormattedMessage } from "react-intl";
import { SettingsSidebar } from "./SettingsSidebar";
import { FormControl } from "../../components/form/FormContext";
import { Link } from "react-router-dom";
import { InputField } from "../../components/form/InputField";
import { ToggleField } from "../../components/form/ToggleField";
import Loading from "../../components/loading/Loading";
import { application } from "../../helpers/axiosModules";
import { ActionType } from "../../models/ActionType";
import { permissionsGet, permissionsUpdate } from "../../api/routes-app";

export function EditPermissionPage({ history, match }) {
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);
  const setSidebarChildren = useContext(SidebarContext);
  const [permission, setPermission] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const p = await Promise.all([
        application.get(
          `${permissionsGet(match.params.id)}?cachetick=${Math.random()}`
        ),
      ]);
      const _permission = {
        _id: p[0].data._id,
        name: p[0].data.name,
      };
      Object.keys(ActionType).map((el) => {
        _permission[`action.${el}`] = p[0].data.actions.indexOf(el) >= 0;
      });
      setPermission(_permission);
      setLoading(false);
    }

    fetchData();
  }, []);

  useEffect(() => {
    setBreadcrumbRightContent({
      display: ["Edit Permission"],
      backButton: true,
    });

    setSidebarChildren(null);

    return () => {
      setSidebarChildren(<SettingsSidebar />);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.debug(permission);
    setLoading(true);
    application
      .put(permissionsUpdate(permission._id), {
        name: permission.name,
        actions: Object.keys(ActionType).filter(
          (el) => permission[`action.${el}`]
        ),
      })
      .then(() => {
        setLoading(false);
        history.push("/settings/permissions");
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="card">
      <FormControl
        onChange={(values) => {
          setPermission(values);
        }}
        values={permission}
        onSubmit={handleSubmit}
      >
        <div className="card-body">
          <div className="mx-5 px-5">
            <fieldset>
              <legend>
                <FormattedMessage id="common.name" />
              </legend>
              <InputField maxLength="256" controlName="name" required={true} />
              <div className="py-2">
                <div className="text-bold mb-4 mt-5">
                  <FormattedMessage id="permissions.actions" />
                </div>
                <div>
                  {Object.keys(ActionType).map((action, i) => (
                    <ToggleField
                      controlName={`action.${action}`}
                      labelText={
                        <FormattedMessage id={`permissions.${action}`} />
                      }
                      spaceBetween={true}
                      key={i}
                    />
                  ))}
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="border-top p-4 d-flex justify-content-around">
          <Link to={`/settings/permissions`}>
            <button
              className="btn btn-outline-secondary p-4"
              style={{ width: 270 }}
            >
              <FormattedMessage id="common.cancel" />
            </button>
          </Link>
          <button className="btn btn-success p-4" style={{ width: 270 }}>
            <FormattedMessage id="common.confirm" />
          </button>
        </div>
      </FormControl>
    </div>
  );
}
