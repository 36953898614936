import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormContext } from './FormContext';
import { toggleStyle } from '../../whitelabel/toggleStyle';
import { ReactComponent as IconCheck } from '../../assets/svg-icons/Icon-check.svg';

export function ToggleField({controlName, labelText, spaceBetween = false}) {
  const [checked, setChecked] = useState(false);
  const { handleInput, formState } = useContext(FormContext);

  useEffect(() => {
    if (typeof formState[controlName] === 'boolean') {
      setChecked(formState[controlName]);
    }
  }, [formState, formState[controlName]]);

  return (
    <label className={`d-flex align-items-center pointer-cursor ${spaceBetween ? 'justify-content-between' : ''}`} style={{height: '30px'}}>
      {labelText && <span className='mr-4'>{labelText}</span>}

      {typeof checked === 'boolean' &&
        (
          <CheckBoxWrapper>
            <CheckBox
              id={controlName}
              type='checkbox'
              checked={checked}
              onChange={e => handleInput({name: controlName, value: e.target.checked})}
            />
            <CheckBoxLabel htmlFor={controlName} />
            {
              checked && <WrapperIcon><IconCheck fill={toggleStyle.checkedColor} /></WrapperIcon>
            }
          </CheckBoxWrapper>
        )
      }
    </label>
  )
}

const CheckBoxWrapper = styled.div`
  position: relative;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 28px;
  border-radius: 15px;
  background: ${toggleStyle.uncheckedColor};
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    margin: 3px;
    background: #ffffff;
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 28px;
  &:checked + ${CheckBoxLabel} {
    background: ${toggleStyle.checkedColor};
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      margin-left: 25px;
      transition: 0.2s;
    }
  }
`;

const WrapperIcon = styled.div`
  position: absolute;
  top: 0;
  right: 15%;
`;

ToggleField.propTypes = {
  controlName: PropTypes.string.isRequired,
  labelText: PropTypes.any,
  spaceBetween: PropTypes.bool,
  values: PropTypes.any
};
