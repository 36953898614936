import React, { useRef } from "react";
import MaterialTable, { MTableBody, MTableBodyRow } from "material-table";
// import { ReactComponent as NextIcon } from "../assets/svg-icons/Icon-baseline-chevron_right.svg";
// import { ReactComponent as PreviousIcon } from "../assets/svg-icons/Icon-baseline-chevron_left.svg";
// import { ReactComponent as FirstPageIcon } from "../assets/svg-icons/Icon-baseline-first_page.svg";
// import { ReactComponent as LastPageIcon } from "../assets/svg-icons/Icon-baseline-last_page.svg";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import CustomToolbar from "./CustomToolbar";
// import CustomPagination from "./CustomPagination";
// import InfiScroller from "react-infi-scroller";

export const Status = ({ value }) => (
  <div className="d-flex align-items-center">
    <span
      className="mr-3"
      style={{
        borderRadius: 6,
        width: 9,
        height: 9,
        backgroundColor: value === "active" ? "#15ce9a" : "#acbbcd",
      }}
    />
    <FormattedMessage id={`common.${value}`} />
  </div>
);

export function Table({
  fullBody,
  columns = [],
  data = [],
  actions = [],
  onRowClick,
  paging = true,
  filtering = false,
  search = false,
  offsetTop = 250,
  toolbar = false,
  tableRef,
  onCooperativeChanged,
  onRegionChanged,
  pickerFilters,
  loadingType,
  isLoading,
  infiniteScroll,

  ...rest
}) {
  return (
    <MaterialTable
      tableRef={tableRef}
      components={{
        Container: (props) => {
          return <div style={{ width: "100%" }}>{props.children}</div>;
        },
        Toolbar: (props) => <CustomToolbar tableProps={props} />,
        // Pagination: (props) => <CustomPagination {...props} />,
        /*Row: (props) => {
          return <MTableBodyRow {...props} key={data} />;
        },*/
      }}
      columns={columns}
      data={data}
      actions={actions}
      onRowClick={onRowClick}
      options={{
        headerStyle: {
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
          fontSize: ".9rem",
          fontWeight: "bold",
        },

        filtering: false,
        search: false,
        maxBodyHeight: fullBody ? undefined : `calc(100vh - ${offsetTop}px)`,
        pageSizeOptions: [9999],
        // pageSize:
        //   tableRef && tableRef.current
        //     ? tableRef.current.dataManager.pageSize
        //     : 10,
        actionsColumnIndex: -1,
        showFirstLastPageButtons: false,
        paging: false,
        toolbar,
        ...(loadingType ? { loadingType } : {}),
      }}
      // icons={{
      //   FirstPage: FirstPageIcon,
      //   LastPage: LastPageIcon,
      //   NextPage: NextIcon,
      //   PreviousPage: PreviousIcon,
      // }}
      isLoading={isLoading}
      localization={{
        header: {
          actions: "",
        },
        body: {
          emptyDataSourceMessage: isLoading ? "Loading..." : "No record found",
        },
        // toolbar: {
        //   searchTooltip: "Search",
        //   searchPlaceholder: "Search",
        // },

        // pagination: {
        //   firstAriaLabel: "First page",
        //   firstTooltip: "First page",
        //   previousAriaLabel: "Prev",
        //   previousTooltip: "Prev",
        //   nextAriaLabel: "Next",
        //   nextTooltip: "Next",
        //   lastAriaLabel: "Last page",
        //   lastTooltip: "Last page",
        //   labelDisplayedRows: "{from}-{to} of {count}",
        //   labelRowsSelect: "",
        //   labelRowsPerPage: "Lines per page:",
        // },
      }}
      {...rest}
    />
  );
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  actions: PropTypes.array,
  paging: PropTypes.bool,
  offsetTop: PropTypes.number,
};
