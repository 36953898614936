import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { BreadCrumb, BreadcrumbContext } from '../../components/BreadCrumb';

import { ProducersList } from './ProducersList';
import { HandleProducerWithRouter } from './HandleProducer';

export function ProducersWrapper({history}) {
  const [currentProducer, setCurrentProducer] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState({
    filter: null,
    filterIcon: null,
    onSearch: null
  });

  return (
    <BreadcrumbContext.Provider value={setBreadcrumb}>
      <BreadCrumb
        history={history}
        display={currentProducer}
        backButton={!!currentProducer}
        backTo='/producers'
        filter={breadcrumb.filter}
        onSearch={breadcrumb.onSearch}
        rightContent={breadcrumb.rightContent}
      />

      <div className='card'>
        <Switch>
          <Route exact path='/producers' component={ProducersList}/>
          <Route path='/producers/:id'>
            <HandleProducerWithRouter onProducerChange={(producer) =>
              producer ? setCurrentProducer([producer.name]) : setCurrentProducer(null)
            } />
          </Route>
        </Switch>
      </div>
    </BreadcrumbContext.Provider>
  )
}
