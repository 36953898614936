import React from "react";
import {ReactComponent as SearchIcon} from "../assets/svg-icons/Icon-search.svg";

// TODO: Refactor it for a generic implementation
const resetInputs = () => {
  const inputs = document.querySelectorAll('.custom-filter input');
  Array.prototype.slice.call(inputs).map(input => {
    if(input.type === 'radio') {
      input.checked = false;
    } else {
      input.value = '';
    }
  });
};

export const resetTableSearchAndFilter = (tableRef) => {
  const { current, current: {state: {columns}}} = tableRef;
  current.onSearchChange('');
  columns.map((column, index) => current.onFilterChange(index, ''));
  resetInputs();
};

const CustomFilter = ({ tableRef: { current: ref }, intl }) => {
  console.debug('Table ref: ', ref);
  if (!ref) return null;

  const handleChange = (e) => {
    const {nativeEvent: { target, target: { value }}} = e;
    const columnId = target.getAttribute('columnId');
    ref.onFilterChange(columnId, value);
  };

  const { state: {columns}} = ref;
  console.debug('Table columns: ', columns);
  return (
    <div className="custom-filter d-flex flex-grow-1 justify-content-between">
      <span className="arrow-right-detail" />
      {
        columns.map(column => {
          const { field, title, lookup, tableData: { id } } = column;

          if (field === 'actions') return;

          if (lookup) {
            return (
              <div className="d-flex flex-row">
                <span
                  style={{
                    fontWeight: '500',
                    fontSize: 15
                  }}
                >
                  {intl.formatMessage({ id: field})}
                </span>
                {
                  Object.values(lookup).map(({ props: { value }}, index) => (
                    <label className="radio-container" htmlFor={`${field}-${value}`} key={`${field}-${value}`}>
                      {intl.formatMessage({ id: `custom_filters.lookup_${value}` })}
                      <input
                        id={`${field}-${value}`}
                        columnId={id}
                        name={field}
                        value={index}
                        type="radio"
                        onChange={handleChange}
                      />
                      <span className="checkmark" />
                    </label>
                  ))
                }
              </div>
            );
          }

          console.debug('title: ', title);

          return (
            <div className="input-wrapper">
              {/*<FieldPlaceholder id={field} />*/}
              <input
                columnId={id}
                key={field}
                name={field}
                placeholder={intl.formatMessage({ id: `custom_filters.${field}` })}
                type="text"
                onChange={handleChange}
              />
              <SearchIcon className="input-icon" fill="#77CF7D" />
            </div>
          )
        })
      }
    </div>
  )
};

export default CustomFilter;
