import React from 'react';
import Skeleton from 'react-loading-skeleton';

function genArray(size = 4) {
  return Array.from(Array(size).keys());
}

export function magazinesLoading(size) {
  return (
    <>
      {genArray(size).map( i => (
      <div className='p-4 mb-3 d-flex' key={i}>
        <Skeleton height={106} width={80}/>
        <div className='pl-3 w-100'>
          <Skeleton count={2}  />
          <div className='border-top mt-3 pt-3'>
            <Skeleton />
          </div>
        </div>
      </div>))}
    </>
  )
}

export function newsLoading(size) {
  return (
    <>
    {genArray(size).map( i => (
      <div className='p-4 mb-3' key={i}>
        <Skeleton />
        <Skeleton width={64}/>
        <Skeleton />
        <div className='border-top mt-3 pt-3'>
          <Skeleton />
        </div>
      </div>
    ))}
    </>
  )
}

export function generalLoading(size) {
  return (
    <>
      {genArray(size).map( i => (
      <div className='p-4 mb-3 d-flex' key={i}>
        <div className='pl-3 w-100'>
          <Skeleton count={4}  />
        </div>
      </div>))}
    </>
  )
}
