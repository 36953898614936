import { CircularProgress } from "@material-ui/core";
import { makeUseAxios } from "axios-hooks";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { dataAxiosClient } from "../../../helpers/axiosModules";
import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CardActionArea,
  Divider,
  List,
  ListItem,
  ListItemText,
  Slide,
  Dialog,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { ReactComponent as CloseIcon } from "../../../assets/svg-icons/Icon-close.svg";

import { filterUserInteraction } from "../../../api/routes-data";

const useStyles = makeStyles((theme) => ({
  user: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    height: 250,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  dialog: {
    width: "100%",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const useAxios = makeUseAxios({ axios: dataAxiosClient() });
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const params = {
  to: dayjs().endOf("month").toISOString(),
  from: dayjs().startOf("month").toISOString(),
  limit: 10,
};

function MostInteractiveUsersQualityMonth() {
  const classes = useStyles();
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);

  const [{ data, loading, error }] = useAxios({
    url: filterUserInteraction(),
    params: { screenName: "quality", ...params },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={handleClickOpen}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CardContent
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box component="div">
            <Typography
              className={classes.title}
              color="textSecondary"
              variant="subtitle2"
              gutterBottom
            >
              <FormattedMessage id="dashboard.most_interactive_users_quality_month" />
            </Typography>
          </Box>
          <Box
            component="div"
            style={{
              width: 100,
              height: 50,
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography color="secondary" variant="h3">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                data?.length || "-"
              )}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
      <Dialog
        key="most_interactive_users_quality_month-dialog"
        className={classes.dialog}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <>
          <DialogTitle disableTypography className={classes.dialogTitle}>
            {intl.formatMessage({
              id: "dashboard.most_interactive_users_quality_month",
            })}
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <List className={classes.user}>
            <ListItem className="d-flex justify-content-between">
              <Box component="div" className="mx-2">
                <ListItemText
                  primary={<FormattedMessage id="dashboard.identification" />}
                />
              </Box>
              <Divider />
              <Box component="div" className="mx-2">
                <ListItemText
                  primary={<FormattedMessage id="dashboard.name" />}
                />
              </Box>
              <Divider orientation="vertical" className="h-10" />
              <Box component="div" className="mx-2">
                <ListItemText
                  primary={
                    <FormattedMessage id="dashboard.quantity_interaction" />
                  }
                />
              </Box>
            </ListItem>
            {!loading &&
              data &&
              data.map((item) => (
                <span key={item.identification}>
                  <Divider />

                  <ListItem
                    key={item.identification}
                    className="d-flex justify-content-between"
                  >
                    <Box component="div" className="mx-2">
                      <ListItemText primary={`${item.identification}`} />
                    </Box>
                    <Box component="div" className="mx-2">
                      <ListItemText primary={`${item.name}`} />
                    </Box>
                    <Box component="div" className="mx-2">
                      <ListItemText primary={`${item.count}`} />
                    </Box>
                  </ListItem>
                  <Divider />
                </span>
              ))}
          </List>
        </>
      </Dialog>
    </Card>
  );
}

export default MostInteractiveUsersQualityMonth;
