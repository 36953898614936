export const categories = [
  'quality',
  'collect',
  'temperature',
  'magazines',
  'other'
];

export default {
  QUALITY: 'QUALITY',
  COLLECT: 'COLLECT',
  TEMPERATURE: 'TEMPERATURE',
  MAGAZINES: 'MAGAZINES',
  OTHER: 'OTHER'
}
