import { getOr, map } from "lodash/fp";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadcrumbContext } from "../../components/BreadCrumb";
import Loading from "../../components/loading/Loading";
import { NotificationForm, triggerRuleToFormRange } from "./form";
import { application } from "../../helpers/axiosModules";
import { notificationUpdate, notificationGet } from "../../api/routes-app";

const notificationDataDefaultProps = {
  error: null,
  loading: false,
  data: {},
};

export function NotificationEdit() {
  const history = useHistory();
  const { id } = useParams();
  const [{ error, loading, data }, setNotificationData] = useState(
    notificationDataDefaultProps
  );

  const fetchData = async () => {
    setNotificationData({ data: {}, error: null, loading: true });

    try {
      const { data } = await application.get(notificationGet(id));
      setNotificationData({ error: null, loading: false, data });
    } catch (err) {
      setNotificationData({ data: {}, error: err, loading: false });
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const defaultValues = {
    id: data?._id,
    title: data?.title,
    kind: data?.kind,
    body: data?.body,
  };

  const hookFormParams = useForm({
    mode: "onSubmit",
    shouldUnregister: false,
  });

  const { watch, setValue, reset } = hookFormParams;
  const triggerRules = watch("triggerRules");

  useEffect(() => {
    if (data) {
      Object.entries(defaultValues).map(([key, value]) => setValue(key, value));

      if (!triggerRules || !triggerRules.length) {
        setValue(
          "triggerRules",
          triggerRuleToFormRange(getOr([], "triggerRules", data))
        );
      }
    }
  }, [data]);

  const setBreadcrumbRightContent = useContext(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumbRightContent({
      display: [
        <FormattedMessage id="header.notifications" />,
        <FormattedMessage id="header.edit_notifications" />,
      ],
    });
  }, []);
  const formSubmit = async (data) => {
    try {
      await application.put(notificationUpdate(id), data);
      toast.success(<FormattedMessage id="settings.changed_notification" />);
      history.push("/notifications");
    } catch (err) {
      toast.error(
        <FormattedMessage id="settings.changed_notification_error" />
      );
    }
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    history.push("/notifications");
  }

  return (
    <>
      <NotificationForm formSubmit={formSubmit} hookForm={hookFormParams} />
    </>
  );
}
