import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { BreadcrumbContext } from "./SettingsWrapper";
import { application } from "../../helpers/axiosModules";
import { Table, Status } from "../../components/Table";
import { User } from "../../components/User";
import { IconWithText } from "../../components/IconWithText";
import { Modal } from "../../components/Modal";
import { resetTableSearchAndFilter } from "../../components/CustomFilter";

import { ReactComponent as EditIcon } from "../../assets/svg-icons/Icon-edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg-icons/Icon-trash.svg";
import { ReactComponent as InfoIcon } from "../../assets/svg-icons/Icon-info.svg";
import { SidebarContext } from "../../components/layouts/Main/Main";
import { SettingsSidebar } from "./SettingsSidebar";
import { administratorsGet, administratorsRemove } from "../../api/routes-app";

export function AdministratorsPage({ history }) {
  const [administrators, setAdministrators] = useState([]);
  const [loading, setLoading] = useState(true);
  const [adminToDelete, setAdminToDelete] = useState();
  const tableRef = useRef(null);
  const intl = useIntl();
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);
  const setSidebarChildren = useContext(SidebarContext);

  useEffect(() => {
    async function fetchData() {
      const response = await application.get(
        `${administratorsGet()}?cachetick=${Math.random()}`
      );

      setAdministrators(
        response.data.map((user) => {
          return {
            _id: user._id,
            identification: user.identification,
            image_url: user.photoLink,
            name: user.name,
            groups: user.groups,
            cooperative: user.cooperative,
            role: user.role,
            active: user.active ? 1 : 0,
          };
        })
      );
      setLoading(false);
    }

    fetchData();
  }, []);

  function handleDelete() {
    setLoading(true);
    application.delete(administratorsRemove(adminToDelete._id)).then(() => {
      setAdminToDelete(null);
      setLoading(false);
      history.push("/administrators");
      setTimeout(() => {
        history.push("/settings/administrators");
      }, 100);
    });
  }

  useEffect(() => {
    setBreadcrumbRightContent({
      rightContent: (
        <Link className="btn btn-light green-text px-5" to="add-administrator">
          <FormattedMessage id="settings.add_administrator" />
        </Link>
      ),
      resetTableSearchAndFilter: () => resetTableSearchAndFilter(tableRef),
    });
    setSidebarChildren(<SettingsSidebar />);
  }, []);

  return (
    <>
      <Table
        tableRef={tableRef}
        columns={[
          {
            title: <FormattedMessage id="common.name" />,
            field: "name",
            disableClick: true,
            sorting: false,
            render: (props) => <User user={props} />,
          },
          {
            title: <FormattedMessage id="common.role" />,
            disableClick: true,
            sorting: false,
            field: "role",
          },
          {
            title: <FormattedMessage id="common.status" />,
            field: "active",
            disableClick: true,
            sorting: false,
            lookup: {
              1: <Status value="active" />,
              0: <Status value="inactive" />,
            },
          },
          {
            title: "Ações",
            field: "actions",
            sorting: false,
            render: (rowData) => (
              <div className="d-flex justify-content-center px-3 py-2 child-svg-color-1">
                <Link
                  className="btn"
                  to={`administrators/${rowData.identification}`}
                >
                  <IconWithText icon={<EditIcon />} reset={true} />
                </Link>
                <button
                  className="btn"
                  onClick={() => setAdminToDelete(rowData)}
                >
                  <IconWithText icon={<DeleteIcon />} reset={true} />
                </button>
              </div>
            ),
          },
        ]}
        isLoading={loading}
        data={administrators}
      />
      <Modal
        isOpen={!!adminToDelete}
        onRequestClose={() => {
          setAdminToDelete(null);
        }}
        onConfirm={handleDelete}
      >
        <div className="d-flex flex-column align-items-center text-center">
          <div
            className="px-3 py-2 border-radius mb-5"
            style={{ backgroundColor: "#f5f6fa", fill: "#657fa0" }}
          >
            <InfoIcon />
          </div>
          <h6 className="text-bold">
            <FormattedMessage id="permissions.want_delete_admin" />,
          </h6>
          <div className="py-4">
            {adminToDelete ? adminToDelete.headerTitle : ""}
          </div>
        </div>
      </Modal>
    </>
  );
}
