import React from "react";
import { IconWithText } from "../../components/IconWithText";
import { FormattedMessage } from "react-intl";
import { MdDashboard } from "react-icons/md";

export function DashboardSidebar() {
  return (
    <IconWithText
      icon={<MdDashboard size={22} />}
      text={<FormattedMessage id="header.dashboard" defaultMessage="" />}
    />
  );
}
