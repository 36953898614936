import React from "react";

export class BadgeTooltip extends React.Component {

  render() {
    const {
      x, y, datum, lastMonth, color, year
    } = this.props;
    return datum._x === lastMonth && (
      <g>
        <svg
          x={x - 3}
          y={y - 4}
        >
          <polygon
            points="0,0 8,0 4,5"
            fill={color}
          />
        </svg>
        <rect
          x={x - 25}
          y={y - 28}
          rx={10}
          ry={10}
          width={50}
          height={25}
          fill={color}
        />
        <text
          x={x}
          y={y - 12}
          fill="white"
          fontSize="12"
          fontWeight="bold"
          textAnchor="middle"
        >
          {year}
        </text>
      </g>
    );
  }
}

const thousandNumber = value => value.toString().replace(
  /(\d)(?=(\d{3})+(?!\d))/g,
  '$1.'
);

const getPosY = (y, variant= 0) => {
  const chartHeight = 400;
  const paddingChart = 120;
  const barHeight = chartHeight - paddingChart - y;
  return y + (barHeight / 2 ) - variant
};

const formatDate = (date) => {
  const split = date.split('/');
  return `${split[0]}/${split[1]}`;
};

export class ChartTooltip extends React.Component {
  render() {
    const { x, y, datum: data, type, getFakePosY, unit } = this.props;
    const fakeY = getFakePosY ? getFakePosY(data._y, y, type) : y;
    const isOverflowingX = (600 - (x + 15 + 120)) <= 0;
    const posX = !isOverflowingX ?  x + 55 : x - 90;
    const posY = getPosY(fakeY);
    // const posYDate = getFakePosY ? getPosY(fakeY, 46) : 36;
    // const posYValue = getFakePosY ? getPosY(fakeY, 26) : 56;
    return (
      <g>
        <svg x={posX} y={posY} style={{ overflow: 'visible' }}>
          <text
            x={15}
            y={36}
            fill='#657FA0'
            fontWeight="normal"
            fontSize={12}
            fontFamily={'Roboto'}
            textAnchor="middle"
            alignmentBaseline="central"
          >
            {typeof data.x === 'string' ? formatDate(data.x) : data.x}
          </text>
          <text
            x={15}
            y={56}
            fill='#15CE9A'
            fontWeight="bold"
            fontSize={16}
            fontFamily={'Roboto'}
            textAnchor="middle"
            alignmentBaseline="central"
          >
            {`${thousandNumber(data.y)} ${unit ? unit : ''}`}
          </text>
        </svg>
      </g>
    )
  }
}

