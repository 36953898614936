import React from "react";
import { FiStar } from "react-icons/fi";
import { FormattedMessage } from "react-intl";
import CardAnalytcs from "./CardAnalytics";
import { CircularProgress } from "@material-ui/core";

export default function PostLikesAtThisMonth({
  loading,
  error,
  data,
}) {
  if (loading)
    return (
      <div className="d-flex justify-content-center">
        <CircularProgress color="secondary" />;
      </div>
    );
  if (error) return `Error! ${error.message}`;
  if (!data)
    return (
      <CardAnalytcs
        title={<FormattedMessage id="dashboard.without_content" />}
      ></CardAnalytcs>
    );

  return (
    <CardAnalytcs
      title={<FormattedMessage id="dashboard.post_likes_at_month" />}
      value={data.postLikesAtThisMonth}
    >
      <FiStar size={50} color="#41b049" />
    </CardAnalytcs>
  );
}
