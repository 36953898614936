import React from "react";
import { IconWithText } from "../../components/IconWithText";
import { FormattedMessage } from "react-intl";
import { ReactComponent as NewsIcon } from "../../assets/svg-icons/Icon-news-opened.svg";

export function PublicationsSidebar() {
  return (
    <IconWithText
      icon={<NewsIcon />}
      text={<FormattedMessage id="header.publications" defaultMessage="" />}
    />
  );
}
