import { variables } from "../whitelabel/variables";
const dataFunctionsPath = variables.API_DATA_URL;

export const collectionsGet = () => `${dataFunctionsPath}/v1/collections`
export const consolidatedGet = () => `${dataFunctionsPath}/v1/consolidated`
export const consolidatedGetById = (id) => `${dataFunctionsPath}/v1/consolidated/${id}`
export const cooperativesGet = () => `${dataFunctionsPath}/v1/cooperative`
export const coordinationGet = () => `${dataFunctionsPath}/v1/coordination`
export const coordinationGetById = (id) => `${dataFunctionsPath}/v1/coordination/${id}`
export const ingestion = () => `${dataFunctionsPath}/v1/ingestion/{collection}`
export const invoiceGet = () => `${dataFunctionsPath}/v1/invoice`
export const invoiceGetById = (id) => `${dataFunctionsPath}/v1/invoice/${id}`
export const createUserInteraction = () =>`${dataFunctionsPath}/v1/interactions`
export const filterUserInteraction = () =>`${dataFunctionsPath}/v1/interactions_filter`
export const qualityGet = () => `${dataFunctionsPath}/v1/quality`
export const qualityGetById = (id) => `${dataFunctionsPath}/v1/quality/${id}`
export const staticTextsGet = () => `${dataFunctionsPath}/v1/statictexts`
export const usersGetByPropertyCode = () => `${dataFunctionsPath}/v1/users/by_propertycode`
export const usersGetByRegistration = (id) => `${dataFunctionsPath}/v1/users/registration/${id}`
export const whois = () => `${dataFunctionsPath}/v1/users/whois`
