import React from "react";
import logo from "../../../whitelabel/logo.jpg";
import { NavItem } from "./NavItem";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { IconWithText } from "../../IconWithText";
import { UserInfo } from "./UserInfo";

// icons
import { ReactComponent as NewsIcon } from "../../../assets/svg-icons/Icon-news.svg";
import { ReactComponent as HomeMilk } from "../../../assets/svg-icons/Icon-milk.svg";
import { ReactComponent as ConfigIcon } from "../../../assets/svg-icons/Icon-config.svg";
import { ReactComponent as AdminIcon } from "../../../assets/svg-icons/Icon-admins.svg";
import { ReactComponent as PermissionsIcon } from "../../../assets/svg-icons/Icon-info.svg";
import { ReactComponent as NotificationsIcon } from "../../../assets/svg-icons/Icon-bell.svg";
import { ReactComponent as SupportIcon } from "../../../assets/svg-icons/Icon-support.svg";

import "./Header.scss";
import { MdDashboard } from "react-icons/md";

const SubNavItem = ({ to, id, icon, disabled }) => (
  <div className={disabled ? "disabled" : ""}>
    <NavLink to={to} activeClassName="active">
      <IconWithText icon={icon} text={<FormattedMessage id={id} />} />
    </NavLink>
  </div>
);

export default function Header() {
  return (
    <header className="header d-flex">
      <div className="header__logo d-flex justify-content-center align-items-center">
        <img alt="" src={logo} />
      </div>

      <div className="d-flex justify-content-between w-100">
        <div className="header__nav p-1 pl-4">
          <NavItem
            icon={<MdDashboard size={22} />}
            to="/dashboard"
            title={<FormattedMessage id="header.dashboard" />}
            placement="right"
          />
          <NavItem
            icon={<NewsIcon />}
            to="/publications"
            title={<FormattedMessage id="header.publications" />}
            placement="right"
          />
          <NavItem
            to="/producers"
            icon={<HomeMilk />}
            title={<FormattedMessage id="header.producers" />}
            placement="right"
          />
          <NavItem
            to="/notifications"
            icon={<NotificationsIcon style={{ height: 20, width: 20 }} />}
            title={<FormattedMessage id="header.notifications" />}
            placement="right"
          />
          <NavItem
            icon={<ConfigIcon />}
            to="/settings"
            title={<FormattedMessage id="header.settings" />}
            placement="right"
          >
            <SubNavItem
              to="/settings/administrators"
              icon={<AdminIcon />}
              id="header.administrators"
            />
            <SubNavItem
              to="/settings/permissions"
              icon={<PermissionsIcon />}
              id="header.permissions"
            />
            <SubNavItem
              to="/settings/support"
              icon={<SupportIcon />}
              id="header.support"
            />
          </NavItem>
        </div>
        <div className="d-flex py-3 px-4">
          <div></div>
          <UserInfo />
        </div>
      </div>
    </header>
  );
}
