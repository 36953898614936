import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {data} from "../../../helpers/axiosModules";
import { ReactComponent as EmailIcon } from '../../../assets/svg-icons/Icon-email-blue.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/svg-icons/Icon-phone.svg';
import { generalLoading } from '../../../helpers/skeletonSchemas';
import defaultPic from '../../../assets/images/profile-placeholder.jpg';

const ContactCard = ({email, phoneNumber, name, roleName, roleDescription, imageUrl}) => (
  <div className='d-inline-block rounded shadow bg-white p-4 mr-5'>
    <div className='d-flex justify-content-between align-items-center bg-info rounded pl-4 py-2 pr-2'>
      <span className='text-light'>{roleName}</span>
      <div>
        <a href={`mailto:${email}`}><span className='btn btn-light mr-2'><EmailIcon fill={'#4976EF'}/></span></a>
        <a href={`tel:${phoneNumber}`}><span className='btn btn-light'><PhoneIcon fill={'#15CE9A'}/></span></a>
      </div>
    </div>
    <div className='d-flex'>
      <div className='rounded overflow-hidden w-100 py-4' style={{maxWidth: 155}}>
        <img src={imageUrl} alt='' height={155} className='rounded'/>
      </div>
      <div className='w-100 p-4'>
        <div className='border-bottom'>
          <div className='text-bold'>
            {name}
          </div>
          <div><small>{roleDescription}</small></div>
        </div>
        <div>
          <div className='text-bold'><FormattedMessage id='common.phone_number'/></div>
          <small>{phoneNumber}</small>
        </div>
        <div>
          <div className='text-bold'>E-mail</div>
          <small>{email}</small>
        </div>
      </div>
    </div>
  </div>
);

export function ContactSection(props) {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);

  function loadContacts() {
    data.get(`/coordination/${props.id}?cachetick=${Math.random()}`)
      .then(({data}) => {
        setContacts([...contacts, ...data]);
        setLoading(false);
      })
      .catch(err => console.error(err));
  }

  useEffect(
    () => {
      loadContacts();
    },
    []
  );

  if(loading) {
    return generalLoading(1);
  }

  return (
    <div>
      <div className='px-5 py-4 border-bottom'>
        <div className='text-bold'><FormattedMessage id='producers.contacts'/></div>
      </div>
      <div className='p-5'>
        {contacts ? contacts.map(contact => {
          return <ContactCard
            email={contact.email}
            phoneNumber={contact.phone}
            name={contact.name}
            roleName={contact.office}
            // roleDescription={'Supervisor de captação de leite'}
            imageUrl={contact.photoLink || defaultPic}
          />
        })
        : null}
      </div>
    </div>
  )
}
