import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import ListPublications from "./container/ListPublications";
import AddPublications from "./container/AddPublications";
import Loading from "../../components/loading/Loading";
import Toaster from "../../components/Toaster";
import { application } from "../../helpers/axiosModules";
import {
  publicationGetAll,
  publicationSave,
  publicationRemove,
} from "../../api/routes-app";

const classBtnConfirm = "btn btn-outline-secondary border btn-lg mt-5";
const classBtnCancel = "btn btn-success btn-lg ml-5 mt-5";

Swal.mixin({
  customClass: {
    confirmButton: classBtnCancel,
    cancelButton: classBtnConfirm,
  },
  focusCancel: true,
  buttonsStyling: false,
});

function Publications({ history }) {
  const match = useParams();
  const [files, setFiles] = useState([]);
  const [loading, setPublicationLoading] = useState(true);
  const [error, setPublicationError] = useState(null);
  const [publications, setPublications] = useState([]);

  const onDrop = async (onDropFiles) => {
    setFiles({ files: onDropFiles[0] });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setPublicationLoading(true);
      const response = await application.get(publicationGetAll());
      const data = response.data
       .sort((d1, d2) => new Date(d2.createdAt).getTime() - new Date(d1.createdAt).getTime());

      setPublications(data || []);
    } catch (err) {
      setPublicationError(err);
    } finally {
      setPublicationLoading(false);
    }
  };

  const createPublication = async (data) => {
    const publication = {
      title: data.title,
      type: data.type,
      description: data.description,
      subtitle: data.subtitle,
      active: true,
      audience: data.audience,
      edition: data.edition,
      videoUrl: data.videoUrl,
      images: data.images,
      files: data.files,
      cover: data.cover,
    };

    try {
      const post = await application.post(publicationSave(), publication);
      toast.success("Publication created successfully!");
      fetchData();
      return post;
    } catch (err) {
      console.log(err);
      toast.error("Publication create error!");
    }
  };

  const deletePublication = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this post?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      reverseButtons: true,
      confirmButtonText: "Confirm",
      backdrop: true,
      focusCancel: true,
    }).then((result) => {
      if (result.value) {
        application.delete(publicationRemove(id)).then(() => {
          Swal.fire("Excluded!", "Post was successfully deleted!", "success");
          fetchData();
        });
      }
    });
  };

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;
  if (!publications.length) return <p>Not found</p>;

  return (
    <>
      <Toaster />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <AddPublications
            createPublication={createPublication}
            onDrop={onDrop}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          {!loading && (
            <ListPublications
              history={history}
              listAll={publications}
              deletePublication={deletePublication}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export { Publications };
