import React from 'react';
import {VictoryAxis, VictoryChart, VictoryLine, VictoryScatter, VictoryVoronoiContainer, VictoryTooltip} from 'victory';
import ChartNoData from "./ChartNoData";
import Theme from '../../utils/Chart.theme';
import {BadgeTooltip} from "./ChartTooltip";
import ChartLineTooltipFlyout from "./ChartLineTooltipFlyout";
import {ChartLineTooltip} from "./ChartLineTooltip";

const lateYearColor = '#3BD6AA';
const lastYearColor = '#4976EF';
const currentYearColor = '#F26894';

const getLastMonthWithValue = (dataYear) => {
  let lastMonth = 0;
  dataYear.forEach((item) => {
    if (item.y > 0 && item.x >= lastMonth) {
      lastMonth = item.x;
    }
  });
  return lastMonth;
};

const renderLateScatter = (dataLateYear, selectedYear) => {
  const monthWithValue = getLastMonthWithValue(dataLateYear);
  if (monthWithValue) {
    return (
      <VictoryScatter
        data={dataLateYear}
        dataComponent={(
          <BadgeTooltip
            lastMonth={monthWithValue}
            color={lateYearColor}
            year={selectedYear - 2}
          />
        )}
        style={{ labels: { fill: 'none' } }}
      />
    );
  }
  return null;
};

const renderLastScatter = (dataLastYear, selectedYear) => {
  const monthWithValue = getLastMonthWithValue(dataLastYear);
  if (monthWithValue) {
    return (
      <VictoryScatter
        data={dataLastYear}
        dataComponent={(
          <BadgeTooltip
            lastMonth={monthWithValue}
            color={lastYearColor}
            year={selectedYear - 1}
          />
        )}
        style={{ labels: { fill: 'none' } }}
      />

    );
  }
  return null;
};

const renderCurrentScatter = (dataYear, selectedYear) => {
  const monthWithValue = getLastMonthWithValue(dataYear);
  if (monthWithValue) {
    return (
      <VictoryScatter
        data={dataYear}
        dataComponent={(
          <BadgeTooltip
            lastMonth={monthWithValue}
            color={currentYearColor}
            year={selectedYear}
          />
        )}
        style={{ labels: { fill: 'none' } }}
      />
    );
  }
  return null;
};

const getXTickValues = () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const getXTickFormat = () => [
  'jan',
  'fev',
  'mar',
  'abr',
  'mai',
  'jun',
  'jul',
  'ago',
  'set',
  'out',
  'nov',
  'dez',
];

const CHART_WIDTH = 600;
const CHART_HEIGHT = 400;

const VolumeYearlyChart = ({
  dataYear,
  dataLastYear,
  dataLateYear,
  selectedYear,
}) => (
  <div>
    {
      getLastMonthWithValue(dataYear) === 0 && <ChartNoData type={'volume'} />
    }
    <VictoryChart
      animate={{duration: 2000}}
      width={CHART_WIDTH}
      height={CHART_HEIGHT}
      domain={{ y: [0, 1200000] }}
      theme={Theme}
      containerComponent={(
        <VictoryVoronoiContainer
          labels={({y}) => `${y}`}
          labelComponent={(
            <VictoryTooltip
              flyoutComponent={(
                <ChartLineTooltipFlyout
                  chartHeight={CHART_HEIGHT}
                  arrowOrientation={'vertical'}
                />
              )}
              labelComponent={<ChartLineTooltip chartHeight={CHART_HEIGHT} />}
            />
          )}
        />
      )}
    >
      <VictoryAxis
        dependentAxis
        standalone={false}
        style={{
          axis: {
            stroke: 'transparent',
          },
          grid: {
            stroke: 'grey',
            strokeDasharray: '1,4',
          },
          tickLabels: {
            fontSize: 10,
            fill: '#657FA0'
          }
        }}
        tickValues={[
          0,
          100000,
          200000,
          300000,
          400000,
          500000,
          600000,
          700000,
          800000,
          900000,
          1000000,
          1100000,
        ]}
        tickFormat={d => (d > 1000 ? `${d / 1000}k L` : d)}
      />
      <VictoryAxis
        standalone={false}
        style={{
          axis: {
            stroke: 'transparent'
          },
          ticks: {
            stroke: 'black',
            strokeWidth: 1
          },
          tickLabels: {
            fontSize: 8,
            fill: '#657FA0',
            padding: 5
          },
        }}
        tickValues={getXTickValues()}
        tickFormat={getXTickFormat()}
      />

      {
        dataLateYear && renderLateScatter(dataLateYear, selectedYear)
      }

      {
        dataLastYear && renderLastScatter(dataLastYear, selectedYear)
      }

      {
        dataYear && renderCurrentScatter(dataYear, selectedYear)
      }

      {
        dataLateYear && (
          <VictoryLine
            data={dataLateYear}
            style={{
              data: { stroke: lateYearColor },
            }}
          />
        )
      }

      {
        dataLastYear && (
          <VictoryLine
            data={dataLastYear}
            style={{
              data: { stroke: lastYearColor },
            }}
          />
        )
      }

      <VictoryLine
        data={dataYear}
        style={{
          data: { stroke: currentYearColor },
        }}
      />

      <VictoryScatter
        data={dataLateYear}
        style={{
          data: {
            fill: (datum, active) => active ? "white" : "transparent",
            stroke: (datum, active) => active ? lateYearColor : "transparent",
            strokeWidth: 2
          }
        }}
      />

      <VictoryScatter
        data={dataLastYear}
        style={{
          data: {
            fill: (datum, active) => active ? "white" : "transparent",
            stroke: (datum, active) => active ? lastYearColor : "transparent",
            strokeWidth: 2
          }
        }}
      />

      <VictoryScatter
        data={dataYear}
        style={{
          data: {
            fill: (datum, active) => active ? "white" : "transparent",
            stroke: (datum, active) => active ? currentYearColor : "transparent",
            strokeWidth: 2
          }
        }}
      />

    </VictoryChart>

  </div>
);

export default VolumeYearlyChart;
