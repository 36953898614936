import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import * as _ from 'lodash';

function AddNotificationButton({location}) {
  const lastDir = _.last(location.pathname.split('/'));

  if (_.includes(['quality', 'collect', 'temperature', 'magazines', 'other'], lastDir)) {
    return (
      <Link to={`${lastDir}/add`}>
        <div className='btn btn-light green-text px-5'><FormattedMessage id='settings.add_notification' /></div>
      </Link>
    );
  }

  return null;
}

export default withRouter(AddNotificationButton);
