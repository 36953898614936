import React, { useContext, useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { SidebarContext } from "../../components/layouts/Main/Main";
import { BreadCrumb, BreadcrumbContext } from "../../components/BreadCrumb";
import { NotificationsSidebar } from "../notificationsBK/NotificationsSidebar";
import { Notifications } from "./Notifications";
import { NotificationAdd } from "./NotificationAdd";
import { NotificationEdit } from "./NotificationEdit";
import { NotificationSend } from "./NotificationSend";
import Toaster from "../../components/Toaster";

export function NotificationsWrapper({ history }) {
  const setSidebarChildren = useContext(SidebarContext);
  const [breadcrumb, setBreadcrumb] = useState({
    filter: null,
    filterIcon: null,
    onSearch: null,
  });

  useEffect(() => {
    setSidebarChildren(<NotificationsSidebar />);
  }, []);

  return (
    <>
      <Toaster />
      <BreadcrumbContext.Provider value={setBreadcrumb}>
        <BreadCrumb {...breadcrumb} />
        <div>
          <Switch>
            <Route exact path="/notifications">
              <Notifications history={history} />
            </Route>
            <Route exact path="/notifications/add">
              <NotificationAdd history={history} />
            </Route>
            <Route exact path="/notifications/send">
              <NotificationSend history={history} />
            </Route>
            <Route path="/notifications/edit/:id">
              <NotificationEdit />
            </Route>
          </Switch>
        </div>
      </BreadcrumbContext.Provider>
    </>
  );
}
