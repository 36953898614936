import React from "react";
import SafeValueUtils from "../../utils/SafeValue.utils";
import {ReactComponent as PropertyIcon} from "../../assets/svg-icons/Icon-property.svg";

const getPosY = (chartHeight, y, variant = 0) => {
  const paddingChart = 120;
  const barHeight = chartHeight - paddingChart - y;
  return y + (barHeight / 2) - variant
};

class AnalyticsLabelFlyout extends React.Component {
  render() {
    const {x, y, datum, chartHeight, data} = this.props;
    const isOverflowingX = (600 - (x + 15 + 120)) <= 0;
    const posX = !isOverflowingX ? x : x - 150;
    const posY = getPosY(chartHeight, y);
    const filteredUser = data.filter(item => item.x === datum.x);
    const url = filteredUser && filteredUser[0] ? filteredUser[0].tooltipData.user.photoLink : '';

    return (
      <g>
        <svg x={posX - 10} y={posY - 10} width={135}>
          <defs>
            <clipPath id="myCircleTooltip">
              <circle cx="40" cy="40" r="7" fill={'red'}/>
            </clipPath>
          </defs>
          {
            url && (
              <image
                x={33}
                y={33}
                href={url}
                height={14}
                width={14}
                clipPath="url(#myCircleTooltip)"
                preserveAspectRatio="xMidYMid slice"
              />
            )
          }
          {
            !url && (
              <g>
                <circle cx="40" cy="40" r="7" fill={datum.tooltipData.user.color}/>
                <text
                  x={40}
                  y={40}
                  textAnchor="middle"
                  alignmentBaseline="central"
                  style={{fontSize: 8, fill: '#fff'}}
                >
                  {datum.tooltipData.user.name.charAt(0)}
                </text>
              </g>
            )
          }
          <text
            x={55}
            y={40}
            alignmentBaseline="central"
            style={{
              fill: '#1F8CC7',
              fontSize: 8,
              fontWeight: 'bold'
            }}
          >{datum.tooltipData.user.name}</text>
          <PropertyIcon x={34} y={55} fill={'#89A0BC'} width={12} height={8}/>
          <text
            x={55}
            y={60}
            alignmentBaseline="central"
            style={{
              fill: '#1F8CC7',
              fontSize: 8
            }}
          >{SafeValueUtils.getCooperative(datum.tooltipData.cooperative)}</text>
          <rect
            x={33}
            y={70}
            ry={4}
            rx={4}
            width={100}
            height={18}
            fill={'rgba(201,214,250,0.24)'}
          />
          <text
            x={80}
            y={80}
            textAnchor="middle"
            alignmentBaseline="central"
            style={{
              fill: '#1F8CC7',
              fontSize: 8,
              fontWeight: 'bold'
            }}
          >
            {`${datum.y} acessos`}
          </text>
        </svg>
      </g>
    );
  }
}

export default AnalyticsLabelFlyout;
