import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border-width: 3px;
  border-style: solid;
  border-color: blue;
  border-bottom-color: white;
  border-radius: 50%;
  width: 45px;
  height: 45px;

  animation: 0.8s linear ${spin} infinite;
`;

export default Spinner;
