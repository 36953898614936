import React, { useState, useEffect } from "react";
import { CustomCard } from "../../../components/CustomCard";
import styled from "styled-components";
import { MdAttachFile, MdLink, MdImage, MdExpandLess } from "react-icons/md";
import { isEmpty } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import {
  MenuItem,
  Grid,
  Button,
  Collapse,
  InputAdornment,
  IconButton,
  CircularProgress,
  Typography,
  TextareaAutosize,
} from "@material-ui/core";

import { useForm, Controller } from "react-hook-form";
import { validations } from "../utils/validations";

import { UploadFiles } from "./UploadFiles";
import {
  SelectTypePublication,
  ContainerTitle,
  InputField,
  useStyles,
} from "./styles";
import { application } from "../../../helpers/axiosModules";
import { parseJwt } from "../../../helpers/parseJwt";
import { administratorsGetByIdentification } from "../../../api/routes-app";

let types = {
  NEWS: "NEWS",
  MAGAZINES: "MAGAZINES",
};

const defaultValues = {
  type: types.NEWS,
  title: "",
  subtitle: "",
  description: "",
  edition: "",
  active: 1,
  cover: "",
  // categories: [],
  audience: [],
  videoUrl: "",
  // attachments: [],
  createdBy: new Date(),
};

const getBase64 = async (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onload = (e) => resolve(e.target.result);
    reader.onerror = (error) => reject(error);
  });
};

function AddPublications({ createPublication }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState([]);
  const [coverFile, setCoverFile] = useState("");
  const [attachFile, setAttachFile] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [files, setFiles] = useState({ cover: [], attach: [], images: [] });
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, reset, control, watch, errors } = useForm({
    defaultValues,
    validationSchema: validations,
    mode: "onSubmit",
  });
  const intl = useIntl();

  const name = watch("type");

  useEffect(() => {
    async function fetchData() {
      const parsedToken = parseJwt(sessionStorage.getItem("jwtoken"));

      const response = await application.get(
        administratorsGetByIdentification(parsedToken.identification)
      );
      setUser(response.data);
      const filteredOptions = [];

      [user.groups].forEach((item) => {
        filteredOptions.push({ label: item, value: item });
      });
    }

    fetchData();
  }, []);

  async function onSubmit(data) {
    try {
      if (coverFile) {
        data.cover = coverFile;
      }

      if (attachFile) {
        data.files = [attachFile];
      }

      if (imageFile) {
        data.images = [imageFile];
      }

      setLoading(true);
      await createPublication(data);
      setImageFile("");
      setCoverFile("");
      setAttachFile("");
      reset();
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  const onAcceptedCover = async (file) => {
    const imageBase64 = await getBase64(file);
    setCoverFile(imageBase64);

    setFiles({ ...files, cover: [file] });
  };

  const onAcceptedFiles = async (file) => {
    const attachBase64 = await getBase64(file);
    setAttachFile(attachBase64);

    const attach = isEmpty(files.attach) ? [file] : [...files.attach, file];
    setFiles({ ...files, attach });
  };

  const onAcceptedImage = async (file) => {
    const imageBase64 = await getBase64(file);
    setImageFile(imageBase64);

    const images = isEmpty(files.image) ? [file] : [...files.image, file];
    setFiles({ ...files, images });
  };

  return (
    <CustomCard>
      <Grid container>
        <form onSubmit={handleSubmit(onSubmit)} className="mb-0">
          <ContainerTitle>
            <h6 className="card-title dashed-green mb-0">
              <FormattedMessage id="publication.form.label_add" />
            </h6>
            <Controller
              as={
                <SelectTypePublication inputRef={register}>
                  <MenuItem value="NEWS">
                    <FormattedMessage id="publication.form.news" />
                  </MenuItem>
                  <MenuItem value="MAGAZINE">
                    <FormattedMessage id="publication.form.magazine" />
                  </MenuItem>
                </SelectTypePublication>
              }
              style={{ maxWidth: 125 }}
              name="type"
              control={control}
            />
          </ContainerTitle>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                as={InputField}
                name="title"
                label={<FormattedMessage id="publication.form.title" />}
                control={control}
                inputRef={register({ required: true })}
                helperText={errors.title && errors.title.message}
                error={errors.title}
              />
            </Grid>
            {name === "NEWS" && (
              <Grid item xs={12}>
                <Controller
                  className="w-100"
                  style={{
                    height: 58,
                    padding: "15px 0px 15px 10px",
                    border: "#ccc 1px solid",
                    borderRadius: 5,
                  }}
                  as={TextareaAutosize}
                  name="description"
                  placeholder={intl.formatMessage({
                    id: "publication.form.desc",
                  })}
                  variant="outlined"
                  control={control}
                />
              </Grid>
            )}

            {name === "MAGAZINE" && (
              <React.Fragment>
                <Grid item xs={12}>
                  <Controller
                    as={InputField}
                    name="subtitle"
                    label={<FormattedMessage id="publication.form.subtitle" />}
                    control={control}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    as={InputField}
                    name="edition"
                    label={<FormattedMessage id="publication.form.edition" />}
                    control={control}
                    inputRef={register}
                  />
                </Grid>
              </React.Fragment>
            )}

            <>
              <Grid
                item
                xs={12}
                className={`${name === "MAGAZINE" ? "mb-5" : null}`}
              >
                <Controller
                  as={
                    <CustomAudience
                      style={{ height: 58 }}
                      isMulti
                      options={user.groups}
                      getOptionLabel={(option) => option}
                      getOptionValue={(option) => option}
                      classNamePrefix="mySelect"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  }
                  placeholder={intl.formatMessage({
                    id: "publication.form.audience",
                  })}
                  name="audience"
                  control={control}
                />
              </Grid>
            </>
            {name === "MAGAZINE" && (
              <>
                <Grid item xs={12} className="m-0 pt-0">
                  <UploadFiles
                    onAcceptedFiles={onAcceptedFiles}
                    accept={["application/pdf"]}
                    description={intl.formatMessage({
                      id: "publication.form.add_magazine_pdf",
                    })}
                  >
                    <MdAttachFile size={20} color="#37933c" />
                  </UploadFiles>
                </Grid>
                <Grid item xs={12} className="m-0 pt-0">
                  <UploadFiles
                    onAcceptedFiles={onAcceptedCover}
                    description={intl.formatMessage({
                      id: "publication.form.add_magazine_cover",
                    })}
                    accept={["image/jpg", "image/jpeg", "image/png"]}
                  >
                    <MdImage size={20} color="#37933c" />
                  </UploadFiles>
                </Grid>
              </>
            )}
            {name === "NEWS" && (
              <React.Fragment>
                <Grid item xs={12} className="m-0 pr-3 pl-3 pb-0">
                  <Button
                    onClick={() => setOpen(true)}
                    fullWidth
                    variant="outlined"
                    className={classes.button}
                    startIcon={<MdLink className={classes.icon} size={20} />}
                  >
                    Add link
                  </Button>
                </Grid>
                <Grid item xs={12} className="m-0">
                  <Collapse in={open}>
                    <Controller
                      as={InputField}
                      name="videoUrl"
                      inputRef={register}
                      control={control}
                      label={intl.formatMessage({
                        id: "publication.form.add_youtube_link",
                      })}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setOpen(false)}>
                              <MdExpandLess />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Collapse>
                </Grid>
                <Grid item xs={12} className="m-0 pt-0">
                  <UploadFiles
                    onAcceptedFiles={onAcceptedImage}
                    description={intl.formatMessage({
                      id: "publication.form.add_image",
                    })}
                    accept={["image/jpg", "image/jpeg", "image/png"]}
                  >
                    <MdAttachFile size={20} color="#37933c" />
                  </UploadFiles>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            className={classes.button}
            color="secondary"
          >
            {loading ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography className={classes.typhography}>
                <FormattedMessage id="common.publish" />
              </Typography>
            )}
          </Button>
        </form>
      </Grid>
    </CustomCard>
  );
}

const CustomAudience = styled(Select).attrs({})`
  && .mySelect__value-container {
    height: 58px;
  }
`;

export default AddPublications;
