import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import { application } from "../../helpers/axiosModules";
import { useForm, Controller } from "react-hook-form";
import { TextField, Chip } from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import InputMask from "react-input-mask";
import {
  administratorsUpdate,
  administratorsGetByIdentification,
} from "../../api/routes-app";

const CustomChip = ({ chip, className, onDelete, handleClick }, key) => {
  return (
    <Chip
      className={className}
      label={chip}
      key={key}
      onClick={handleClick}
      onDelete={onDelete}
      size="small"
      variant="outlined"
      color="primary"
    />
  );
};

export function EditAdministratorPage({ match, history }) {
  const [formImage, setFormImage] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const { handleSubmit, register, control } = useForm();
  const { formatMessage } = useIntl();

  async function onSubmitForm(user) {
    setLoading(true);
    await application
      .put(administratorsUpdate(data._id), {
        name: user.name,
        emails: [user.email],
        identification: user.identification.replace(".", "").replace("-", ""),
        groups: user.groups,
        role: user.role,
        area: user.area,
        phone: user.phone,
        photoBase64Content: data.photoBase64Content,
        active: data.active,
      })
      .then(() => {
        setLoading(false);
        history.push("/settings/administrators");
      });
  }

  const onDropImages = (picture) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      console.debug("drop images base64: ", e.target.result);
      setFormImage(e.target.result);
      setData({
        ...data,
        photoBase64Content: e.target.result,
      });
    };
    if (picture && picture.length) {
      fileReader.readAsDataURL(picture[picture.length - 1]);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const p = await Promise.all([
        application.get(
          `${administratorsGetByIdentification(
            match.params.id
          )}?cachetick=${Math.random()}`
        ),
      ]);
      const admin = p[0].data;
      setData({
        _id: admin._id,
        name: admin.name,
        email: admin.emails[0],
        identification: admin.identification
          .replace(".", "")
          .replace("-", "")
          .replace(".", ""),
        phone: admin.phone,
        groups: admin.groups,
        role: admin.role,
        area: admin.area,
        active: admin.active,
      });
      setFormImage(admin.photoLink);
      setLoading(false);
    }

    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="card">
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="d-flex p-5 mx-5">
          <div className="col-6 pl-5 ml-5">
            <div className="form-group">
              <Controller
                as={TextField}
                label={<FormattedMessage id="administrator.form.name" />}
                name="name"
                variant="outlined"
                fullWidth
                defaultValue={data.name}
                control={control}
              />
            </div>
            <div className="form-group">
              <Controller
                as={TextField}
                label={<FormattedMessage id="administrator.form.email" />}
                name="email"
                variant="outlined"
                fullWidth
                defaultValue={data.email}
                control={control}
              />
            </div>
            <div className="form-group">
              <Controller
                as={TextField}
                label={
                  <FormattedMessage id="administrator.form.identification" />
                }
                name="identification"
                variant="outlined"
                fullWidth
                defaultValue={data.identification}
                control={control}
              />
            </div>
            <div className="form-group">
              <Controller
                as={
                  <InputMask mask="(99)99999-9999">
                    {() => (
                      <TextField
                        label={
                          <FormattedMessage id="administrator.form.phone_number" />
                        }
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  </InputMask>
                }
                name="phone"
                defaultValue={data.phone}
                control={control}
              />
            </div>
            <div className="form-group">
              <Controller
                as={TextField}
                label={<FormattedMessage id="administrator.form.area" />}
                name="area"
                variant="outlined"
                fullWidth
                defaultValue={data.area}
                control={control}
              />
            </div>
            <div className="form-group">
              <Controller
                as={ChipInput}
                chipRenderer={CustomChip}
                variant="outlined"
                name="groups"
                label={<FormattedMessage id="administrator.form.groups" />}
                defaultValue={data.groups}
                placeholder={formatMessage({
                  id: "administrator.form.groups_placeholder",
                })}
                fullWidth={true}
                dataSource={data.groups}
                control={control}
                inputRef={register}
              />
            </div>
            <div className="form-group">
              <Controller
                as={TextField}
                label={<FormattedMessage id="administrator.form.role" />}
                name="role"
                variant="outlined"
                fullWidth
                defaultValue={data.role}
                control={control}
              />
            </div>
          </div>
        </div>
        <div className="border-top p-4 d-flex justify-content-around">
          <Link to={`/settings/administrators`}>
            <button
              className="btn btn-outline-secondary p-4"
              style={{ width: 270 }}
            >
              <FormattedMessage id="common.cancel" />
            </button>
          </Link>
          <button className="btn btn-success p-4" style={{ width: 270 }}>
            <FormattedMessage id="common.confirm" />
          </button>
        </div>
      </form>
    </div>
  );
}
