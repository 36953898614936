import React from 'react';
import './SignedOut.scss';
// import { Footer } from '../components/Footer';
import { Redirect } from 'react-router-dom';

export default function SignedOut({children}) {
  const jwtoken = sessionStorage.getItem('jwtoken');

  // when jwtoken is present, it assumes there's a token
  // and redirect to autheticated pages.
  // Should it be moved to a different file? Not great not terrible.
  if (jwtoken) {
    return <Redirect to='/posts'/>;
  }

  return (
    <div className='signed-out-page d-flex flex-column justify-content-between'>
      <div className='d-flex justify-content-center align-items-center h-100'>
        {children}
      </div>
      {/* <Footer /> */}
    </div>
  );
}
