import React, {useState, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import {data} from "../../../helpers/axiosModules";
import {ReactComponent as CowIcon} from '../../../assets/svg-icons/Icon-cow-blue.svg';
import {ReactComponent as IconProperty} from "../../../assets/svg-icons/Icon-property.svg";
import {generalLoading} from '../../../helpers/skeletonSchemas';
import flag from '../../../assets/pt-br-flag.png';

export function PropertySection(props) {
  const [propertyData, setPropertyData] = useState([]);
  const [loading, setLoading] = useState(true);

  function loadQualityData() {
    data.get(`/properties/${props.id}?cachetick=${Math.random()}`)
      .then(({data}) => {
        setPropertyData(data);
        setLoading(false);
      })
      .catch(err => console.error(err));
  }

  useEffect(() => {
    loadQualityData();
  }, []);

  if (loading) {
    return generalLoading(1);
  }

  const lat = (propertyData && propertyData[0]) ? (propertyData && propertyData[0]).latitude : undefined;
  const lng = (propertyData && propertyData[0]) ? (propertyData && propertyData[0]).longitude : undefined;

  return (
    <div>
      <div className='p-4 px-5 border-bottom d-flex justify-content-between'>
        <b><FormattedMessage id='producers.property'/></b>
      </div>
      <div className='row m-0'>
        <div className='col-8 border-right p-0'>
          <div className='p-5'>
            <div className='rounded bg-light d-flex justify-content-center align-items-center' style={{height: 272, textAlign: 'center'}}>
              {
                propertyData && propertyData[0] && propertyData[0].photoLink
                  ? (
                    <img src={propertyData[0].photoLink}
                         style={{minWidth: 80, height: 272, borderRadius: 3, overflow: 'hidden'}} alt='Foto da propriedade'/>
                  )
                  : <IconProperty width={48} height={48} fill='#025481' />
              }
            </div>
          </div>
          <div className='row px-5'>
            <div className='col-6'>
              <div className='border-bottom pb-2 d-flex flex-row justify-content-between align-items-center'>
                <div>
                  <div className='text-bold'>
                    <FormattedMessage id='producers.property'/>:
                  </div>
                  <h4 className='text-info'>{(propertyData && propertyData[0]) ? propertyData[0].name : null}</h4>
                </div>
                <div style={{
                  backgroundColor: '#F6F6F8',
                  borderRadius: 6,
                  padding: 8
                }}>
                  <img
                    src={flag}
                    alt="Bandeira"
                    style={{
                      borderRadius: '50%'
                    }}
                  />
                </div>
              </div>
              <div className='row py-4'>
                <div className='col-7'>
                  <div className='text-bold'><FormattedMessage id='producers.city'/>:</div>
                  <div>{(propertyData && propertyData[0]) ? propertyData[0].city : null}</div>
                </div>
                <div className='col-5'>
                  <div className='text-bold'><FormattedMessage id='producers.state'/>:</div>
                  <div>{(propertyData && propertyData[0]) ? propertyData[0].state : null}</div>
                </div>
              </div>
            </div>
            <div className='col-6'>
              {(lat && lng) ? (
                <img
                  width={250}
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&markers&zoom=13&scale=1&size=600x300&maptype=roadmap&key=AIzaSyABF8nkDw6Fv6UnYCu0Bw8zuIVAdoD0OyM&format=png&visual_refresh=true&markers=color:red|${lat},${lng}`}
                  alt='Localização da propriedade'
                />
              ) : undefined}
            </div>
          </div>
        </div>
        <div className='col-4 p-5'>
          <div className='d-flex align-items-center'>
            <CowIcon/> <b className='ml-3'><FormattedMessage id='producers.herd'/></b>
          </div>
          <div className='my-5'>
            <div className='text-primary mb-1'><FormattedMessage id='producers.amount_of_lactation_cows'/></div>
            <div className='text-bold'>{(propertyData && propertyData[0]) ? propertyData[0].milking : null}</div>
          </div>
          <div className='my-5'>
            <div className='text-primary mb-1'><FormattedMessage id='producers.amount_of_dry_cows'/></div>
            <div className='text-bold'>{(propertyData && propertyData[0]) ? propertyData[0].dry : null}</div>
          </div>
          <div className='my-5'>
            <div className='text-primary mb-1'><FormattedMessage id='producers.predominant_breed_in_your_herd'/></div>
            <div className='text-bold'>{(propertyData && propertyData[0]) ? propertyData[0].race : null}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
