import React, {useState, useEffect, useContext} from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import {data} from '../../../helpers/axiosModules';
import QualityMonthlyChart from '../../../components/charts/QualityMonthlyChart';
import QualityYearlyChart from '../../../components/charts/QualityYearlyChart';
import {getMonths} from '../../../utils/Date';
import {BreadcrumbContext} from '../../../components/BreadCrumb';
import {ResultsMonthYear} from '../components/ResultsMonthYear';
import {PeriodSelector} from '../components/PeriodSelector';
import {generalLoading} from '../../../helpers/skeletonSchemas';
import QualityMonthlyLegend from "../../../components/charts/QualityMonthlyLegend";
import {Modal} from "../../../components/Modal";
import chartDataIndicatior from '../../../whitelabel/chartDataIndicator';
import {ReactComponent as IconQuality} from '../../../assets/svg-icons/Icon-list-check.svg';

const typeViews = {
  SCC: 'SCC',
  TBC: 'TBC',
  FAT: 'FAT',
  PROTEIN: 'PROTEIN'
};

// const styles = {
//   tooltip: {
//     container: {
//       position: 'absolute',
//       backgroundColor: '#FFF',
//       boxShadow: '0 5px 13px #657fa0',
//       zIndex: 1000,
//       width: 120,
//       height: 76,
//       borderRadius: 6,
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       flexDirection: 'column'
//     },
//     dateText: {
//       color: '#657FA0',
//       fontSize: 12,
//       fontWeight: '400',
//     },
//     valueText: {
//       color: '#15CE9A',
//       fontSize: 16,
//       fontWeight: '500'
//     }
//   }
// };

// class Tooltip extends React.Component {
//   static defaultEvents = VictoryTooltip.defaultEvents;
//
//   render() {
//     const { data } = this.props;
//     return (
//       <div>
//         <div style={styles.tooltip.dateText}>{data.x}</div>
//         <div style={styles.tooltip.valueText}>{data.y}</div>
//         <VictoryTooltip {...this.props} renderInPortal={false} />
//       </div>
//     )
//   }
// }

// Separates the same day collect
const CHART_BAR_DATE_FORMAT = 'DD/MM/s';

const orderByDate = (arr) => _.orderBy(arr, 'x');

const filter = (qualityAll, type, month, year) => {
  if (!qualityAll) {
    return [];
  }

  const ans = [];
  qualityAll.map((data) => {
    if (!data.date) {
      return ans;
    }

    const date = moment(data.date);

    if (date.month() !== month || date.year() !== year) {
      return ans;
    }

    switch (type) {
      case typeViews.SCC:
        if(data.scc) {
          ans.push({
            x: moment(data.date)
              .format(CHART_BAR_DATE_FORMAT),
            y: data.scc,
          });
        }
        break;
      case typeViews.TBC:
        if(data.tbc) {
          ans.push({
            x: moment(data.date)
              .format(CHART_BAR_DATE_FORMAT),
            y: data.tbc
          });
        }
        break;
      case typeViews.FAT:
        if(data.fat) {
          ans.push({
            x: moment(data.date)
              .format(CHART_BAR_DATE_FORMAT),
            y: data.fat
          });
        }
        break;
      case typeViews.PROTEIN:
        if(data.protein) {
          ans.push({
            x: moment(data.date)
              .format(CHART_BAR_DATE_FORMAT),
            y: data.protein
          });
        }
        break;
      default:
        break;
    }
    return ans;
  });

  return orderByDate(ans);
};

const filterConsolidated = (consolidatedAll, type, month, year) => {
  const consolidated = [];
  if (consolidatedAll) {
    consolidatedAll.map((data) => {
      if (!data.month || !data.year) {
        return;
      }

      if (Number.parseInt(data.year, 10) === year && Number.parseInt(data.month, 10) === month) {
        consolidated.push(data);
      }
    });
  }

  return consolidated;
};

const filterYear = (qualityAll, type, year, color) => {
  if (!qualityAll) {
    return [];
  }

  // same year
  const dataAllSameYear = [];
  qualityAll.map((data) => {
    if (moment(data.date).year() !== year) {
      return dataAllSameYear;
    }
    return dataAllSameYear.push(data);
  });
  const ans = [];
  const dataGroupedByMonth = _.groupBy(dataAllSameYear, data => moment(data.date)
    .month());
  getMonths()
    .map((month) => {
      const monthData = dataGroupedByMonth[month.value];
      if (monthData) {
        switch (type) {
          case typeViews.SCC:
            ans.push({
              x: month.value,
              y: _.meanBy(monthData, value => value.scc),
              year,
              color
            });
            break;
          case typeViews.TBC:
            ans.push({
              x: month.value,
              y: _.meanBy(monthData, value => value.tbc),
              year,
              color
            });
            break;
          case typeViews.FAT:
            ans.push({
              x: month.value,
              y: _.meanBy(monthData, value => value.fat),
              year,
              color
            });
            break;
          case typeViews.PROTEIN:
            ans.push({
              x: month.value,
              y: _.meanBy(monthData, value => value.protein),
              year,
              color
            });
            break;
          default:
            break;
        }
      }
      return ans;
    });

  // Hack for null values
  if (ans.length <= 1) {
    ans.push({
      x: 0,
      y: 0
    });
    ans.push({
      x: 0,
      y: 0
    });
  }

  // const _consolidated = [];
  // if (consolidatedAll) {
  //   consolidatedAll.map((data) => {
  //     if (!data.month || !data.year) {
  //       return;
  //     }
  //     if (Number.parseInt(data.year, 10) === year) {
  //       _consolidated.push(data);
  //     }
  //   });
  // }

  // return {
  //   quality: ans,
  //   consolidated: _consolidated
  // };

  return ans;
};

const CURRENT_MONTH = moment().month();
const CURRENT_YEAR = moment().year();
const lateYearColor = '#3BD6AA';
const lastYearColor = '#4976EF';
const currentYearColor = '#F26894';

export function QualitySection(props) {
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);
  const [qualityData, setQualityData] = useState([]);
  const [consolidatedAllData, setConsolidatedAllData] = useState([]);
  const [consolidatedData, setConsolidatedData] = useState([]);
  const [resultsView, setResultsView] = useState('month');
  const [typeView, setTypeView] = useState(typeViews.SCC);
  const [years, setYears] = useState([]);
  const months = moment.months().map((name, i) => ({name, value: i}));
  const [currentMonth, setCurrentMonth] = useState(CURRENT_MONTH);
  const [currentYear, setCurrentYear] = useState(CURRENT_YEAR);
  const [dataLateYear, setDataLateYear] = useState([]);
  const [dataLastYear, setDataLastYear] = useState([]);
  const [dataYear, setDataYear] = useState([]);
  const [dataMonth, setDataMonth] = useState([]);
  const [partials, setPartials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalInfoOpen, toggleModalInfo] = useState(false);
  const [tierColor, setTierColor] = useState();
  const [viewPartials, toggleViewPartials] = useState(false);

  function loadQualityData() {
    data.get(`/quality/${props.id}?cachetick=${Math.random()}`)
      .then(({data}) => {
        setQualityData([...qualityData, ...data]);
        setLoading(false);
      })
      .catch(err => console.error(err));
    data.get(`/consolidated/${props.id}?cachetick=${Math.random()}`)
      .then(({data}) => {
        setConsolidatedAllData([...consolidatedData, ...data]);
        setLoading(false);
      }).catch(err => console.error(err));
  }

  function handleModalInfo(color) {
    setTierColor(color);
    toggleModalInfo(true);
  }

  useEffect(() => {
    loadQualityData();
  }, []);

  useEffect(() => {
    setBreadcrumbRightContent({
      rightContent: <ResultsMonthYear onChange={setResultsView} view={resultsView}/>
    });

    return () => {
      setBreadcrumbRightContent({
        rightContent: null
      });
    }
  }, [resultsView, setBreadcrumbRightContent]);

  useEffect(() => {
    setYears(_.map(_.keys(_.groupBy(qualityData, item => moment(item.date).year())), value => ({value: Number(value)})));

    switch (resultsView) {
      case 'month':
        const dataMonth = filter(qualityData, typeView, currentMonth, currentYear);
        const consolidated = filterConsolidated(consolidatedAllData, typeView, currentMonth, currentYear);
        setPartials(dataMonth.slice(0, 5).map(item => ({date: `${item.x} /${currentYear}`, result: item.y})));
        setDataMonth(dataMonth);
        setConsolidatedData(consolidated);
        break;
      case 'year':
        setDataLateYear(filterYear(qualityData, typeView, currentYear - 2, lateYearColor));
        setDataLastYear(filterYear(qualityData, typeView, currentYear - 1, lastYearColor));
        setDataYear(filterYear(qualityData, typeView, currentYear, currentYearColor));
        break;
      default:
        break;
    }
  }, [qualityData, resultsView, typeView, currentMonth, currentYear]);

  if (loading) {
    return generalLoading(1);
  }

  const futureDate = currentMonth > CURRENT_MONTH && currentYear >= CURRENT_YEAR;
  const isPartialsPeriod = !futureDate && (CURRENT_YEAR === currentYear && CURRENT_MONTH <= currentMonth);
  const isAccomplishedPeriod = !futureDate && !isPartialsPeriod;

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center p-4 border-bottom'>
        <div>
          <b><FormattedMessage id='common.show_by'/>:</b>
          <button
            className={'btn btn-secondary mx-2' + (typeView === typeViews.SCC ? ' active' : '')}
            onClick={() => setTypeView(typeViews.SCC)}
            style={{minWidth: 108}}
          >
            <FormattedMessage id="SCC" defaultMessage="CCS"/>
          </button>
          <button
            className={'btn btn-secondary mx-2' + (typeView === typeViews.TBC ? ' active' : '')}
            onClick={() => setTypeView(typeViews.TBC)}
            style={{minWidth: 108}}
          >
            <FormattedMessage id="TBC" defaultMessage="CBT"/>
          </button>
          <button
            className={'btn btn-secondary mx-2' + (typeView === typeViews.FAT ? ' active' : '')}
            onClick={() => setTypeView(typeViews.FAT)}
            style={{minWidth: 108}}
          >
            <FormattedMessage id='FAT' defaultMessage="Gordura"/>
          </button>
          <button
            className={'btn btn-secondary mx-2' + (typeView === typeViews.PROTEIN ? ' active' : '')}
            onClick={() => setTypeView(typeViews.PROTEIN)}
            style={{minWidth: 108}}
          >
            <FormattedMessage id="PROTEIN" defaultMessage="Proteina"/>
          </button>
        </div>

        <PeriodSelector
          years={years}
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
          hideMonth={resultsView === 'year'}
          months={months}
          currentMonth={currentMonth}
          setCurrentMonth={setCurrentMonth}
        />

      </div>
      <div className='row m-0'>
        {resultsView === 'month' && (
          <>
            <div className='col-7 border-right p-0'>
              <div className='p-4'>
                <QualityMonthlyChart data={dataMonth} type={typeView} toggleModal={handleModalInfo}/>
                <Modal
                  isOpen={isModalInfoOpen}
                  clickOutside={() => toggleModalInfo(false)}
                  onRequestClose={() => toggleModalInfo(false)}
                >
                  <div style={{overflow: 'auto', maxHeight: '68vh', padding: '0 4px'}}>
                    <div style={{
                      backgroundColor: tierColor,
                      height: 16,
                      width: '100%',
                      borderRadius: 8,
                      opacity: 0.2,
                      marginBottom: 14
                    }}/>
                    <span style={{
                      color: '#3D5879',
                      fontSize: 16,
                      fontWeight: '500',
                      marginBottom: 14,
                      display: 'block'
                    }}>
                      <FormattedMessage
                        id="common.milk_payment_system"
                        defaultMessage="Sistema de pagamento do leite"
                      />
                    </span>
                    <div className="quality-info">
                      <table>
                        <thead>
                        <tr>
                          <td>
                            <FormattedMessage id={typeView}/>
                          </td>
                          <td>
                            <FormattedMessage id={`profit_${typeView}`

                            }/>
                          </td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          chartDataIndicatior[typeView] && chartDataIndicatior[typeView].map((item) => (
                            <tr>
                              <td>{item.value}</td>
                              <td>
                                <div style={{backgroundColor: item.tier, padding: 6, borderRadius: 6}}>
                                  {item.prize}
                                </div>
                              </td>
                            </tr>
                          ))
                        }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Modal>
              </div>
              {
                dataMonth.length > 0 && (
                  <div className='border-top w-100 m-0 p-4'>
                    <QualityMonthlyLegend/>
                  </div>
                )
              }
            </div>
            <div className='col-5 p-5'>
              {
                isPartialsPeriod && !futureDate && (
                    <>
                      <div>
                        <b><FormattedMessage id='producers.partials'/></b>
                      </div>
                      <div className='row my-3 px-2'>
                        {partials.map((partial, i) => (
                          <div className='col-sm-6 p-2' key={i}>
                            <div className='d-flex flex-column align-items-center border border-radius py-4'>
                              <small>
                                <FormattedMessage id='common.day'/>: {partial.date}
                              </small>
                              <small><strong><FormattedMessage id='common.result'/>:</strong></small>
                              <h4 className='m-0 text-bold'>
                                <FormattedNumber value={partial.result}/>
                              </h4>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div>
                        <b><FormattedMessage id='producers.accomplished'/></b>
                        <div style={{backgroundColor: '#FFF4E0', borderRadius: 6, padding: 16, textAlign: 'center'}}>
                          <span style={{color: '#BB744A'}}>
                            <FormattedMessage id='common.in_progress' />
                          </span>
                        </div>
                      </div>
                    </>
                  )
              }

              {
                isAccomplishedPeriod && (
                  <div>
                    <b><FormattedMessage id='producers.accomplished'/></b>
                    <div className='flex-column my-3 px-2'>
                      <div
                        className="align-items-center d-flex flex-column justify-content-center p-4 text-center mb-4 border border-radius">
                          <span
                            style={{
                              color: '#41B049',
                              fontSize: 14,
                              fontWeight: '500'
                            }}
                          >
                            Valor de pagamento
                          </span>
                        <span
                          style={{
                            color: '#41B049',
                            fontSize: 18,
                            marginBottom: 12
                          }}
                        >
                            <FormattedNumber
                              value={consolidatedData.length && consolidatedData[0][`billing${typeView}`]}/>
                          </span>
                        <span
                          style={{
                            fontSize: 13,
                            color: '#657FA0',
                          }}
                        >
                            Obs.: Valor realizado do mês ou média geométrica do mês corrente mais os dois meses anteriores.
                          </span>
                      </div>
                      <div
                        className="align-items-center d-flex flex-column justify-content-center p-4 text-center mb-4 border border-radius">
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: '500',
                              color: '#657FA0'
                            }}
                          >
                            Valor realizado do mês
                          </span>
                        <span>
                            <FormattedNumber
                              value={consolidatedData.length && consolidatedData[0][`reference${typeView}`]}/>
                          </span>
                        <button
                          className="btn"
                          onClick={() => toggleViewPartials(!viewPartials)}
                          style={{
                            backgroundColor: '#F5F6FA',
                            width: '100%',
                            color: '#657FA0',
                            fontSize: 13,
                          }}
                        >
                          {
                            !viewPartials
                              ? 'Ver resultados parciais'
                              : 'X'
                          }
                        </button>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            flexWrap: 'noWrap',
                            maxWidth: '100%'
                          }}
                        >
                          {
                            viewPartials && partials.map((partial, i) => (
                              <div className='col-sm-6 p-2' key={i}>
                                <div className='d-flex flex-column align-items-center border border-radius py-4'>
                                  <small>
                                    <FormattedMessage id='common.day'/>: {partial.date}
                                  </small>
                                  <small><strong><FormattedMessage id='common.result'/>:</strong></small>
                                  <h4 className='m-0 text-bold'>
                                    <FormattedNumber value={partial.result}/>
                                  </h4>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <div
                        className="align-items-center d-flex flex-column justify-content-center p-4 text-center mb-4 border border-radius">
                        <span>Valor médio coopeerativa</span>
                        <span>
                            <FormattedNumber
                              value={consolidatedData.length && consolidatedData[0][`cooperativeAverage${typeView}`]}/>
                          </span>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </>
        )}
        {resultsView === 'year' && (
          <>
            <div className='col-9 border-right p-0'>
              <div className='p-4'>
                <QualityYearlyChart
                  dataLateYear={dataLateYear}
                  dataLastYear={dataLastYear}
                  dataYear={dataYear}
                  selectedYear={currentYear}
                  type={typeView}
                />
              </div>
            </div>
            <div className='col-3 p-5 text-center'>
              <div className='shadow bg-white rounded p-4'>
                <div className="align-items-center d-flex flex-column justify-content-center">
                  <div
                    style={{
                      padding: 10,
                      backgroundColor: 'rgba(65,176,73,0.07)',
                      borderRadius: 6,
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: 20
                    }}
                  >
                    <IconQuality fill="#41B049"/>
                  </div>
                  <small><FormattedMessage id='producers.annual_average'/></small>
                </div>
                <span
                  style={{
                    color: '#3D5879',
                    fontSize: 18,
                    fontWeight: '700'
                  }}
                >
                  <FormattedNumber value={parseInt((
                    dataLateYear.reduce((r, item) => r + item.y, 0) +
                    dataLastYear.reduce((r, item) => r + item.y, 0) +
                    dataYear.reduce((r, item) => r + item.y, 0)
                  ) / 3)}/>
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
