import React, {useState} from 'react';
import {ReactComponent as IconSearch} from "../assets/svg-icons/Icon-search.svg";
import {ReactComponent as IconClose} from "../assets/svg-icons/Icon-close.svg";
import {InputField} from "./form/InputField";
import {FormControl} from "./form/FormContext";

const ToggleButton = ({ showInput, toggleInput }) => (
  <button
    className="border-radius"
    style={{
      cursor: 'pointer',
      border: 0,
      outline: 0,
      width: 36,
    }}
    onClick={() => toggleInput(!showInput)}
  >
    {
      showInput
      ? <IconClose fill="#657FA0" />
      : <IconSearch fill="#657FA0" />
    }
  </button>
);

export default function CustomToolbar ({ tableProps }) {
  const { onSearchChanged } = tableProps;
  const [showInput, toggleInput] = useState(false);

  return(
    <div className={`d-flex flex-row-reverse border-bottom align-content-center justify-content-between p-4`}>
      <div className="right-side d-flex flex-row">
        <div
          style={{
            backgroundColor: '#F5F6FA',
            cursor: 'pointer',
            display: 'flex',
            position: 'relative',
            height: 36
          }}
        >
          {
            showInput && (
              <FormControl onSubmit={e => e.preventDefault() }>
                <IconSearch
                  style={{
                    position: 'absolute',
                    top: '35%',
                    left: '5%',
                    zIndex: 1
                  }}
                  fill="#657FA0"
                />
                <InputField
                  maxLength="256"
                  controlName="tableSearch"
                  type="search"
                  onChange={onSearchChanged}
                  style={{
                    paddingLeft: '16%',
                    height: 36,
                  }}
                />
              </FormControl>
            )
          }
          <ToggleButton toggleInput={() => {toggleInput(!showInput); onSearchChanged();}} showInput={showInput} />
        </div>
      </div>
    </div>
  );
}
