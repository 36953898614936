import React from "react";
import { StyledBadge } from "./styles";
import Img from "react-image";

import {
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Divider,
  Tooltip,
  Chip,
} from "@material-ui/core";
import { CustomCardItem } from "../../../components/CustomCardItem";
import { MdThumbUp } from "react-icons/md";
import moment from "moment";

const howLongPosted = (date) => moment(date).toNow(true);
const YOUTUBE_PATTERN =
  '^((http\\:\\/\\/|https\\:\\/\\/)?((www|m)\\.youtube\\.com\\/|youtu\\.be\\/)(watch\\?v=[^"&?\\/ ]{11}|[^"&?\\/ ]{11})|(watch\\?v=[^"&?\\/ ]{11}|[^"&?\\/ ]{11}))';

const extractYoutubeId = (url) => {
  const matched = url && url.match(new RegExp(YOUTUBE_PATTERN));
  const id =
    matched && matched[5]
      ? matched[5].replace("watch?v=", "")
      : matched && matched[6]
      ? matched[6].replace("watch?v=", "")
      : "";
  console.debug(id);
  return id;
};

function ItemPreview({ item }) {
  const youtubeId = extractYoutubeId(item.videoUrl);

  if (!item) {
    return null;
  }

  return (
    <React.Fragment>
      <CustomCardItem>
        <Card key={item.id} className="my-3">
          <CardContent>
            <Typography variant="h5" color="textSecondary" className="mb-4">
              {item.title}
            </Typography>
            <div className="mb-3">
              {(item.categories || []).map((categ, index) => (
                <Chip
                  size="medium"
                  key={index}
                  variant="outlined"
                  className="mr-3"
                  label={categ}
                  color={`primary`}
                />
              ))}
            </div>
            {item.type === "MAGAZINE" ? (
              <>
                <Typography
                  color="textSecondary"
                  component="p"
                  className="mb-2"
                >
                  {item.subtitle}
                </Typography>
                <Typography
                  color="textSecondary"
                  component="p"
                  className="mb-3"
                >
                  {item.edition}
                </Typography>
              </>
            ) : (
              <Typography color="textSecondary" component="p" className="mb-3">
                {item.description}
              </Typography>
            )}

            <div className="mb-3 d-flex overflow-auto">
              {item.type === "NEWS" ? (
                // item.attachments.map((img, index) => (
                <Img
                // key={index}
                // className="mx-3 rounded"
                // alt={`${img}`}
                // height={156}
                // src={img}
                />
              ) : (
                // ))
                <Img
                  className="mx-3 rounded"
                  alt={`${item.cover}`}
                  height={156}
                  src={item.cover}
                />
              )}
            </div>
            {item.type === "NEWS" && (
              <div className="py-1" style={{ textAlign: "center" }}>
                {youtubeId && (
                  <div className="py-1" style={{ textAlign: "center" }}>
                    {youtubeId ? (
                      <iframe
                        width="420"
                        height="315"
                        src={`https://www.youtube.com/embed/${youtubeId}?rel=0&autoplay=0&showinfo=0&controls=0`}
                      ></iframe>
                    ) : null}
                  </div>
                )}
              </div>
            )}
          </CardContent>
          <Divider />
          <CardActions
            disableSpacing
            className="d-flex justify-content-between"
          >
            <div className="d-flex align-items-center">
              <Tooltip
                placement="top"
                title={`${item.likesCount} curtidas`}
                arrow
              >
                <IconButton>
                  <StyledBadge badgeContent={item.likesCount} color="secondary">
                    <MdThumbUp size={22} color="#379" />
                  </StyledBadge>
                </IconButton>
              </Tooltip>
              <Typography className="ml-5">
                {`${howLongPosted(
                  item.updatedAt || item.createdAt
                )} ${"atrás"}`}
              </Typography>
            </div>
          </CardActions>
        </Card>
      </CustomCardItem>
    </React.Fragment>
  );
}

export { ItemPreview };
