import React, { useState, useEffect } from "react";
import { CheckedTab } from "../../components/tabs/CheckedTab";
import { CheckedTabsWrapper } from "../../components/tabs/CheckedTabsWrapper";
import { data, communication } from "../../helpers/axiosModules";
import { FormattedMessage } from "react-intl";
import { Table } from "../../components/Table";
import { camelCaseString } from "../../utils/String.utils";
import { RankedCollapsible } from "./components/RankedCollapsible";
import { User } from "../../components/User";
import { ReactComponent as CalendarIcon } from "../../assets/svg-icons/icon-calendar-grey.svg";
import { ReactComponent as LikeIcon } from "../../assets/svg-icons/Icon-like.svg";
import { ReactComponent as ShareIcon } from "../../assets/svg-icons/Icon-share.svg";
import Moment from "react-moment";
import { BreadCrumbDate } from "../../components/BreadCrumbDate";
import moment from "moment";
import axios from "axios";

const CheckedTabAccess = ({ children, text, icon, active, onClick }) => (
  <CheckedTab active={active} onClick={onClick}>
    <div className="d-flex justify-content-between align-items-center">
      {text}
      <div style={{ display: "flex", flex: 1, margin: "0 10%" }}>
        {children}
      </div>
      {icon}
    </div>
  </CheckedTab>
);

export function PublicationsPage() {
  const [totals, setTotals] = useState({
    total_publications: "-",
    liked_news: "-",
    shared_news: "-",
  });
  let firstLoad = true;
  const [enabledTab, setEnabledTab] = useState("total_publications");
  const [topPublications, setTopPublications] = useState([]);
  const [postsLike, setPostsLike] = useState([]);
  const [postsShare, setPostsShare] = useState([]);
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState();
  const [searchDate, setSearchDate] = useState({
    initialDate: moment().subtract(6, "months").format("YYYY-MM-DD"),
    finalDate: moment().format("YYYY-MM-DD"),
  });
  const source = axios.CancelToken.source();

  const fetchData = async () => {
    setLoading(true);
    // TODO: Where do these analytics routes come from?
    const p = await Promise.all([
      data.get(
        `/analytics/postviews?initdate=${searchDate.initialDate}&enddate=${
          searchDate.finalDate
        }&cachetick=${Math.random()}`
      ),
      data.get(
        `/analytics/getpostlike?initdate=${searchDate.initialDate}&enddate=${
          searchDate.finalDate
        }&cachetick=${Math.random()}`
      ),
      data.get(
        `/analytics/getpostshare?initdate=${searchDate.initialDate}&enddate=${
          searchDate.finalDate
        }&cachetick=${Math.random()}`
      ),
      loadNews(),
    ]);
    setLoading(false);

    setTopPublications(
      p[0].data.map((topPublication) => {
        return {
          _id: topPublication._id,
          title: camelCaseString(topPublication.headerTitle),
          responsible: {
            name: topPublication.createdUserId
              ? topPublication.createdUserId.name
              : "-",
            image_url: topPublication.createdUserId
              ? topPublication.createdUserId.photoLink
              : "-",
          },
          createDate: topPublication.createDate,
        };
      })
    );

    setPostsLike(
      p[1].data.map((postLike) => {
        return {
          _id: postLike._id,
          title: camelCaseString(postLike.headerTitle),
          responsible: {
            name: postLike.createdUserId ? postLike.createdUserId.name : "-",
            image_url: postLike.createdUserId
              ? postLike.createdUserId.photoLink
              : "-",
          },
          createDate: postLike.createDate,
        };
      })
    );

    setPostsShare(
      p[2].data.map((postShare) => {
        return {
          _id: postShare._id,
          title: camelCaseString(postShare.headerTitle),
          responsible: {
            name: postShare.createdUserId ? postShare.createdUserId.name : "-",
            image_url: postShare.createdUserId
              ? postShare.createdUserId.photoLink
              : "-",
          },
          createDate: postShare.createDate,
        };
      })
    );
  };

  const loadNews = async (limit, skip, termValue) => {
    let moreNews = await communication.get(
      `/posts?cachetick=${Math.random()}`,
      {
        params: {
          limit,
          skip,
        },
        cancelToken: source.token,
      }
    );

    if (!moreNews || !moreNews.data) {
      return;
    }

    moreNews.data = moreNews.data.map((topPublication) => {
      return {
        _id: topPublication._id,
        title: camelCaseString(topPublication.headerTitle),
        responsible: {
          name: topPublication.createdUserId
            ? topPublication.createdUserId.name
            : "-",
          image_url: topPublication.createdUserId
            ? topPublication.createdUserId.photoLink
            : "-",
        },
        createDate: topPublication.createDate,
      };
    });

    setNews(
      [...news, ...moreNews.data].sort((a, b) => {
        const dateA = new Date(a["createDate"]);
        const dateB = new Date(b["createDate"]);
        if (dateA > dateB) {
          return -1;
        }
        if (dateA < dateB) {
          return 1;
        }
        return 0;
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [searchDate, setSearchDate]);

  useEffect(() => {
    setTotals({
      total_publications: news.length,
      liked_news: postsLike.length,
      shared_news: postsShare.length,
    });
  }, [topPublications]);

  return (
    <>
      <BreadCrumbDate
        onChange={(value) => {
          if (
            firstLoad &&
            value &&
            value.setFinalDate &&
            value.setInitialDate
          ) {
            firstLoad = false;
            value.setFinalDate(null);
            value.setInitialDate(null);
          } else if (value.initialDate && value.finalDate) {
            setSearchDate({
              initialDate: moment(value.initialDate).format("YYYY-MM-DD"),
              finalDate: moment(value.finalDate).format("YYYY-MM-DD"),
            });
          }
        }}
      />
      <CheckedTabsWrapper>
        <CheckedTabAccess
          text={<FormattedMessage id="publications.total_publications" />}
          active={enabledTab === "total_publications"}
          onClick={() => setEnabledTab("total_publications")}
        >
          <b>{totals.total_publications}</b>
        </CheckedTabAccess>
        <CheckedTabAccess
          text={<FormattedMessage id="publications.liked_news" />}
          active={enabledTab === "liked_news"}
          onClick={() => setEnabledTab("liked_news")}
          icon={
            <LikeIcon
              fill={enabledTab === "liked_news" ? "#41B049" : "#8EA1B8"}
              width={18}
              height={18}
            />
          }
        >
          <b>{totals.liked_news}</b>
        </CheckedTabAccess>
        <CheckedTabAccess
          text={<FormattedMessage id="publications.shared_news" />}
          active={enabledTab === "shared_news"}
          onClick={() => setEnabledTab("shared_news")}
          icon={
            <ShareIcon
              fill={enabledTab === "shared_news" ? "#41B049" : "#8EA1B8"}
              width={18}
              height={18}
            />
          }
        >
          <b>{totals.shared_news}</b>
        </CheckedTabAccess>
      </CheckedTabsWrapper>

      <div className="row pb-3">
        <div className="col-9">
          <div className="card">
            <Table
              columns={[
                {
                  title: "#",
                  render: (rowData) => <span>{rowData.tableData.id + 1}º</span>,
                  disableClick: true,
                  sorting: false,
                },
                {
                  title: <FormattedMessage id="common.title" />,
                  field: "title",
                  disableClick: true,
                  sorting: false,
                },
                {
                  title: <FormattedMessage id="common.responsible" />,
                  render: (rowData) =>
                    rowData.responsible ? (
                      <User user={rowData.responsible} />
                    ) : null,
                  disableClick: true,
                  sorting: false,
                },
                {
                  title: <FormattedMessage id="common.date" />,
                  disableClick: true,
                  sorting: false,
                  render: (rowData) => (
                    <Moment format="DD-MM-YYYY">{rowData.createDate}</Moment>
                  ),
                },
              ]}
              isLoading={loading}
              data={
                enabledTab === "total_publications"
                  ? news
                  : enabledTab === "liked_news"
                  ? postsLike
                  : postsShare
              }
              offsetTop={345}
              toolbar={true}
              search={true}
              pickerFilters={false}
            />
          </div>
        </div>
        <div className="col-3">
          <div className="card full-height">
            <div className="text-fold p-4 text-center text-bold border-bottom">
              <FormattedMessage id="users.top_publications" />
            </div>
            <div>
              {topPublications.map((publication, i) => (
                <RankedCollapsible
                  trigger={publication.title}
                  numeration={i + 1}
                  key={i}
                >
                  <div className="pl-5 pb-4">
                    <div className="pl-1">
                      <User user={publication.responsible} size={24} />
                    </div>
                    <div
                      className="d-flex mt-3"
                      style={{ paddingLeft: "0.5rem" }}
                    >
                      <CalendarIcon className="ml-1 mr-4" />
                      <Moment format="DD-MM-YYYY">
                        {publication.createDate}
                      </Moment>
                    </div>
                  </div>
                </RankedCollapsible>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
