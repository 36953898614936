import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { ReactComponent as CloseIcon } from '../assets/svg-icons/Icon-close.svg';
import { ClipLoader } from 'react-spinners';
import { FormattedMessage } from 'react-intl';

ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0, 28, 43, 0.64)';
ReactModal.defaultStyles.content = {
  position: 'relative',
  boxShadow: '0 14px 33px -13px rgba(71, 86, 105, 0.5)',
  backgroundColor: '#FFF',
  borderRadius: 6,
  width: '100%'
};

const customStyles = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100
  },
  content: {}
};

const closeStyle = {
  position: 'absolute',
  width: 33,
  height: 33,
  borderRadius: 33,
  boxShadow: '0 7px 9px 0 rgba(57, 88, 170, 0.21)',
  backgroundColor: '#ffffff',
  border: 'none',
  right: 0,
  top: -38
};

let loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  WebkitTransition: 'all 0.15s ease 0s',
  opacity: 1
};

export function Modal({
  isOpen,
  onRequestClose,
  children,
  clickOutside = true,
  loading = false,
  size = 'small',
  padding = 32,
  onConfirm
}) {

  useEffect(() => {
    ReactModal.setAppElement('body');
    ReactModal.defaultStyles.content.padding = padding;
  }, []);

  switch (size) {
    case 'small':
      customStyles.content = {
        marginTop: '10%',
        marginRight: '35%',
        marginBottom: '10%',
        marginLeft: '35%',
        minWidth: 525
      };
      break;
    case 'medium':
      customStyles.content = {
        marginTop: '10%',
        marginRight: '35%',
        marginBottom: '10%',
        marginLeft: '35%',
        minWidth: 725
      };
      break;
    default:
      break;
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={clickOutside ? onRequestClose : null}
      style={{
        ...customStyles,
        content: {
          ...customStyles.content,
          maxHeight: loading ? 0 : '80vh',
          overflow: loading ? 'hidden' : 'unset',
          ...(onConfirm ? {padding: 0 } : {})
        }
      }}
    >
      <div style={{...loadingStyle, opacity: loading ? 1 : 0, pointerEvents: loading ? 'all' : 'none'}}>
        <ClipLoader color='#41b049'/>
      </div>
      <button style={closeStyle} onClick={onRequestClose}>
        <CloseIcon />
      </button>
      <div style={{padding: onConfirm ? 32 : 0 }}>
        {children}
      </div>
      {onConfirm && (
        <div
          className='d-flex justify-content-center px-5 py-5'
          style={{backgroundColor: '#f5f6fa', width: '100%'}}
        >
          <button className='btn btn-outline-secondary mx-2 w-100' onClick={onRequestClose}>
            <FormattedMessage id='common.cancel'/>
          </button>
          <button className='btn btn-success mx-2 w-100' onClick={onConfirm}>
            <FormattedMessage id='common.confirm'/>
          </button>
        </div>
      )}
    </ReactModal>
  )
}

ReactModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  clickOutside: PropTypes.bool,
  size: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string,
  padding: PropTypes.number,
  onConfirm: PropTypes.func
};
