import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { application } from "../../helpers/axiosModules";
import { FormControl } from "../../components/form/FormContext";
import { InputField } from "../../components/form/InputField";
import Loading from "../../components/loading/Loading";
import { BreadcrumbContext } from "./SettingsWrapper";
import { supportPut, supportPost, supportGet } from "../../api/routes-app";

let oldData = {};

export function Support({ history }) {
  const [support, setSupport] = useState();
  const [loading, setLoading] = useState(true);
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const { _id: id } = support;
    if (id) {
      application.put(supportPut(id), support).then((res) => {
        if (res) {
          if (res.status === 200) {
            alert("Saved successfully");
          } else if (res.status === 401) {
            alert("no permissions");
          }
        }
        setLoading(false);
      });
    } else {
      application.post(supportPost(), support).then((res) => {
        if (res) {
          if (res.status === 201) {
            alert("Successfully created");
            setSupport({
              id: res.data,
              ...support,
            });
          } else if (res.status === 401) {
            alert("no permissions");
          }
        }
        setLoading(false);
      });
    }

    console.debug("form submitted", e);
  }

  function handleCancel(e) {
    e.preventDefault();
    setSupport(oldData);
    history.push("/dashboard");
  }

  useEffect(() => {
    async function fetchSupport() {
      application
        .get(`${supportGet()}?cachetick=${Math.random()}`)
        .then((res) => {
          const { data } = res;
          setSupport(data);
          oldData = data;
          setLoading(false);
        });
    }

    setBreadcrumbRightContent({});
    fetchSupport();
  }, []);

  useEffect(() => {
    console.debug(oldData);
    console.debug(support);
  }, [support]);

  if (loading) {
    return <Loading />;
  }

  return (
    <FormControl
      values={support}
      onChange={(values) => setSupport(values)}
      onSubmit={handleSubmit}
    >
      <div className="card">
        <div className="d-flex justify-content-center p-5">
          <div className="col-7 px-5">
            <div className="px-5 py-2">
              <div>
                <fieldset>
                  <legend
                    className="mb-4"
                    style={{ fontWeight: "500", color: "#1D2E44" }}
                  >
                    <FormattedMessage id="support.title" />
                  </legend>
                </fieldset>
                <div className="form-group">
                  <InputField
                    controlName="email"
                    placeholder={<FormattedMessage id="support.email" />}
                  />
                </div>
                <div className="form-group">
                  <InputField
                    controlName="phone"
                    placeholder={<FormattedMessage id="support.phone" />}
                  />
                </div>
                <div className="form-group">
                  <InputField
                    controlName="instagram"
                    placeholder={<FormattedMessage id="support.instagram" />}
                  />
                </div>
                <div className="form-group">
                  <InputField
                    controlName="facebook"
                    placeholder={<FormattedMessage id="support.facebook" />}
                  />
                </div>
                <div className="form-group">
                  <InputField
                    controlName="youtube"
                    placeholder={<FormattedMessage id="support.youtube" />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-top p-4 d-flex justify-content-around">
          <button
            onClick={handleCancel}
            className="btn btn-outline-secondary p-4"
            style={{ width: 270 }}
          >
            <FormattedMessage id="common.cancel" />
          </button>
          <button
            type="submit"
            className="btn btn-success p-4"
            style={{ width: 270 }}
          >
            <FormattedMessage id="common.confirm" />
          </button>
        </div>
      </div>
    </FormControl>
  );
}
