import * as Yup from "yup";

export const validations = Yup.object({
  title: Yup.string().required("É obrigatório a inserção do título."),
  // audience: Yup.array().required("É obrigatório a inserção da cooperativa."),
  // attachments: Yup.array().when("type", {
  //   is: "magazine",
  //   then: Yup.array().required("É obrigatório a inserção").min(1),
  // }),
});
