import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'react-simple-checkbox';
import { FormContext } from './FormContext';
import { ReactComponent as CheckBoxIcon } from '../../assets/svg-icons/Icon-check-box.svg';
import { ReactComponent as CheckBoxCheckedIcon } from '../../assets/svg-icons/Icon-check-box-green.svg';

export function CheckboxField({controlName, labelText, defaultChecked, onChange, spacing = true}) {
  const [checked, setChecked] = useState(false);
  const { handleInput, formState } = useContext(FormContext);

  useEffect(() => {    
    setChecked(defaultChecked);
  }, [defaultChecked]);

  useEffect(() => {
    if (typeof formState[controlName] === 'boolean') {
      setChecked(formState[controlName]);
    }
  }, [formState]);

  return (
    <label
      className={`d-flex align-items-center cursor-pointer ${spacing ? 'pb-3' : 'm-0'}`}
      onClick={() => onChange ? onChange(!checked) : handleInput({name: controlName, value: !checked})}
    >
      <input type='checkbox' name={controlName} defaultChecked={checked} style={{visibility: 'hidden', width: 0}}/>
      {checked ? <span className='child-svg-color-1'><CheckBoxCheckedIcon/></span> : <CheckBoxIcon/>}
      <span className='ml-3'>{labelText}</span>
    </label>
  )
}

CheckboxField.propTypes = {
  controlName: PropTypes.string,
  labelText: PropTypes.any,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  spacing: PropTypes.bool
};
