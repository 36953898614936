import {isValidCnpj} from "@brazilian-utils/is-valid-cnpj";
import {isValidCpf} from "@brazilian-utils/is-valid-cpf";
import validator from "validator";

const { isEmail } = validator;
const inRange = (value, min, max) => value >= min && value <= max;

export {
  isValidCnpj,
  isValidCpf,
  inRange,
  isEmail
};
