import React, { useRef, useState } from "react";
import * as _ from "lodash";

import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../assets/svg-icons/Icon-edit.svg";
import { ReactComponent as InfoIcon } from "../../assets/svg-icons/Icon-info.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg-icons/Icon-trash.svg";
import { IconWithText } from "../../components/IconWithText";
import { Modal } from "../../components/Modal";
import { Table } from "../../components/Table";
import { CircularProgress } from "@material-ui/core";

export default function NotificationsTable({ data, loading, handleDelete }) {
  const tableRef = useRef(null);
  const [isDeleting, setIsDeleting] = useState({});

  return (
    <>
      {
        <Table
          className="pt-5"
          tableRef={tableRef}
          columns={[
            {
              title: <FormattedMessage id="notification.form.title" />,
              field: "title",
              disableClick: true,
              sorting: false,
            },
            {
              title: <FormattedMessage id="notification.form.message" />,
              field: "body",
              disableClick: true,
              sorting: false,
            },
            {
              title: <FormattedMessage id="notification.form.actions" />,
              field: "actions",
              disableClick: true,
              sorting: false,
              render: (rowData) => (
                <div className="d-flex justify-content-center px-1 py-2 child-svg-color-1">
                  <Link
                    className="btn"
                    to={`/notifications/edit/${rowData._id}`}
                  >
                    <IconWithText icon={<EditIcon />} reset={true} />
                  </Link>
                  <button
                    className="btn"
                    onClick={async () => {
                      const { _id } = rowData;
                      setIsDeleting({ [_id]: true, ...isDeleting });
                      await handleDelete(_id);
                      setIsDeleting({ [_id]: false, ...isDeleting });
                    }}
                  >
                    {_.get(isDeleting, rowData._id, false) ? (
                      <CircularProgress style={{ color: "#41b049" }} />
                    ) : (
                      <IconWithText icon={<DeleteIcon />} reset={true} />
                    )}
                  </button>
                </div>
              ),
            },
          ]}
          isLoading={loading}
          loadingType={"linear"}
          data={data}
          paging={false}
        />
      }

      <Modal onRequestClose={() => {}} onConfirm={() => {}}>
        <div className="d-flex flex-column align-items-center text-center">
          <div
            className="px-3 py-2 border-radius mb-5"
            style={{ backgroundColor: "#f5f6fa", fill: "#657fa0" }}
          >
            <InfoIcon />
          </div>
          <h6 className="text-bold">
            <FormattedMessage id="settings.remove_notification_confirm" />
          </h6>
          <div className="py-4"></div>
        </div>
      </Modal>
    </>
  );
}
