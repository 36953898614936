import React, { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { BreadcrumbContext } from "../../components/BreadCrumb";
import { NotificationForm } from "./form";
import { application } from "../../helpers/axiosModules";
import { notificationSave } from "../../api/routes-app";

export function NotificationAdd() {
  const history = useHistory();
  const hookFormParams = useForm({
    mode: "onSubmit",
    shouldUnregister: false,
  });

  const setBreadcrumbRightContent = useContext(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumbRightContent({
      display: [
        <FormattedMessage id="header.notifications" />,
        <FormattedMessage id="settings.add_notification" />,
      ],
    });
  }, []);

  const formSubmit = async (data) => {
    try {
      await application.post(notificationSave(), data);
      toast.success(
        <FormattedMessage id="settings.add_notification_success" />
      );
      history.push("/notifications");
    } catch (err) {
      console.log(err);
      toast.error(<FormattedMessage id="settings.add_notification_error" />);
    }
  };

  return (
    <>
      <NotificationForm hookForm={hookFormParams} formSubmit={formSubmit} />
    </>
  );
}
