import React from "react";
import moment from 'moment';

const thousandNumber = value => value.toString().replace(
  /(\d)(?=(\d{3})+(?!\d))/g,
  '$1.'
);

// const formatDate = (date) => {
//   const split = date.split('/');
//   return `${split[0]}/${split[1]}`;
// };

export class ChartLineTooltip extends React.Component {
  render() {
    const { x, y, datum: data, unit } = this.props;
    const posX = x - 15;
    const posY = y - 66;
    return (
      <g>
        <svg x={posX} y={posY} style={{ overflow: 'visible' }}>
          <text
            x={15}
            y={36}
            fill='#657FA0'
            fontWeight="normal"
            fontSize={12}
            fontFamily={'Roboto'}
            textAnchor="middle"
            alignmentBaseline="central"
          >
            {`${moment().month(data.x).format('MMM')} ${data.year}`}
          </text>
          <text
            x={15}
            y={56}
            fill='#15CE9A'
            fontWeight="bold"
            fontSize={16}
            fontFamily={'Roboto'}
            textAnchor="middle"
            alignmentBaseline="central"
          >
            {`${thousandNumber(parseFloat(data.y).toFixed(0))} ${unit ? unit : ''}`}
          </text>
        </svg>
      </g>
    )
  }
}

