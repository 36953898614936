import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import {ReactComponent as Phone} from '../../../assets/phone-notification.svg';
import {ReactComponent as QualityIcon} from '../../../assets/svg-icons/Icon-quality.svg';
import {ReactComponent as ValeIcon} from '../../../assets/svg-icons/Icon-graphic.svg';
import {ReactComponent as TruckIcon} from '../../../assets/svg-icons/Icon-truck.svg';
import {ReactComponent as MagazineIcon} from '../../../assets/svg-icons/Icon-magazine.svg';
import {ReactComponent as FlagIcon} from '../../../assets/svg-icons/Icon-flag.svg';

function NotificationPhone({location, title, description, icon}) {
  const category = location.pathname.split('/')[3];

  const categories = {
    quality: {
      title: '',
      icon: null
    }
  };

  function renderIcon() {
    console.debug('type icon: ', icon);
    switch (icon) {
      case 'QUALITY':
        return <QualityIcon fill='#fff'/>;
      case 'COLLECT':
        return <ValeIcon fill='#fff'/>;
      case 'TEMPERATURE':
        return <TruckIcon fill='#fff'/>;
      case 'MAGAZINES':
        return <MagazineIcon fill='#fff'/>;
      case 'OTHER':
        return <FlagIcon fill='#fff'/>;
    }
  }

  return (
    <div className='notification-wrapper text-center'>
      <Phone/>
      <div className='notification-box'>
        <div className='notification-icon-wrapper'>
          {renderIcon()}
        </div>
        <div className='notification-info-wrapper'>
          <span className='notification-title'>{title}</span>
          <span className='notification-description'>{description}</span>
        </div>
      </div>
    </div>
  )
}

NotificationPhone.propTypes = {
  name: PropTypes.string.isRequired,
};

export default withRouter(NotificationPhone);
