// import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MdExpandLess, MdLink } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import styled from "styled-components";
import { BreadcrumbContext } from "../../../components/BreadCrumb";
import { CustomCard } from "../../../components/CustomCard";
import Loading from "../../../components/loading/Loading";
import Toaster from "../../../components/Toaster";
import { application } from "../../../helpers/axiosModules";
import { parseJwt } from "../../../helpers/parseJwt";
import { ItemPreview } from "./ItemPreview";
import {
  ContainerTitle,
  InputField,
  SelectTypePublication,
  useStyles,
} from "./styles";
import {
  administratorsGetByIdentification,
  publicationUpdate,
  publicationGet,
} from "../../../api/routes-app";

function EditPublications() {
  const [user, setUser] = useState([]);
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [open, setOpen] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [publication, setPublication] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPublicationById = async () => {
      try {
        setLoading(true);
        const response = await application.get(publicationGet(id));
        setPublication(response.data || []);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPublicationById();
  }, []);

  useEffect(() => {
    async function fetchData(params) {
      const parsedToken = parseJwt(sessionStorage.getItem("jwtoken"));

      const response = await application.get(
        administratorsGetByIdentification(parsedToken.identification)
      );
      setUser(response.data);
      const filteredOptions = [];

      [user.groups].forEach((item) => {
        filteredOptions.push({ label: item, value: item });
      });
    }

    fetchData();
  }, []);

  useEffect(() => {
    setBreadcrumbRightContent({
      backButton: true,
      display: [
        <FormattedMessage id="breadcrumb.posts" />,
        <FormattedMessage id="breadcrumb.edit" />,
      ],
    });

    return () => {
      setBreadcrumbRightContent({});
    };
  }, []);

  const { handleSubmit, register, control, watch, setValue, getValues } =
    useForm({
      // mode: "onChange"
    });

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;
  if (!publication) return <p>Not found</p>;

  function handleDeleteChip(key) {
    publication.post.categories.filter((item) => item.key !== key);
  }

  async function onSubmit(data) {
    setIsUpdating(true);
    await application.put(publicationUpdate(id), data);
    setIsUpdating(false);
    toast.success("Publicação editada com sucesso!");
    history.push("/publications");
  }

  return (
    <Grid container spacing={3}>
      <Toaster />
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <CustomCard>
          <form
            onSubmit={handleSubmit((data) =>
              onSubmit({
                id: id,
                ...data,
              })
            )}
            className="mb-0"
          >
            <ContainerTitle>
              <h6 className="card-title dashed-green mb-0">
                <FormattedMessage id="publication.form.label_edit" />
              </h6>
              <Controller
                as={
                  <SelectTypePublication>
                    <MenuItem value="NEWS">News</MenuItem>
                    <MenuItem value="MAGAZINE">Magazine</MenuItem>
                  </SelectTypePublication>
                }
                style={{ maxWidth: 125 }}
                name="type"
                control={control}
                defaultValue={publication.type}
              />
            </ContainerTitle>
            <Grid container spacing={3}>
              <input type="hidden" name="id" value={id} />
              <Grid item xs={12}>
                <Controller
                  as={InputField}
                  name="title"
                  label={<FormattedMessage id="publication.form.title" />}
                  control={control}
                  defaultValue={publication.title}
                />
              </Grid>
              {publication.type === "NEWS" ? (
                <Grid item xs={12}>
                  <Controller
                    className="w-100"
                    style={{
                      height: 58,
                      padding: "15px 0px 15px 10px",
                      border: "#ccc 1px solid",
                      borderRadius: 5,
                    }}
                    as={TextareaAutosize}
                    name="description"
                    variant="outlined"
                    fullWidth
                    control={control}
                    defaultValue={publication.description}
                  />
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Controller
                      as={InputField}
                      name="edition"
                      label={<FormattedMessage id="publication.form.edition" />}
                      control={control}
                      defaultValue={publication.edition}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      as={InputField}
                      name="subtitle"
                      label={
                        <FormattedMessage id="publication.form.subtitle" />
                      }
                      control={control}
                      defaultValue={publication.subtitle}
                    />
                  </Grid>
                </React.Fragment>
              )}
              <Grid
                item
                xs={12}
                className={`${publication.type === "MAGAZINE" ? "mb-5" : null}`}
              >
                <Controller
                  defaultValue={publication.audience}
                  as={
                    <CustomAudience
                      style={{ height: 58 }}
                      isMulti
                      options={user.groups}
                      getOptionLabel={(option) => option}
                      getOptionValue={(option) => option}
                      classNamePrefix="mySelect"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  }
                  name="audience"
                  control={control}
                />
              </Grid>
              {publication.type === "NEWS" && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Button
                      onClick={() => setOpen(true)}
                      fullWidth
                      variant="outlined"
                      className={classes.button}
                      startIcon={<MdLink className={classes.icon} size={20} />}
                    >
                      <FormattedMessage id="publication.form.add_link" />
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Collapse in={open}>
                      <Controller
                        as={InputField}
                        className=""
                        name="videoUrl"
                        control={control}
                        label={
                          <FormattedMessage id="publication.form.add_youtube_link" />
                        }
                        defaultValue={publication.videoUrl}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setOpen(publication.videoUrl)}
                              >
                                <MdExpandLess />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Collapse>
                  </Grid>
                </React.Fragment>
              )}
              <Grid item xs={6}>
                <Button
                  onClick={() => history.push("/publications")}
                  type="submit"
                  variant="outlined"
                  fullWidth
                  className={classes.button}
                  color="primary"
                >
                  <FormattedMessage id="common.cancel" />
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  className={classes.button}
                  color="secondary"
                >
                  {isUpdating ? (
                    <CircularProgress />
                  ) : (
                    <Typography component="p" className={classes.toSave}>
                      <FormattedMessage id="common.save" />
                    </Typography>
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CustomCard>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <ItemPreview item={publication} />
      </Grid>
    </Grid>
  );
  // })
}

const CustomAudience = styled(Select).attrs({})`
  && .mySelect__value-container {
    height: 58px;
  }
`;
export default EditPublications;
