import React, { useEffect, useState } from "react";
import { data } from "../../../helpers/axiosModules";
import { FormattedMessage } from "react-intl";
import { Table } from "../../../components/Table";
import { Modal } from "../../../components/Modal";
import moment from "moment";

const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 5,
  },
  oddRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 5,
    borderRadius: 6,
    backgroundColor: "#F4F6FB",
  },
  rowText: {
    flex: 1,
    color: "#3D5879",
    fontSize: 12,
  },
  headerTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    padding: 5,
  },
  headerTableLabel: {
    color: "#1D2E44",
    fontWeight: "500",
    textTransform: "uppercase",
  },
};

const boxStyles = {
  wrapperBox: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
  container: {
    display: "flex",
    flexGrow: 1,
    backgroundColor: "white",
    borderRadius: 6,
    width: "40%",
    padding: 15,
    justifyContent: "space-between",
    marginBottom: 25,
    marginRight: 10,
    boxShadow: "0px 0px 6px 0px rgba(101, 127, 160, 0.11999999731779099)",
  },
  label: {
    fontSize: 12,
    color: "#657FA0",
  },
  value: {
    color: "#3D5879",
    fontSize: 15,
    fontWeight: "500",
  },
};

const randomNumber = () => Math.random().toFixed(16).slice(3, 16);

const Box = ({ field, value }) => (
  <div style={boxStyles.container}>
    <span style={boxStyles.label}>
      <FormattedMessage id={`invoice.${field}`} />
    </span>
    <span style={boxStyles.value}>
      {!value || value === "-" ? (
        "-"
      ) : (
        <>
          <FormattedMessage id="invoice.currency" /> {value}
        </>
      )}
    </span>
  </div>
);

const Row = ({ index, field, value }) => (
  <div style={index % 2 ? styles.oddRow : styles.row}>
    <div style={styles.rowText}>
      <FormattedMessage id={`invoice.${field}`} />
    </div>
    <div style={styles.rowText}>
      {!value || value === "-" ? (
        "-"
      ) : (
        <>
          <FormattedMessage id="invoice.currency" /> {value}
        </>
      )}
    </div>
  </div>
);

const transformData = ({
  additional_market,
  animal_health_rate,
  base_icms,
  bonus_logistic,
  bonus_loyalty,
  bonus_protein,
  bonus_scale,
  complement_price,
  incentive_production,
  inss,
  milk_cru,
  rat,
  senar,
  total_credits,
  total_debts,
  // value,
  // value_deposit,
  value_icms,
  value_total_bill,
  value_total_product,
}) => ({
  description: {
    milk_cru,
    additional_market,
    bonus_loyalty,
    bonus_scale,
    bonus_logistic,
    complement_price,
    bonus_protein,
  },
  icms: {
    base_icms,
    value_icms,
    value_total_product,
    value_total_bill,
  },
  taxes: {
    inss,
    rat,
    senar,
    animal_health_rate,
    incentive_production,
  },
  totals: {
    total_credits,
    total_debts,
  },
});

export function InvoiceSection(props) {
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    data
      .get(`/invoice/${props.id}?cachetick=${Math.random()}`)
      .then(({ data }) => {
        if (!data) {
          return [];
        }

        setInvoices(
          data.map((el) => {
            return {
              ...el,
              date: moment(el.date).format("YYYY-MM-DD"),
            };
          })
        );
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div>
        <div className="px-5 p-4 border-bottom">Notas</div>
        <div className="d-flex p-5" style={{ display: "flex" }}>
          <Table
            columns={[
              {
                title: "#",
                disableClick: true,
                sorting: false,
                render: (rowData) => <span>{rowData.tableData.id + 1}º</span>,
              },
              {
                title: "Data",
                field: "date",
                disableClick: true,
                sorting: false,
              },
            ]}
            onRowClick={(row, column) => {
              const transformedData = transformData(column);
              console.debug(transformedData);
              setSelectedInvoice(transformedData);
              setOpen(true);
            }}
            isLoading={loading}
            data={invoices}
            offsetTop={415}
          />

          {/* {invoices.map((invoice, i) => <Invoice key={i} {...invoice}/>)} */}
        </div>
      </div>
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        loading={loading}
        size="medium"
      >
        <div
          style={{
            overflowX: "unset",
            overflowY: "auto",
            maxHeight: "calc(80vh - 60px)",
          }}
        >
          <h6>NF</h6>
          <div className="pt-4 pb-2">
            <div style={styles.headerTable}>
              <span style={styles.headerTableLabel}>
                Descrição dos produtos
              </span>
              <span style={styles.headerTableLabel}>VALOR TOTAL</span>
            </div>
            {selectedInvoice &&
              Object.entries(selectedInvoice.description).map(
                ([key, value], index) => (
                  <Row key={key} index={index} field={key} value={value} />
                )
              )}

            <div style={boxStyles.wrapperBox}>
              {selectedInvoice &&
                Object.entries(selectedInvoice.icms).map(([key, value]) => (
                  <Box
                    key={`${key}-${randomNumber()}`}
                    field={key}
                    value={value}
                  />
                ))}
            </div>

            {selectedInvoice &&
              Object.entries(selectedInvoice.taxes).map(
                ([key, value], index) => (
                  <Row key={key} index={index} field={key} value={value} />
                )
              )}
            <div style={boxStyles.wrapperBox}>
              {selectedInvoice &&
                Object.entries(selectedInvoice.totals).map(([key, value]) => (
                  <Box
                    key={`${key}-${randomNumber()}`}
                    field={key}
                    value={value}
                  />
                ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
