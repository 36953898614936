import React, {useState, useEffect, useContext} from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import {FormattedMessage, FormattedNumber} from "react-intl";
import VolumeMonthlyChart from "../../../components/charts/VolumeMonthlyChart";
import VolumeYearlyChart from "../../../components/charts/VolumeYearlyChart";
import {data} from "../../../helpers/axiosModules";
import {getMonths} from '../../../utils/Date';
import {ResultsMonthYear} from "../components/ResultsMonthYear";
import {BreadcrumbContext} from "../../../components/BreadCrumb";
import {PeriodSelector} from '../components/PeriodSelector';
import {ReactComponent as TruckIcon} from '../../../assets/svg-icons/Icon-truck-blue-24.svg';
import {ReactComponent as MilkIcon} from '../../../assets/svg-icons/Icon-milk-blue-24.svg';
import {generalLoading} from '../../../helpers/skeletonSchemas';

// const getAverage = (arr) => {
//   let sum = 0;
//   const noZeroData = arr.filter(a => a.y > 0);
//   noZeroData.map((item) => {
//     sum += item.y;
//   });
//   return sum / noZeroData.length;
// };
//
// const sumValues = arr => _.sumBy(arr, value => value.y);

const filter = (volumeAll, month, year) => {

  if (!volumeAll) {
    return [];
  }

  const ans = [];

  // let all be zero
  for (let i = 0; i < 31; i += 1) {
    ans.push({
      x: i,
      y: 0
    });
  }

  let hasItems = false;
  volumeAll.map((data) => {
    if (!data.date) {
      return;
    }

    const date = moment(data.date);

    if (date.month() !== month || date.year() !== year) {
      return;
    }

    const dayOfMonth = moment(data.date).date();
    // TODO: fix collect on same day might be upper each other
    if (data.volume) {
      hasItems = true;
      ans.push({
        x: dayOfMonth,
        y: data.volume,
        label: `${moment(data.date).format('DD/MM')} \n ${data.volume}`
      });
    }

    return ans;
  });

  return hasItems ? ans : [];
};

const filterYear = (volumeAll, year, color) => {

  if (!volumeAll) {
    return [];
  }

  // same year
  const dataAllSameYear = [];
  volumeAll.map((data) => {
    if (moment(data.date).year() !== year) {
      return dataAllSameYear;
    }
    return dataAllSameYear.push(data);
  });
  const ans = [];
  const dataGroupedByMonth = _.groupBy(dataAllSameYear, data => moment(data.date).month());
  getMonths().map((month) => {
    const monthData = dataGroupedByMonth[month.value];
    const lastMonth = ans.length > 0 ? ans[ans.length - 1].y : 0;

    if (monthData) {
      ans.push({
        x: month.value,
        y: _.sumBy(monthData, value => value.volume) + lastMonth,
        year,
        color
      });
    }
    return ans;
  });
  // Hack for null values
  if (ans.length <= 1) {
    ans.push({
      x: 0,
      y: 0
    });
    ans.push({
      x: 0,
      y: 0
    });
  }
  return ans;
};

const Total = ({icon, title, content}) => (
  <div className='shadow bg-white rounded p-5 my-4'>
    <div className='d-flex'>
      <div className='p-4 rounded' style={{background: 'rgba(31, 140, 199, .1)'}}>
        {icon}
      </div>
      <div className='ml-4'>
        <div>{title}:</div>
        <h5 className='m-0'>({content} <FormattedMessage id='common.liters'/>)</h5>
      </div>
    </div>
  </div>
);

const Totals = ({totalDelivered, dailyAverage}) => (
  <>
    <Total
      icon={<TruckIcon/>}
      title={<FormattedMessage id='producers.total_delivered'/>}
      content={<FormattedNumber value={totalDelivered} />}
    />
    <Total
      icon={<MilkIcon/>}
      title={<FormattedMessage id='producers.daily_average'/>}
      content={<FormattedNumber value={dailyAverage} />}
    />
  </>
);

const CURRENT_YEAR = moment().year();
const CURRENT_MONTH = moment().month();
const lateYearColor = '#3BD6AA';
const lastYearColor = '#4976EF';
const currentYearColor = '#F26894';

export function VolumeSection(props) {
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);
  const [volumeData, setVolumeData] = useState([]);
  const [resultsView, setResultsView] = useState('month');
  const [dataLateYear, setDataLateYear] = useState([]);
  const [dataLastYear, setDataLastYear] = useState([]);
  const [dataYear, setDataYear] = useState([]);
  const [dataMonth, setDataMonth] = useState([]);
  const [years, setYears] = useState([]);
  const months = moment.months().map((name, i) => ({name, value: i}));
  const [currentMonth, setCurrentMonth] = useState(CURRENT_MONTH);
  const [currentYear, setCurrentYear] = useState(CURRENT_YEAR);
  const [loading, setLoading] = useState(true);

  function loadVolume() {
    data.get(`/collections/${props.id}?cachetick=${Math.random()}`)
      .then(({data}) => {
        setVolumeData([...volumeData, ...data]);
        setLoading(false);
      })
      .catch(err => console.error(err));
  }

  useEffect(
    () => {
      loadVolume();
    },
    []
  );

  useEffect(() => {
    setYears(_.map(_.keys(_.groupBy(volumeData, item => moment(item.date).year())), value => ({value: Number(value)})));

    switch (resultsView) {
      case 'month':
        setDataMonth(filter(volumeData, currentMonth, currentYear));
        break;
      case 'year':
        setDataLateYear(filterYear(volumeData, currentYear - 2, lateYearColor));
        setDataLastYear(filterYear(volumeData, currentYear - 1, lastYearColor));
        setDataYear(filterYear(volumeData, currentYear, currentYearColor));
        break;
      default:
        break;
    }
  }, [volumeData, resultsView, currentMonth, currentYear]);

  useEffect(() => {
    setBreadcrumbRightContent({
      rightContent: <ResultsMonthYear onChange={setResultsView} view={resultsView}/>
    });

    return () => {
      setBreadcrumbRightContent({
        rightContent: null
      });
    }
  }, [resultsView, setBreadcrumbRightContent]);

  if (loading) {
    return generalLoading(1);
  }

  const totalDelivered =  dataMonth.reduce((total, item) => total + item.y, 0);
  const producedDays = (dataMonth.filter(item => item.y > 0)).length;
  const dailyAverage = producedDays && totalDelivered / producedDays;
  const partialPeriod = CURRENT_YEAR === currentYear && CURRENT_MONTH <= currentMonth;

  return (
    <div>
      <div className='p-4 border-bottom d-flex justify-content-between'>
        {
          resultsView === 'month' && (
            <div
              style={{flex: 1}}
              className="d-flex align-items-center justify-content-between justify-content-around"
            >
            {/* <div className="d-flex align-items-center">
              <span
                className="d-inline-block m-2"
                style={{
                  width: 11,
                  height: 11,
                  borderRadius: '50%',
                  backgroundColor: '#4976EF'
                }}
              />
                  <span>Leite conforme</span>
                </div>
                <div className="d-flex align-items-center">
              <span
                className="d-inline-block m-2"
                style={{
                  width: 11,
                  height: 11,
                  borderRadius: '50%',
                  backgroundColor: '#C93838'
                }}
              />
              <span>Leite não conforme</span>
            </div> */}
              <div className="d-flex align-items-center">
            <span
              className="d-inline-block m-2"
              style={{
                height: 1,
                width: 20,
                borderWidth: 2,
                borderColor: '#15CE9A',
                borderStyle: 'solid'
              }}
            />
                <span>Média volume entregue</span>
              </div>
            </div>
          )
        }

        {
          resultsView === 'year' && (
            <div className="d-flex flex-row-reverse justify-content-between align-items-center">
              <div className="d-flex flex-row align-items-center mx-3">
                <span
                  className="d-inline-block m-2"
                  style={{
                    width: 11,
                    height: 11,
                    borderRadius: '50%',
                    backgroundColor: dataLateYear.length ? '#3BD6AA' : 'grey'
                  }}
                />
                {currentYear - 2}
              </div>
              <div className="d-flex flex-row align-items-center mx-3">
                <span
                  className="d-inline-block m-2"
                  style={{
                    width: 11,
                    height: 11,
                    borderRadius: '50%',
                    backgroundColor: dataLastYear.length ? '#4976EF' : 'grey'
                  }}
                />
                {currentYear - 1}
              </div>
              <div className="d-flex flex-row align-items-center mx-3">
                <span
                  className="d-inline-block m-2"
                  style={{
                    width: 11,
                    height: 11,
                    borderRadius: '50%',
                    backgroundColor: '#F26894'
                  }}
                />
                {currentYear}
              </div>
            </div>
          )
        }
        <div className="justify-content-end">
          <PeriodSelector
            years={years}
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
            hideMonth={resultsView === 'year'}
            months={months}
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
          />
        </div>
      </div>
      <div className='row m-0'>
        {resultsView === 'month' && (
          <>
            <div className='col-8 border-right p-0'>
              <div className='p-4'>
                <VolumeMonthlyChart
                  data={dataMonth}
                  average={dailyAverage}
                />
              </div>
            </div>
            <div className='col-4 p-5'>
              <div>
                <b><FormattedMessage id={`producers.${partialPeriod ? 'partials' : 'accomplished'}`}/></b>
                <Totals totalDelivered={totalDelivered} dailyAverage={dailyAverage}/>
              </div>
            </div>
          </>
        )}
        {resultsView === 'year' && (
          <>
            <div className='col-8 border-right p-0'>
              <div className='p-4'>
                <VolumeYearlyChart
                  dataLateYear={dataLateYear}
                  dataLastYear={dataLastYear}
                  dataYear={dataYear}
                  selectedYear={currentYear}
                />
                <div className='border-top w-100 m-0 p-4'>
                </div>
              </div>
            </div>
            <div className='col-4 p-5'>
              <div>
                <b><FormattedMessage id='producers.consolidated'/></b>
                <Totals totalDelivered={0} dailyAverage={0}/>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
