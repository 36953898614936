import React, { useState, useEffect, useContext, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { application } from "../../helpers/axiosModules";
import { User } from "../../components/User";
import { SidebarContext } from "../../components/layouts/Main/Main";
import { camelCaseString } from "../../utils/String.utils";
import { Table, Status } from "../../components/Table";
import { ReactComponent as IconStar } from "../../assets/svg-icons/Icon-start.svg";
import { ReactComponent as IconEdit } from "../../assets/svg-icons/Icon-edit.svg";
import { ReactComponent as IconMore } from "../../assets/svg-icons/Icon-more.svg";
import maskPattern from "../../utils/Masks";
import StringMask from "string-mask";
import { BreadcrumbContext } from "../../components/BreadCrumb";
import CustomFilter, {
  resetTableSearchAndFilter,
} from "../../components/CustomFilter";
import { ActionMenu } from "../../components/ActionMenu";
import { IconWithText } from "../../components/IconWithText";
import Toaster from "../../components/Toaster";
import { toast } from "react-toastify";
import { usersCount, auth, usersAll } from "../../api/routes-app";

export function ProducersList() {
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);
  const [moreAccessed, setMoreAccessed] = useState([]);
  const [totalProducers, setTotalProducers] = useState();
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState();
  const setSidebarChildren = useContext(SidebarContext);
  let termTimeout = null;
  const tableRef = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    async function fetchData() {
      const totalProducersRes = await application.get(
        `${usersCount()}?cachetick=${Math.random()}`
      );
      setTotalProducers(totalProducersRes.data || "0");
      setLoading(false);
    }

    fetchData();
  }, []);

  // function handleSearch(value) {
  //   clearTimeout(termTimeout);
  //   termTimeout = setTimeout(async () => {
  //     setSearchTerm(value);
  //     tableRef.current.onQueryChange();
  //   }, 500);
  // }

  useEffect(() => {
    setBreadcrumbRightContent({
      // onSearch: handleSearch,
      resetTableSearchAndFilter: () => resetTableSearchAndFilter(tableRef),
    });
    setSidebarChildren(
      <div className="px-4 py-1">
        <div className="d-flex pb-4 border-bottom justify-content-center align-items-center arrow-down-detail">
          <IconStar fill="#41b049" stroke="#41b049" />
          <b className="mx-3">
            <FormattedMessage id="breadcrumb.producers" />
          </b>
        </div>
        <div className="px-3 mt-4">
          {moreAccessed.map((producer) => (
            <Link to={`/producers/${producer.id}`} key={producer.id}>
              <div className="py-4">
                <User
                  user={{
                    ...producer,
                  }}
                />
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }, [moreAccessed]);

  const sendAccessPassword = async (id) => {
    const { status } = await application.post(
      `${auth()}?createvoucher=true&identification=${id}`
    );
    if (status === 200) {
      toast.success(
        <FormattedMessage id="recover_password.voucher_sent_successfully" />
      );
    } else {
      toast.error(
        <FormattedMessage id="recover_password.voucher_sent_error" />
      );
    }
  };

  const sendAdminAccessPassword = async (id) => {
    const { status } = await application.post(
      `${auth()}?createvoucher=true&identification=${id}&admin=true`
    );
    if (status === 200) {
      toast.success(
        <FormattedMessage id="recover_password.voucher_sent_successfully" />
      );
    } else {
      toast.success(
        <FormattedMessage id="recover_password.voucher_sent_error" />
      );
    }
  };

  const doSearch = async (params, currentPage) => {
    setLoading(true);
    const ans = await application.get(
      `${usersAll()}?cachetick=${Math.random()}`
    );
    setLoading(false);

    if (!ans || !ans.data) {
      return [];
    }

    return {
      data: ans.data.map((user) => {
        const maskedPhone = user.phone
          ? user.phone.length > 10
            ? new StringMask(maskPattern.mobilePhone).apply(user.phone)
            : new StringMask(maskPattern.phone).apply(user.phone)
          : "";

        return {
          id: user.identification,
          image_url: user.profile ? user.profile.photoLink : null,
          name: camelCaseString(user.name),
          identification: user.identification,
          email: user.email,
          phone: maskedPhone,
          status: 1,
        };
      }),
      page: currentPage,
      totalCount: totalProducers ? totalProducers : "...",
    };
  };

  return (
    <>
      <Toaster />
      <Table
        tableRef={tableRef}
        columns={[
          {
            title: <FormattedMessage id="common.name" />,
            field: "name",
            render: (props) => <User user={props} />,
            disableClick: true,
            sorting: false,
          },
          {
            title: <FormattedMessage id="common.identification" />,
            field: "identification",
            disableClick: true,
            sorting: false,
          },
          {
            title: <FormattedMessage id="common.email" />,
            field: "email",
            disableClick: true,
            sorting: false,
          },
          {
            title: <FormattedMessage id="common.phone" />,
            field: "phone",
            disableClick: true,
            sorting: false,
          },
          {
            title: <FormattedMessage id="common.status" />,
            field: "status",
            disableClick: true,
            sorting: false,
            lookup: {
              1: <Status value="active" />,
              0: <Status value="inactive" />,
            },
          },
          {
            title: "Action",
            field: "id",
            sorting: false,
            render: (rowData) => (
              <div className="d-flex justify-content-end">
                <ActionMenu
                  trigger={
                    <button className="btn px-3 py-2">
                      <IconMore />
                    </button>
                  }
                  content={
                    <div
                      className="px-3 py-2 child-svg-color-1"
                      style={{ width: 290 }}
                    >
                      <span
                        className="btn w-100"
                        onClick={() => sendAccessPassword(rowData.id)}
                      >
                        <IconWithText
                          icon={<IconEdit />}
                          text={
                            <FormattedMessage id="recover_password.send_voucher_producer" />
                          }
                          reset={true}
                        />
                      </span>
                      <span
                        className="btn w-100"
                        onClick={() => sendAdminAccessPassword(rowData.id)}
                      >
                        <IconWithText
                          icon={<IconEdit />}
                          text={
                            <FormattedMessage id="recover_password.send_voucher_coordinators" />
                          }
                          reset={true}
                        />
                      </span>
                    </div>
                  }
                />
              </div>
            ),
          },
        ]}
        paging={true}
        search={false} // not working
        filtering={false}
        isLoading={loading}
        data={(query) => {
          return new Promise((resolve) => {
            let params = `limit=${query.pageSize}`;
            if (searchTerm && searchTerm !== "") {
              params += `&name=${searchTerm}`;
            } else {
              if (query.filters.length) {
                query.filters.map((item) => {
                  console.debug(item);
                  params += `&${item.column.field}=${item.value}`;
                });
              }

              params += `&skip=${query.page * query.pageSize}`;
            }

            return resolve(doSearch(params, query ? query.page : 0));
          });
        }}
      />
    </>
  );
}
