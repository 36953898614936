import React from 'react';
import {VictoryAxis, VictoryBar, VictoryChart, VictoryTooltip} from 'victory';
import ChartTooltipFlyout from './ChartTooltipFlyout';
import {ChartTooltip} from './ChartTooltip';
import ChartNoData from "./ChartNoData";

const formatDate = (date) => {
  const split = date.split('/');
  return `${split[0]}/${split[1]}`;
};

const getCSSColor = (y, type) => {
  switch (type) {
    case 'SCC':
      if (y <= 250000) return 'url(#blueGrad)'; // TODO: Check Bonification values and colors
      if (y <= 400000) return 'url(#greenGrad)';
      if (y <= 600000) return 'url(#orangeGrad)';
      return 'url(#redGrad)';
    case 'TBC':
      if (y <= 20000) return 'url(#blueGrad)'; // TODO: Check Bonification values and colors
      if (y <= 100000) return 'url(#greenGrad)';
      if (y <= 600000) return 'url(#orangeGrad)';
      return 'url(#redGrad)';
    case 'FAT':
      if (y <= 3.0) return 'url(#redGrad)';
      if (y <= 3.2) return 'url(#orangeGrad)';
      if (y <= 4.4) return 'url(#greenGrad)';
      return 'url(#blueGrad)';
    case 'PROTEIN':
      if (y >= 3.4) return 'url(#blueGrad)'; // TODO: Check Bonification values and colors
      if (y >= 3.15) return 'url(#greenGrad)';
      if (y >= 2.9) return 'url(#orangeGrad)';
      return 'url(#redGrad)';
    default:
      return undefined;
  }
};

const CHART_WIDTH = 600;
const CHART_HEIGHT = 400;
const BAR_WIDTH = 60;

const redBarColor = 'rgb(233,63,102)';
const yellowBarColor = 'rgb(255,200,102)';
const greenBarColor = 'rgb(21,206,154)';
const blueBarColor = 'rgb(73,118,239)';

const getBarColor = (y, type) => {
  switch (type) {
    case 'SCC':
      if (y <= 250000) return 'url(#blueGrad)';
      if (y <= 400000) return 'url(#greenGrad)';
      if (y <= 600000) return 'url(#yellowGrad)';
      return 'url(#redGrad)';
    case 'TBC':
      if (y <= 20000) return 'url(#blueGrad)';
      if (y <= 300000) return 'url(#greenGrad)';
      if (y <= 600000) return 'url(#yellowGrad)';
      return 'url(#redGrad)';
    case 'FAT':
      if (y <= 3.1) return 'url(#redGrad)';
      if (y <= 3.2) return 'url(#yellowGrad)';
      if (y <= 4.5) return 'url(#greenGrad)';
      return 'url(#blueGrad)';
    case 'PROTEIN':
      if (y <= 3) return 'url(#redGrad)';
      if (y <= 3.1) return 'url(#yellowGrad)';
      if (y <= 3.8) return 'url(#greenGrad)';
      return 'url(#blueGrad)';
    default:
      return 'black';
  }
};

const getFakePosY = (value, y, type) => {
  if (type === 'SCC') {
    if (value <= 250000) return y;
    if (value <= 400000) return y - 35;
    if (value <= 600000) return y - 35;
    if (value > 600000 && value <= 700000) return y - 28;
    if (value > 700000 && value <= 800000) return y - 25;
    if (value > 800000 && value <= 900000) return y - 22;
    if (value > 900000 && value < 1000000) return y + 30;
    if (value >= 1000000 && value < 10000000) return 70;
    return 50;
  }
  if (type === 'TBC') {
    if (value <= 20000) return y - 40;
    if (value <= 300000) return y - 95;
    if (value <= 600000) return y + 5;
    if (value < 800000) return y + 15;
    if (value >= 800000 && value < 900000) return y + 75;
    if (value >= 900000 && value < 1000000) return y + 95;
    if (value >= 1000000 && value < 10000000) return 70;
    return 50;
  }
  if (type === 'FAT') {
    if (value <= 3.1) return y - 80;
    if (value > 3.1 && value < 3.2) return y - 110;
    if (value > 3.2 && value < 3.5) return y - 200;
    if (value >= 3.5 && value < 3.8) return y - 180;
    if (value <= 4.5) return y - 200;
    if (value > 4.5 && value <= 5) return y + 25;
    return 50;
  }
  if (type === 'PROTEIN') {
    if (value <= 3) return y + 150;
    if (value <= 3.1) return y - 150;
    if (value <= 3.8) return y - 190;
    if (value < 5) return y - 200;
    return 50;
  }
  return y;
};

const getFakeHeight = (value, y, y0, type) => {
  if (type === 'SCC') {
    if (value <= 250000) return y0 - y;
    if (value <= 400000) return y0 - y + 35;
    if (value <= 600000) return y0 - y + 35;
    if (value > 600000 && value <= 700000) return y0 - y + 28;
    if (value > 700000 && value <= 800000) return y0 - y + 25;
    if (value > 800000 && value <= 900000) return y0 - y + 22;
    if (value > 900000 && value < 1000000) return y0 - y - 30;
    if (value >= 1000000 && value < 10000000) return CHART_HEIGHT - 120;
    return CHART_HEIGHT - 128;
  }
  if (type === 'TBC') {
    if (value <= 20000) return y0 - y + 40;
    if (value <= 300000) return y0 - y + 95;
    if (value <= 600000) return y0 - y - 5;
    if (value < 800000) return y0 - y - 15;
    if (value >= 800000 && value < 900000) return y0 - y - 75;
    if (value > 900000 && value < 1000000) return y0 - y - 95;
    if (value >= 1000000 && value < 10000000) return CHART_HEIGHT - 120;
    return CHART_HEIGHT - 128;
  }
  if (type === 'FAT') {
    if (value <= 3.1) return y0 - y + 80;
    if (value > 3.1 && value < 3.2) return y0 - y + 110;
    if (value > 3.2 && value < 3.5) return y0 - y + 200;
    if (value >= 3.5 && value < 3.8) return y0 - y + 180;
    if (value <= 4.5) return y0 - y + 200;
    if (value > 4.5 && value <= 5) return y0 - y - 25;
    return CHART_HEIGHT - 100;
  }
  if (type === 'PROTEIN') {
    if (value <= 3) return y0 - y - 150;
    if (value <= 3.1) return y0 - y + 150;
    if (value <= 3.8) return y0 - y + 190;
    if (value < 5) return y0 - y + 200;
    return CHART_HEIGHT - 100;
  }
  return y0 - y;
};


const CustomBar = ({  x, y, x0, y0, datum: data, type, events }) => {
  const fill = getBarColor(data._y, type);
  const fakeY = getFakePosY(data._y, y, type);
  const fakeYHeight = getFakeHeight(data._y, y, y0, type);
  return (
    <g>
      <rect
        x={x - x0 / 2}
        y={fakeY}
        rx={6}
        ry={6}
        width={x0}
        height={fakeYHeight}
        fill={fill}
        {...events}
      />
      <rect
        x={x - 15}
        y={fakeY - 3}
        rx={3}
        ry={3}
        fill='#fff'
        height={6}
        width={30}
      />
    </g>
  )
};

const TierBars = ({data, type, toggleModal}) => {
  if ( type === 'SCC' || type === 'TBC' ) {
    return (
      <g>
        <rect
          width={550}
          height={74}
          x={50}
          y={50}
          rx={10}
          ry={10}
          fill={data.length > 0 ? redBarColor : '#F4F6FB'}
          opacity={data.length > 0 ? 0.2 : 1}
          onClick={() => toggleModal(redBarColor)}
        />
        <rect
          width={550}
          height={74}
          x={50}
          y={125}
          rx={10}
          ry={10}
          fill={data.length > 0 ? yellowBarColor : '#F4F6FB'}
          opacity={data.length > 0 ? 0.2 : 1}
          onClick={() => toggleModal(yellowBarColor)}
        />
        <rect
          width={550}
          height={74}
          x={50}
          y={200}
          rx={10}
          ry={10}
          fill={data.length > 0 ? greenBarColor : '#F4F6FB'}
          opacity={data.length > 0 ? 0.2 : 1}
          onClick={() => toggleModal(greenBarColor)}
        />
        <rect
          width={550}
          height={75}
          x={50}
          y={275}
          rx={10}
          ry={10}
          fill={data.length > 0 ? blueBarColor : '#F4F6FB'}
          opacity={data.length > 0 ? 0.2 : 1}
          onClick={() => toggleModal(blueBarColor)}
        />
      </g>
    )
  }
  return (
    <g>
      <rect
        width={550}
        height={74}
        x={50}
        y={50}
        rx={10}
        ry={10}
        fill={data.length > 0 ? blueBarColor : '#F4F6FB'}
        opacity={data.length > 0 ? 0.2 : 1}
        onClick={() => toggleModal(blueBarColor)}
      />
      <rect
        width={550}
        height={74}
        x={50}
        y={125}
        rx={10}
        ry={10}
        fill={data.length > 0 ? greenBarColor : '#F4F6FB'}
        opacity={data.length > 0 ? 0.2 : 1}
        onClick={() => toggleModal(greenBarColor)}
      />
      <rect
        width={550}
        height={74}
        x={50}
        y={200}
        rx={10}
        ry={10}
        fill={data.length > 0 ? yellowBarColor : '#F4F6FB'}
        opacity={data.length > 0 ? 0.2 : 1}
        onClick={() => toggleModal(yellowBarColor)}
      />
      <rect
        width={550}
        height={75}
        x={50}
        y={275}
        rx={10}
        ry={10}
        fill={data.length > 0 ? redBarColor : '#F4F6FB'}
        opacity={data.length > 0 ? 0.2 : 1}
        onClick={() => toggleModal(redBarColor)}
      />
    </g>
  );
};

const QualityMonthlyChart = ({data, type, toggleModal}) => (
  <div
    style={{
      flex: 1,
    }}
  >
    <div
      style={{
        flex: 1,
        paddingBottom: 20,
        position: 'relative'
      }}
    >
      {
        !data.length && <ChartNoData type={'qualidade'}/>
      }
      <VictoryChart
        domainPadding={{x: 60, y: 0}}
        width={CHART_WIDTH}
        height={CHART_HEIGHT}
        maxDomain={{y: 1000000}}
      >
        <TierBars data={data} type={type} toggleModal={toggleModal} />
        <defs>
          <linearGradient id="redGrad" x1="0" y1="0" x2="0" y2="170">
            <stop offset="0" stopColor="rgba(255,175,191)" stopOpacity="1" />
            <stop offset="1" stopColor="rgb(241,119,144)" stopOpacity="1" />
          </linearGradient>
          <linearGradient id="yellowGrad" x1="0" y1="0" x2="0" y2="170">
            <stop offset="0" stopColor="rgb(255,215,178)" stopOpacity="1" />
            <stop offset="1" stopColor="rgb(255,163,114)" stopOpacity="1" />
          </linearGradient>
          <linearGradient id="greenGrad" x1="0" y1="0" x2="0" y2="170">
            <stop offset="0" stopColor="rgb(154,238,215)" stopOpacity="1" />
            <stop offset="1" stopColor="rgb(86,215,180)" stopOpacity="1" />
          </linearGradient>
          <linearGradient id="blueGrad" x1="0" y1="0" x2="0" y2="170">
            <stop offset="0" stopColor="#5986ff" stopOpacity="1" />
            <stop offset="1" stopColor="#83a0ee" stopOpacity="1" />
          </linearGradient>
        </defs>

        {
          type === 'SCC' && (
            <VictoryAxis
              dependentAxis
              standalone
              offsetY={30}
              height={CHART_HEIGHT}
              tickValues={[0, 250000, 500000, 750000, 1000000]}
              tickFormat={(t) => {
                if (t === 250000) return '250k';
                if (t === 500000) return '400k';
                if (t === 750000) return '600k';
                if (t === 1000000) return '100Mi';
                return '';
              }}
              style={{
                axis: {
                  stroke: 'transparent',
                },
                tickLabels: {
                  fill: '#657FA0',
                }
              }}
            />
          )
        }

        {
          type === 'TBC' && (
            <VictoryAxis
              dependentAxis
              standalone
              offsetY={30}
              height={CHART_HEIGHT}
              tickValues={[0, 250000, 500000, 750000, 1000000]}
              tickFormat={(t) => {
                if (t === 250000) return '20k';
                if (t === 500000) return '300k';
                if (t === 750000) return '600k';
                if (t === 1000000) return '100Mi';
                return '';
              }}
              style={{
                axis: {
                  stroke: 'transparent',
                },
                tickLabels: {
                  fill: '#657FA0',
                }
              }}
            />
          )
        }

        {
          type === 'FAT' && (
            <VictoryAxis
              dependentAxis
              standalone
              offsetY={30}
              height={CHART_HEIGHT}
              tickValues={[0, 250000, 500000, 750000, 1000000]}
              tickFormat={(t) => {
                if (t === 250000) return '3.1';
                if (t === 500000) return '3.2';
                if (t === 750000) return '4.5';
                if (t === 1000000) return '5';
                return '';
              }}
              style={{
                axis: {
                  stroke: 'transparent',
                },
                tickLabels: {
                  fill: '#657FA0',
                }
              }}
            />
          )
        }

        {
          type === 'PROTEIN' && (
            <VictoryAxis
              dependentAxis
              standalone
              offsetY={30}
              height={CHART_HEIGHT}
              tickValues={[0, 250000, 500000, 750000, 1000000]}
              tickFormat={(t) => {
                if (t === 250000) return '3.0';
                if (t === 500000) return '3.1';
                if (t === 750000) return '3.8';
                if (t === 1000000) return '5';
                return '';
              }}
              style={{
                axis: {
                  stroke: 'transparent',
                },
                tickLabels: {
                  fill: '#657FA0',
                }
              }}
            />
          )
        }

        {
          data.length > 0 && (
            <VictoryAxis
              style={{
                axis: {
                  stroke: 'transparent'
                },
                tickLabels: {
                  fill: '#657fa0'
                }
              }}
              tickFormat={(t) => formatDate(t)}
            />
          )
        }

        <VictoryBar
          style={{
            data: {
              fill: d => getCSSColor(d.y, type),
              fillOpacity: 1,
              strokeWidth: 1
            },
            labels: {
              fontSize: 0,
              fill: 'transparent'
            }
          }}
          labels={() => ''}
          labelComponent={
            <VictoryTooltip
              flyoutComponent={(
                <ChartTooltipFlyout
                  chartHeight={CHART_HEIGHT}
                  getFakePosY={getFakePosY}
                  type={type}
                  arrowOrientation="horizontal"
                />
              )}
              labelComponent={<ChartTooltip chartHeight={CHART_HEIGHT} getFakePosY={getFakePosY} type={type}/>}
            />
          }
          barWidth={BAR_WIDTH}
          data={data}
          dataComponent={<CustomBar type={type} />}
          cornerRadius={{topLeft: 4, topRight: 4}}
        />

      </VictoryChart>
    </div>
  </div>
);

export default QualityMonthlyChart;
