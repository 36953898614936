import React, { useState, useEffect } from 'react';
import moment from "moment";
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as CalendarIcon } from '../assets/svg-icons/Icon-calendar-white.svg';
import { ReactComponent as CloseIcon } from '../assets/svg-icons/Icon-close.svg';

export function DateRange({onChange = () => null}) {
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);

  useEffect(() => {
    onChange({initialDate, finalDate, setInitialDate, setFinalDate})
  });

  useEffect(() => {
    onChange({initialDate, finalDate, setInitialDate, setFinalDate})
  }, [initialDate, finalDate]);

  return (
    <div className='custom-filter d-flex flex-grow-1 align-items-center'>
      <span className="arrow-right-detail" style={{ top: 8 }} />
      <div className='d-flex align-items-center m-auto'>
        <div className='mx-3'><FormattedMessage id='common.initial_date'/></div>
        <DatePicker
          calendarIcon={<CalendarIcon/>}
          locale='pt-BR'
          clearIcon={initialDate && <CloseIcon fill='#fff' />}
          value={initialDate}
          onChange={date => setInitialDate(date)}
          maxDate={finalDate}
          className='date-picker-custom'
          formatMonthYear={(locale, date) => moment(date).format('MMM YYYY')}
        />
        <div className='mx-3'><FormattedMessage id='common.final_date'/></div>
        <DatePicker
          calendarIcon={<CalendarIcon/>}
          locale='pt-BR'
          clearIcon={finalDate && <CloseIcon fill='#fff' />}
          value={finalDate}
          minDate={initialDate}
          onChange={date => setFinalDate(date)}
          className='date-picker-custom'
          formatMonthYear={(locale, date) => moment(date).format('MMM YYYY')}
        />
      </div>
    </div>
  )
}

DateRange.propTypes = {
  onChange: PropTypes.func
};
