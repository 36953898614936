import React from "react";
import { FormattedMessage } from "react-intl";
import noPermissionImg from "../../assets/images/noPermission@3x.png";
import { ReactComponent as IconWarning } from "../../assets/svg-icons/Icon-exclamation.svg";

export function NoPermission() {
  return (
    <div className="no-permission d-flex flex-column justify-content-center align-content-center">
      <div className="d-flex justify-content-center">
        <IconWarning fill="#ffc866" />
      </div>
      <span
        style={{
          color: "#025481",
          fontWeight: "bold",
          fontSize: 28,
          marginBottom: 10,
          textAlign: "center",
        }}
      >
        <FormattedMessage id="no_permission.title" />
      </span>
      <span
        style={{
          color: "#657fa0",
          fontSize: 14,
          marginBottom: 20,
          textAlign: "center",
        }}
      >
        <FormattedMessage id="no_permission.message" />
      </span>
      <FormattedMessage id="no_permission.alt_image">
        {(text) => {
          return (
            <img
              src={noPermissionImg}
              alt={text}
              style={{
                width: "80%",
                height: "auto",
                margin: "auto",
              }}
            />
          );
        }}
      </FormattedMessage>
    </div>
  );
}
