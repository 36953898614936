import React, { useContext, useEffect, useState } from "react";
import { SidebarContext } from "../../components/layouts/Main/Main";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";
import { BreadCrumb, BreadcrumbContext } from "../../components/BreadCrumb";
import { IconWithText } from "../../components/IconWithText";
import { FormattedMessage } from "react-intl";
import { ReactComponent as IconGraph } from "../../assets/svg-icons/Icon-graph.svg";
import { ReactComponent as IconCow } from "../../assets/svg-icons/Icon-cow.svg";
import { Collection } from "./Collection";
import { UsersPage } from "./UsersPage";
import { PublicationsPage } from "./PublicationsPage";
import { MagazinesPage } from "./MagazinesPage";
import { HerdPage } from "./HerdPage";

export function PanelWrapper({ history }) {
  const setSidebarChildren = useContext(SidebarContext);
  const [breadcrumb, setBreadcrumb] = useState({
    filter: null,
    filterIcon: null,
  });

  useEffect(() => {
    setSidebarChildren(
      <div>
        <NavLink to="/panel/collection">
          <IconWithText
            icon={<IconGraph />}
            text={<FormattedMessage id="header.collection" />}
          />
        </NavLink>
        <NavLink to="/panel/herd">
          <IconWithText
            icon={<IconCow />}
            text={<FormattedMessage id="header.herd" />}
          />
        </NavLink>
      </div>
    );
  }, []);

  return (
    <BreadcrumbContext.Provider value={setBreadcrumb}>
      <BreadCrumb
        history={history}
        limitPaths={2}
        filter={breadcrumb.filter}
        filterIcon={breadcrumb.filterIcon}
      />
      <div>
        <Switch>
          <Route exact path="/panel">
            <Redirect to="/panel/users" />
          </Route>
          <Route path="/panel/collection">
            <Collection />
          </Route>
          <Route path="/panel/users">
            <UsersPage />
          </Route>
          <Route path="/panel/publications">
            <PublicationsPage />
          </Route>
          <Route path="/panel/magazines">
            <MagazinesPage />
          </Route>
          <Route path="/panel/herd">
            <HerdPage />
          </Route>
        </Switch>
      </div>
    </BreadcrumbContext.Provider>
  );
}
