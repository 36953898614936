import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { uniqueId } from "lodash";
import fileSize from "filesize";
import {
  MdCheckCircle,
  MdError,
  MdLink,
} from "react-icons/md";
import { CircularProgressbar } from "react-circular-progressbar";

import {
  DropContainer,
  UploadMessage,
  Container,
  FileInfo,
  Preview,
} from "./styles";
import { IconButton } from "@material-ui/core";

const percentage = 66;

const UploadFiles = ({
  name,
  onAcceptedFiles,
  description,
  children,
  accept,
}) => {
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);

  const handlerOpenModal = () => {
    setOpen(true);
  };

  const handlerCloseModal = () => {
    setOpen(false);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
    acceptedFiles,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => {
          onAcceptedFiles(file);
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: uniqueId(),
            readableSize: fileSize(file.size),
            progress: 0,
            uploaded: false,
            error: false,
            url: null,
          });
        })
      );
      handlerOpenModal();
    },
    multiple: true,
    accept: accept,
  });

  const renderDragMessage = (isDragActive, isDragReject, isDragAccept) => {
    if (!isDragActive) {
      return (
        <UploadMessage>
          <IconButton>{children}</IconButton>
          {description}
        </UploadMessage>
      );
    }

    if (isDragReject) {
      return <UploadMessage type="error">Aquivo não suportado!</UploadMessage>;
    }

    return (
      <UploadMessage type="success">Solte os arquivos aqui ...</UploadMessage>
    );
  };

  return (
    <>
      <DropContainer
        {...getRootProps()}
        isDragActive={isDragActive}
        isDragReject={isDragReject}
        isDragAccept={isDragAccept}
      >
        {renderDragMessage(isDragActive, isDragReject, isDragAccept)}
        <input name={name} {...getInputProps()} />
      </DropContainer>
      <Container>
        {!!files.length &&
          files.map((uploadedFile, index) => (
            <li key={index}>
              <FileInfo>
                <Preview src={uploadedFile.preview} />
                <div>
                  <strong>{uploadedFile.name}</strong>
                  <span>
                    {uploadedFile.readableSize}
                    <button onClick={() => {}}>Remove</button>
                  </span>
                </div>
              </FileInfo>
              <div>
                {uploadedFile.url && (
                  <a
                    href="https://images.pexels.com/photos/735968/pexels-photo-735968.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MdLink style={{ marginRight: 8 }} size={24} color="#222" />
                  </a>
                )}
                {uploadedFile.uploaded && (
                  <MdCheckCircle size={24} color="#37933C" />
                )}
                {uploadedFile.error && <MdError size={24} color="#e57878" />}
              </div>
            </li>
          ))}
      </Container>
    </>
  );
};

export { UploadFiles };
