import React from "react";
import PropTypes from "prop-types";

export function IconWithText({ icon, text, reset = false, className = "" }) {
  return (
    <div
      className={`d-flex align-content-center ${className}`}
      style={{ fill: "currentColor" }}
    >
      <div className={reset ? "p-2" : "p-3 pl-5"}>{icon}</div>
      <div className={reset ? "p-2" : "p-3 pr-5 mt-1"}>{text}</div>
    </div>
  );
}

IconWithText.propTypes = {
  icon: PropTypes.any.isRequired,
  text: PropTypes.any,
  reset: PropTypes.bool,
  className: PropTypes.string,
};
