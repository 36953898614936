import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import { SelectField } from '../../../components/form/SelectField';

export function PeriodSelector({
  years,
  currentYear,
  setCurrentYear,
  hideMonth = false,
  months,
  currentMonth,
  setCurrentMonth,
  onSelectMonth,
  onSelectYear
}) {
  return (
    <div className='d-flex align-items-center pr-2'>
      <b className='mr-5'><FormattedMessage id='common.period'/>:</b>
      <SelectField
        label={'Ano'}
        className='custom-dropdown mr-4'
        options={years}
        defaultOption={{value: currentYear}}
        onSelect={option => {
          if(onSelectYear) {
            onSelectYear(option);
          }
          setCurrentYear(option.value);
        }}
      />
      {!hideMonth && (
        <SelectField
          label={'Mês'}
          className="custom-dropdown"
          options={months}
          defaultOption={months[currentMonth]}
          onSelect={option => {
            if(onSelectMonth) {
              onSelectMonth(option);
            }
            setCurrentMonth(option.value);
          }}
        />
      )}
    </div>
  )
}

PeriodSelector.propTypes = {
  years: PropTypes.array.isRequired,
  currentYear: PropTypes.number.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
  hideMonth: PropTypes.bool,
  months: PropTypes.array.isRequired,
  currentMonth: PropTypes.number.isRequired,
  setCurrentMonth: PropTypes.func.isRequired
};
