import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { RadioButton } from '../../../components/form/RadioButton';

const MONTH = 'month';
const YEAR = 'year';

export function ResultsMonthYear({onChange, view}) {

  useEffect(() => {
    if (typeof onChange === 'function') onChange(view);
  }, [view]);

  return (
    <div className='d-flex align-items-center'>
      <span><FormattedMessage id='common.results'/>:</span>
      <RadioButton
        className='mr-4 ml-4'
        text={<FormattedMessage id='common.monthly'/>}
        formControl='view'
        value={MONTH}
        onChange={e => onChange(e.target.value)}
        checked={view === MONTH}
      />
      <RadioButton
        text={<FormattedMessage id='common.yearly'/>}
        formControl='view'
        value={YEAR}
        onChange={e => onChange(e.target.value)}
        checked={view === YEAR}
      />
    </div>
  )
}

ResultsMonthYear.propTypes = {
  onChange: PropTypes.func,
  view: PropTypes.string
};

