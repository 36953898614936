import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {ActionMenu} from '../ActionMenu';

export function SelectField({
  label,
  options = [],
  onSelect,
  className,
  defaultOption = {},
  icon,
  noBorder,
  position,
  above
}) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(defaultOption);

  function handleSelection(option) {
    setSelected(option);
    setOpen(false);

    if (onSelect) {
      onSelect(option);
    }
  }

  return (
    <div className={'position-relative' + (className ? ` ${className}` : '')}>
      <ActionMenu
        isOpened={open}
        trigger={(
          <div
            className={`form-control cursor-pointer align-middle ${noBorder ? 'no-borders' : ''}`}
            style={{minWidth: label && !icon ? 200 : 'auto', padding: '0.5rem 1.75rem 0.5rem 1.2rem', height: 'auto' }}
            onClick={() => setOpen(true)}
          >
            {icon}
            <span>{!icon && label && `${label}:`} <strong>{selected.name || selected.value}</strong></span>
          </div>
        )}
        content={
          options.map(option => (
            <div
              className={'px-5 py-3 item-hover cursor-pointer' + (option.value === selected.value ? ' active' : '')}
              onClick={() => handleSelection(option)}
              key={option.value}
              style={{ whiteSpace: 'nowrap' }}
            >
              {option.name || option.value}
            </div>
          ))
        }
        position={position}
        above={above}
      >
      </ActionMenu>
    </div>
  )
}

SelectField.propTypes = {
  label: PropTypes.any,
  options: PropTypes.array,
  onSelect: PropTypes.func,
  className: PropTypes.string,
  defaultOption: PropTypes.object
};
