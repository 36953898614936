import React, { useState, useEffect } from "react";
import { CheckedTab } from "../../components/tabs/CheckedTab";
import { CheckedTabsWrapper } from "../../components/tabs/CheckedTabsWrapper";
import { data } from "../../helpers/axiosModules";
import { FormattedMessage } from "react-intl";
import { Table } from "../../components/Table";
import { camelCaseString } from "../../utils/String.utils";
import { RankedCollapsible } from "./components/RankedCollapsible";
import { User } from "../../components/User";
import * as _ from "lodash";
import { BreadCrumbDate } from "../../components/BreadCrumbDate";
import moment from "moment";
import { RegionCooperativePicker } from "./components/RegionCooperativePicker";
import SafeValueUtils from "../../utils/SafeValue.utils";

const CheckedTabAccess = ({
  children,
  text,
  description,
  active,
  onClick,
  enabledTab,
  users,
}) => (
  <CheckedTab alignCheckIcon={true} active={active} onClick={onClick}>
    <div className="pl-3">
      <div>
        <small className="primary-color">
          <FormattedMessage id={description} />
        </small>
      </div>
      <div className="d-flex w-100">
        <div style={{ width: 134 }}>
          <h5 className="text-bold">{text}</h5>
          <div className="btn btn-secondary mt-1 w-100">
            {users && users.length === 1
              ? enabledTab === "lactation"
                ? users[0].milking
                : enabledTab === "dry"
                ? users[0].dry
                : SafeValueUtils.getMax(users, "race").value
              : users && users.length > 1
              ? enabledTab === "lactation"
                ? users.reduce((a, b) => a.milking + b.milking)
                : enabledTab === "dry"
                ? users.reduce((a, b) => a.dry + b.dry)
                : SafeValueUtils.getMax(users, "race").value
              : "-"}
          </div>
        </div>
        <div className="pl-4">{children}</div>
      </div>
    </div>
  </CheckedTab>
);

export function HerdPage() {
  let firstLoad = true;
  const [enabledTab, setEnabledTab] = useState("lactation");
  const [usersBackup, setUsersBackup] = useState([]);
  const [users, setUsers] = useState([]);
  const [topUsers, setTopUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchDate, setSearchDate] = useState({
    initialDate: moment().subtract(6, "months").format("YYYY-MM-DD"),
    finalDate: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    fetchData();
  }, [searchDate, setSearchDate]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const p = await Promise.all([
      data.get(
        `/analytics/milkinganddry?initdate=${searchDate.initialDate}&enddate=${
          searchDate.finalDate
        }&cachetick=${Math.random()}`
      ),
    ]);
    setLoading(false);
    const users = p[0].data.map((el) => {
      return {
        _id: el._id,
        name: camelCaseString(el.name),
        milking: el.milking,
        dry: el.dry,
        race: camelCaseString(el.race),
        city: el.city,
        image_url: el.photoLink,
        cooperative: el.segLevel1,
      };
    });
    setUsers(users);
    setTopUsers(users);
    setUsersBackup(users);
  };

  return (
    <>
      <BreadCrumbDate
        onChange={(value) => {
          if (
            firstLoad &&
            value &&
            value.setFinalDate &&
            value.setInitialDate
          ) {
            firstLoad = false;
            value.setFinalDate(null);
            value.setInitialDate(null);
          } else if (value.initialDate && value.finalDate) {
            setSearchDate({
              initialDate: moment(value.initialDate).format("YYYY-MM-DD"),
              finalDate: moment(value.finalDate).format("YYYY-MM-DD"),
            });
          }
        }}
      />
      <CheckedTabsWrapper>
        <CheckedTabAccess
          description="herd.total_number_of_cows_in"
          text={<FormattedMessage id="herd.lactation" />}
          active={enabledTab === "lactation"}
          enabledTab={"lactation"}
          users={users}
          onClick={() => setEnabledTab("lactation")}
        />
        <CheckedTabAccess
          description="herd.total_number_of_cows"
          text={<FormattedMessage id="herd.dry" />}
          active={enabledTab === "dry"}
          enabledTab={"dry"}
          users={users}
          onClick={() => setEnabledTab("dry")}
        />
        <CheckedTabAccess
          description="herd.predominant_race"
          text={SafeValueUtils.getMax(users, "race").key}
          enabledTab={"race"}
          users={users}
          active={enabledTab === "predominant"}
          onClick={() => setEnabledTab("predominant")}
        />
      </CheckedTabsWrapper>

      <div className="row pb-3">
        <div className="col-9">
          <div className="card">
            <RegionCooperativePicker
              onCooperativeChanged={(ans) => {
                if (!ans || !usersBackup) {
                  return;
                }
                // All
                if (ans.value === 0) {
                  setUsers(usersBackup);
                  return;
                }
                const filteredData = _.filter(usersBackup, (el) => {
                  return el.cooperative === ans.value;
                });
                setUsers(filteredData);
              }}
              // onRegionChanged={onRegionChanged}
            />
            <Table
              columns={
                enabledTab === "lactation"
                  ? [
                      {
                        title: "#",
                        render: (rowData) => (
                          <span>{rowData.tableData.id + 1}º</span>
                        ),
                      },
                      {
                        title: <FormattedMessage id="common.name" />,
                        field: "name",
                        render: (props) => <User user={props} />,
                      },
                      { title: "Vacas em Lactação", field: "milking" },
                      {
                        title: <FormattedMessage id="common.cooperative" />,
                        field: "cooperative",
                        render: (props) =>
                          SafeValueUtils.getCooperative(props.cooperative),
                      },
                    ]
                  : enabledTab === "dry"
                  ? [
                      {
                        title: "#",
                        render: (rowData) => (
                          <span>{rowData.tableData.id + 1}º</span>
                        ),
                      },
                      {
                        title: <FormattedMessage id="common.name" />,
                        field: "name",
                        render: (props) => <User user={props} />,
                      },
                      { title: "Vacas secas", field: "dry" },
                      {
                        title: <FormattedMessage id="common.cooperative" />,
                        field: "cooperative",
                        render: (props) =>
                          SafeValueUtils.getCooperative(props.cooperative),
                      },
                    ]
                  : [
                      {
                        title: "#",
                        render: (rowData) => (
                          <span>{rowData.tableData.id + 1}º</span>
                        ),
                      },
                      {
                        title: <FormattedMessage id="common.name" />,
                        field: "name",
                        render: (props) => <User user={props} />,
                      },
                      { title: "Raça predominante", field: "race" },
                      {
                        title: <FormattedMessage id="common.cooperative" />,
                        field: "cooperative",
                        render: (props) =>
                          SafeValueUtils.getCooperative(props.cooperative),
                      },
                    ]
              }
              isLoading={loading}
              data={users}
              offsetTop={415}
              toolbar={true}
              search={true}
              pickerFilters={true}
            />
          </div>
        </div>
        <div className="col-3">
          <div className="card full-height">
            <div className="text-fold p-4 text-center text-bold">
              {enabledTab === "lactation" ? (
                <FormattedMessage id="herd.top_properties_lac_cows" />
              ) : enabledTab === "dry" ? (
                <FormattedMessage id="herd.top_properties_dry_cows" />
              ) : (
                <FormattedMessage id="herd.top_properties" />
              )}
            </div>
            <div>
              {topUsers
                .sort((a, b) => {
                  return enabledTab === "lactation"
                    ? b.milking - a.milking
                    : enabledTab === "dry"
                    ? b.dry - a.dry
                    : 0;
                })
                .map((user, i) => (
                  <RankedCollapsible
                    trigger={<User user={user} />}
                    numeration={i + 1}
                    key={i}
                  >
                    <div className="pl-5 pb-5">
                      <div>
                        Total:{" "}
                        {enabledTab === "lactation"
                          ? user.milking
                          : enabledTab === "dry"
                          ? user.dry
                          : user.race}
                      </div>
                      <div>
                        Coop.: {SafeValueUtils.getCooperative(user.cooperative)}
                      </div>
                    </div>
                  </RankedCollapsible>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
