import React, { useEffect, useState } from "react";
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
} from "victory";
import Theme from "../../utils/Chart.theme";
import { CheckedTab } from "../../components/tabs/CheckedTab";
import { CheckedTabsWrapper } from "../../components/tabs/CheckedTabsWrapper";
import { data } from "../../helpers/axiosModules";
import { FormattedMessage } from "react-intl";
import { Table } from "../../components/Table";
import { camelCaseString } from "../../utils/String.utils";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { RankedCollapsible } from "./components/RankedCollapsible";
import { User } from "../../components/User";
import { BreadCrumbDate } from "../../components/BreadCrumbDate";
import maskPattern from "../../utils/Masks";
import StringMask from "string-mask";
import moment from "moment";
import SafeValueUtils from "../../utils/SafeValue.utils";
import * as _ from "lodash";
import { RegionCooperativePicker } from "./components/RegionCooperativePicker";

function getTopUsers(topUsers) {
  return (
    <div>
      {topUsers.map((user, i) => (
        <RankedCollapsible
          trigger={<User user={user} />}
          numeration={i + 1}
          key={i}
        >
          <div className="pl-5 pb-5">
            <div>
              CPF: {new StringMask(maskPattern.cpf).apply(user.identification)}
            </div>
            <div>
              Coope.: {SafeValueUtils.getCooperative(user.cooperativeCode)}
            </div>
          </div>
        </RankedCollapsible>
      ))}
    </div>
  );
}

function getTopUsersChart(topCount) {
  if (!topCount) {
    return [];
  }

  return topCount.map((el) => {
    return {
      x: el.users,
      y: moment(el.date).month(),
    };
  });
}

const CheckedTabAccess = ({ children, text, active, onClick, countUsers }) => (
  <CheckedTab alignCheckIcon={true} active={active} onClick={onClick}>
    <div className="pl-3">
      <div>
        <small className="primary-color">
          <FormattedMessage id="tabs.accesses_amount" />
        </small>
      </div>
      <div className="d-flex w-100">
        <div style={{ width: 134 }}>
          <h5 className="text-bold">{text}</h5>
          <div className="btn btn-secondary mt-1 w-100">{countUsers}</div>
        </div>
        <div className="pl-4">{children}</div>
      </div>
    </div>
  </CheckedTab>
);

const BlackTooltip = ({ x, y, datum }) => {
  return (
    <g>
      <rect
        x={x}
        y={y - 30}
        fill={"#1D2E44"}
        width={130}
        height={30}
        rx={15}
        ry={15}
      />
      <text
        x={x + 32.5}
        y={y - 14}
        fill={"#fff"}
        textAnchor="middle"
        alignmentBaseline="central"
        style={{ fontSize: 11 }}
      >
        {`${datum.y} ativos`}
      </text>
      <text
        x={x + 95}
        y={y - 14}
        fill={"#fff"}
        textAnchor="middle"
        alignmentBaseline="central"
        style={{ fontSize: 11 }}
      >
        {moment.months(datum.x || datum._x)}
      </text>
    </g>
  );
};

export function UsersPage() {
  let firstLoad = true;
  const [enabledTab, setEnabledTab] = useState("actives");
  const [topUsersCount, setTopUsersCount] = useState([]);
  const [topInactiveUsersCount, setTopInactiveUsersCount] = useState([]);
  const [topUsers, setTopUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [top100Users, setTop100Users] = useState([]);
  const [top1000Users, setTop1000Users] = useState([]);
  const [topInactiveUsers, setTopInactiveUsers] = useState([]);
  const [topInactive100Users, setTopInactive100Users] = useState([]);
  const [topInactive1000Users, setTopInactive1000Users] = useState([]);
  const [searchDate, setSearchDate] = useState({
    initialDate: moment().subtract(6, "months").format("YYYY-MM-DD"),
    finalDate: moment().format("YYYY-MM-DD"),
  });
  const [loading, setLoading] = useState();

  const fetchData = async () => {
    setLoading(true);
    const p = await Promise.all([
      data.get(
        `/analytics/topusers?initdate=${searchDate.initialDate}&enddate=${searchDate.finalDate}`
      ),
      data.get(
        `/analytics/inactiveusers?initdate=${searchDate.initialDate}&enddate=${searchDate.finalDate}`
      ),
      data.get(
        `/analytics/topuserscount?initdate=${searchDate.initialDate}&enddate=${searchDate.finalDate}`
      ),
      data.get(
        `/analytics/inactiveuserscount?initdate=${searchDate.initialDate}&enddate=${searchDate.finalDate}`
      ),
    ]);
    setLoading(false);
    const topUsers = p[0].data.map((user) => {
      return {
        _id: user._id,
        image_url: user.profile ? user.profile.photoLink : null,
        name: camelCaseString(user.name),
        cooperative: SafeValueUtils.getCooperative(user.mainCooperativeCode),
        cooperativeCode: user.mainCooperativeCode,
        region: "-",
        identification: user.identification,
      };
    });

    const topInactiveUsers = p[1].data.map((user) => {
      return {
        _id: user._id,
        image_url: user.profile ? user.profile.photoLink : null,
        name: camelCaseString(user.name),
        cooperative: SafeValueUtils.getCooperative(user.mainCooperativeCode),
        cooperativeCode: user.mainCooperativeCode,
        region: "-",
        identification: user.identification,
      };
    });

    setTopUsers(topUsers);
    setTop100Users(topUsers.slice(0, 100));
    setTop1000Users(topUsers.slice(0, 1000));

    setTopInactiveUsers(topInactiveUsers);
    setTopInactive100Users(topInactiveUsers.slice(0, 100));
    setTopInactive1000Users(topInactiveUsers.slice(0, 1000));

    setTopUsersCount(
      p[2].data.map((value) => {
        return {
          users: value.users,
          date: value._id,
        };
      })
    );
    setTopInactiveUsersCount(
      p[3].data.map((value) => {
        return {
          users: value.users,
          date: value._id,
        };
      })
    );
    setUsers(enabledTab === "actives" ? topUsers : topInactiveUsers);
  };

  useEffect(() => {
    fetchData();
  }, [searchDate, setSearchDate, enabledTab]);

  return (
    <>
      <BreadCrumbDate
        onChange={(value) => {
          if (
            firstLoad &&
            value &&
            value.setFinalDate &&
            value.setInitialDate
          ) {
            firstLoad = false;
            value.setFinalDate(null);
            value.setInitialDate(null);
          } else if (value.initialDate && value.finalDate) {
            setSearchDate({
              initialDate: moment(value.initialDate).format("YYYY-MM-DD"),
              finalDate: moment(value.finalDate).format("YYYY-MM-DD"),
            });
          }
        }}
      />
      <CheckedTabsWrapper>
        <CheckedTabAccess
          text={<FormattedMessage id="common.actives" />}
          active={enabledTab === "actives"}
          onClick={() => setEnabledTab("actives")}
          countUsers={topUsers.length || 0}
        >
          <div
            style={{
              flex: 1,
              marginTop: -20,
              position: "relative",
            }}
          >
            <VictoryChart
              animate={{ duration: 2000 }}
              theme={Theme}
              domain={[0, 12]}
              width={400}
              height={150}
              containerComponent={
                <VictoryVoronoiContainer
                  labels={({ y, x }) => `${y} ativos ${moment.months(x)}`}
                  labelComponent={<BlackTooltip />}
                />
              }
            >
              <VictoryArea
                style={{
                  data: {
                    fill: enabledTab === "actives" ? "#c2f0c6" : "#c5d5e8",
                  },
                }}
                data={getTopUsersChart(topUsersCount, true)}
              />
              <VictoryLine
                style={{
                  data: {
                    stroke: enabledTab === "actives" ? "#41b049" : "#657fa0",
                  },
                  parent: { border: "1px solid #ccc" },
                }}
                data={getTopUsersChart(topUsersCount, true)}
              />
              <VictoryAxis
                style={{
                  axis: {
                    stroke: "none",
                  },
                  ticks: {
                    fill: "transparent",
                    stroke: "transparent",
                  },
                  tickLabels: {
                    fill: "transparent",
                  },
                }}
              />
              <VictoryScatter
                data={getTopUsersChart(topUsersCount, true)}
                style={{
                  data: {
                    fill: (datum, active) => (active ? "white" : "transparent"),
                    stroke: (datum, active) => (active ? "red" : "transparent"),
                    strokeWidth: 2,
                  },
                }}
              />
            </VictoryChart>
          </div>
        </CheckedTabAccess>
        <CheckedTabAccess
          text={<FormattedMessage id="common.inactives" />}
          active={enabledTab === "inactives"}
          onClick={() => setEnabledTab("inactives")}
          countUsers={topInactiveUsers.length || 0}
        >
          <div
            style={{
              flex: 1,
              marginTop: -20,
              position: "relative",
            }}
          >
            <VictoryChart
              animate={{ duration: 2000 }}
              theme={Theme}
              domain={[0, 12]}
              width={400}
              height={150}
              containerComponent={
                <VictoryVoronoiContainer
                  labels={({ y, x }) => `${y} ativos ${moment.months(x)}`}
                  labelComponent={<BlackTooltip />}
                />
              }
            >
              <VictoryArea
                style={{
                  data: {
                    fill: enabledTab === "actives" ? "#c5d5e8" : "#c2f0c6",
                  },
                }}
                data={getTopUsersChart(topInactiveUsersCount, false)}
              />
              <VictoryLine
                style={{
                  data: {
                    stroke: enabledTab === "actives" ? "#657fa0" : "#41b049",
                  },
                  parent: { border: "1px solid #ccc" },
                }}
                data={getTopUsersChart(topInactiveUsersCount, false)}
              />
              <VictoryAxis
                style={{
                  axis: {
                    stroke: "none",
                  },
                  ticks: {
                    fill: "transparent",
                    stroke: "transparent",
                  },
                  tickLabels: {
                    fill: "transparent",
                  },
                }}
              />
              <VictoryScatter
                data={getTopUsersChart(topUsersCount, true)}
                style={{
                  data: {
                    fill: (datum, active) => (active ? "white" : "transparent"),
                    stroke: (datum, active) => (active ? "red" : "transparent"),
                    strokeWidth: 2,
                  },
                }}
              />
            </VictoryChart>
          </div>
        </CheckedTabAccess>
      </CheckedTabsWrapper>

      <div className="row pb-3">
        <div className="col-8">
          <div className="card">
            <RegionCooperativePicker
              onCooperativeChanged={(ans) => {
                if (!ans) {
                  return;
                }
                // All
                if (ans.value === 0) {
                  setUsers(
                    enabledTab === "actives" ? topUsers : topInactiveUsers
                  );
                  return;
                }
                let filteredData =
                  enabledTab === "actives" ? topUsers : topInactiveUsers;
                filteredData = _.filter(filteredData, (el) => {
                  return el.cooperativeCode === ans.value;
                });
                setUsers(filteredData);
              }}
            />
            <Table
              columns={[
                {
                  title: "#",
                  disableClick: true,
                  sorting: false,
                  render: (rowData) => <span>{rowData.tableData.id + 1}º</span>,
                },
                {
                  title: <FormattedMessage id="common.name" />,
                  field: "name",
                  disableClick: true,
                  sorting: false,
                  render: (props) => <User user={props} />,
                },
                {
                  title: <FormattedMessage id="common.cooperative" />,
                  disableClick: true,
                  sorting: false,
                  field: "cooperative",
                },
              ]}
              isLoading={loading}
              data={users}
              offsetTop={415}
              toolbar={true}
              search={true}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="card full-height">
            <div className="text-fold p-4 text-center text-bold">
              {enabledTab === "actives" && (
                <FormattedMessage id="users.top_active" />
              )}
              {enabledTab === "inactives" && (
                <FormattedMessage id="users.top_inactive" />
              )}
            </div>
            <Tabs>
              <TabList>
                <Tab>Top 10</Tab>
                <Tab>Top 100</Tab>
                <Tab>Top 1000</Tab>
              </TabList>

              <TabPanel>
                {getTopUsers(
                  enabledTab === "actives" ? topUsers : topInactiveUsers
                )}
              </TabPanel>
              <TabPanel>
                {getTopUsers(
                  enabledTab === "actives" ? top100Users : topInactive100Users
                )}
              </TabPanel>
              <TabPanel>
                {getTopUsers(
                  enabledTab === "actives" ? top1000Users : topInactive1000Users
                )}
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}
