import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbContext } from './BreadCrumb';
import { DateRange } from './DateRange';
import { ReactComponent as CalendarIcon } from '../assets/svg-icons/Icon-calendar.svg';

export function BreadCrumbDate({onChange, onSearch = null, searchPlaceHolder}) {
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumbRightContent({
      filter: <DateRange onChange={onChange} />,
      filterIcon: <CalendarIcon/>,
      onSearch,
      searchPlaceHolder
    });

    return () => {
      setBreadcrumbRightContent({
        filter: null,
        filterIcon: null
      });
    };
  }, []);

  return null;
}

BreadCrumbDate.propType = {
  onChange: PropTypes.func,
  onSearch: PropTypes.func
};
