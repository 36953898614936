import React, { useState } from "react";
import logo from "../../whitelabel/logo.jpg";
import { CardSignedOut } from "../../components/CardSignedOut";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { BarLoader } from "react-spinners";
import { ReactComponent as EyeIcon } from "../../assets/svg-icons/icon-eye.svg";
// import { application } from '../../helpers/axiosModules';
import { setupAxios } from "../../helpers/axiosModules";
import { authBackoffice } from "../../api/routes-app";

export function SignInPage({ history }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function logIn(event) {
    event.preventDefault();
    setLoading(true);
    try {
      const ans = await fetch(authBackoffice(), {
        body: JSON.stringify({
          username: email,
          password,
        }),
        method: "POST",
      });
      const bans = await ans.json();
      if (bans && bans.token) {
        sessionStorage.setItem("jwtoken", bans.token);
        setupAxios();
        setTimeout(() => {
          setLoading(false);
          history.push("/dashboard");
        }, 1000);
      } else {
        setLoading(false);
        setError("invalid_login");
      }
    } catch (ex) {
      setLoading(false);
      setError("invalid_login");
    }
  }

  return (
    <div className="sign-in-page">
      <CardSignedOut>
        <div style={{ position: "absolute", top: 0, right: 0, left: 0 }}>
          <BarLoader
            widthUnit="%"
            color="#41b049"
            width={100}
            loading={loading}
          />
        </div>
        <div className="text-center">
          <div className="p-1">
            <img alt="" src={logo} />
          </div>
          <div className="p-1">
            <h6 className="bottom-green">
              <FormattedMessage id="signin.welcome" />
            </h6>
          </div>
        </div>
        {!error ? null : (
          <div style={{ marginTop: "10px", textAlign: "center", color: "red" }}>
            <FormattedMessage
              id="signin.invalid_login"
              defaultMessage="Email"
            ></FormattedMessage>
          </div>
        )}
        <form onSubmit={(event) => logIn(event)}>
          <fieldset disabled={loading}>
            <div className="input-group pb-3 pt-5">
              <FormattedMessage id="signin.email" defaultMessage="Email">
                {(text) => {
                  return (
                    <input
                      name="email"
                      value={email}
                      onChange={({ target }) => setEmail(target.value)}
                      className="form-control"
                      placeholder={text}
                      required
                    />
                  );
                }}
              </FormattedMessage>
            </div>
            <div className="input-group">
              <FormattedMessage id="signin.password" defaultMessage="Password">
                {(text) => {
                  return (
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={password}
                      onChange={({ target }) => setPassword(target.value)}
                      className="form-control"
                      placeholder={text}
                      required
                    />
                  );
                }}
              </FormattedMessage>

              <span
                className="icon eye-icon"
                onClick={() => setShowPassword(!showPassword)}
              >
                {" "}
                <EyeIcon fill={"#040066"}></EyeIcon>{" "}
              </span>
            </div>
            <div className="py-3 text-right">
              <Link
                to="/account/forgot-password"
                className="text-bold"
                href="/"
              >
                <FormattedMessage id="signin.forgot_password" />
              </Link>
            </div>
            <div className="pb-3">
              <button
                className="btn btn-success py-3 w-100"
                disabled={!email || !password}
              >
                <FormattedMessage id="signin.log_in" />
              </button>
            </div>
          </fieldset>
        </form>
      </CardSignedOut>
    </div>
  );
}
