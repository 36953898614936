import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export function ActionMenu({
  trigger,
  content,
  isOpened,
  className,
  above,
  position = 'right',
  fullWidth = false
}) {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(isOpened);
  }, [isOpened]);

  const positions = {
    'right': {right: 0, left: 'unset'},
    'left': {left: 0, right: 'unset'},
    'top': {left: 'unset', right: 'unset', bottom: 60}
  };

  return (
    <>
      <div style={{position: 'relative'}} className={className}>
        <div onClick={() => setOpened(true)}>{trigger}</div>
        {opened && (
          <div className='dropdown-menu show mt-2' style={{
            width: fullWidth ? '100%': 'unset',
            position: 'absolute',
            zIndex: 100,
            top: above ? 0 : 'unset',
            ...positions[position]
          }}>
            {content}
          </div>
        )}
      </div>
      {opened && (
        <div
          style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 50}}
          onClick={() => setOpened(false)}>
        </div>
      )}
    </>
  )
}

ActionMenu.propTypes = {
  trigger: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired,
  className: PropTypes.string,
  above: PropTypes.bool,
  position: PropTypes.string,
  fullWidth: PropTypes.bool
};
