import React, {useContext, useEffect, useState} from 'react';
import * as _ from 'lodash';
import { CategoryLayout } from './CategoryLayout';
import { FormControl } from '../../../components/form/FormContext';
import { FormattedMessage } from 'react-intl';
import { CommunicationType } from './CommunicationType';
import { NotificationInfo } from './NotificationInfo';
import Loading from '../../../components/loading/Loading';
import service from '../notificationsService';
import {BreadcrumbContext} from "../SettingsWrapper";
import types, {categories} from './types';
import { Conditional } from './Conditional';
import {SidebarContext} from "../../../components/layouts/Main/Main";
import CategoriesSidebar from "./CategoriesSidebar";

let inDebounce;

const debounce = function (func, delay) {
  const context = this;
  const args = arguments;
  clearTimeout(inDebounce);
  inDebounce = setTimeout(() => func.apply(context, args), delay);
}

export function QualityCategory({match, location, history}) {
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [indicators, setIndicators] = useState([
    { name: 'SCC', text: <FormattedMessage id='producers.scc'/>, selected: true },
    { name: 'CBT', text: <FormattedMessage id='producers.cbt'/>, selected: false },
    { name: 'PROTEIN', text: <FormattedMessage id='producers.protein'/>, selected: false },
    { name: 'FAT', text: <FormattedMessage id='producers.fat'/>, selected: false }
  ]);
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);
  const setSidebarChildren = useContext(SidebarContext);

  const setIndicator = (name) => {
    const _indicators = _.map(indicators, indicator => {
      indicator.selected = indicator.name === name;
      return indicator;
    });

    setIndicators(_indicators);
  };

  const saveNotification = (e) => {
    e.preventDefault();
    _.assign({subtype: _.find(indicators, 'selected').name}, notification);

    setLoading(true);
    service.save(notification, types.QUALITY).then(() => {
      setLoading(false);
      history.push('/settings/notifications');
    });
  };

  const handleNotification = (_notification) => {
    // if (_notification.push !== notification.push) {
    //   _notification.alert = !_notification.push;
    // }

    // if (_notification.alert !== notification.alert) {
    //   _notification.push = !_notification.alert;
    // }

    debounce(function() {setNotification(_notification)}, 100);
  };

  useEffect(() => {
    const { id } = match.params;

    if (id === 'add') {
      setNotification({
        type: types.QUALITY,
        title: "",
        description: "",
        alert: true,
        push: false,
        conditionals: [
          {
            signal: "",
            value: 0,
            inclusive: false,
            partialResult: true
          }
        ],
      });
      setLoading(false);
      return;
    } else if (id) {
      service.get(id).then(data => {
        setNotification(data);
        setLoading(false);
      });
    }

    setBreadcrumbRightContent({});
    setSidebarChildren(<CategoriesSidebar categories={categories} />);
  }, []);

  if(loading) {
    return <Loading />
  }

  return (
    <FormControl values={notification} onChange={handleNotification} onSubmit={saveNotification}>
      <CategoryLayout
        title={notification.title}
        description={notification.description}
        icon={notification.type}
      >
        <fieldset className='pb-5'>
          <legend className='mb-4'><FormattedMessage id='settings.quality.indicator'/>*</legend>
          {indicators.map((indicator, i) =>
            <button
              key={i}
              type='button'
              onClick={() => setIndicator(indicator.name)}
              className={`btn btn-secondary mr-4 ${indicator.selected ? 'active' : ''}`}
            >
              {indicator.text || indicator.name}
            </button>
          )}
        </fieldset>
        <NotificationInfo />
        <fieldset className='pb-5'>
          <legend><FormattedMessage id='settings.conditional'/>*</legend>
          <Conditional name={_.find(indicators, 'selected').text} controlName='conditionals' />
        </fieldset>
        <CommunicationType />
      </CategoryLayout>
    </FormControl>
  )
}
