import React, {useState, useEffect} from 'react';
import {ReactComponent as IconFilter} from "../../../assets/svg-icons/Icon-filter.svg";
import {ReactComponent as IconProperty} from "../../../assets/svg-icons/Icon-property.svg";
import * as _ from 'lodash';
import {ReactComponent as IconPin} from "../../../assets/svg-icons/Icon-pin.svg";
import { SelectField } from '../../../components/form/SelectField';
import { data } from '../../../helpers/axiosModules';

let allCooperativas = {
  name: 'Todas as cooperativas',
  value: 0
};

let allRegions = {
  name: 'Todas as coordenações',
  value: 0
};

export function RegionCooperativePicker({ onCooperativeChanged, onRegionChanged }) {
  const [cooperatives, setCooperatives] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState();
  const [selectedCooperative, setSelectedCooperative] = useState(allCooperativas);

  useEffect(() => {
    fetchCooperatives();
  }, []);

  const fetchCooperatives = async () => {
    const p = await Promise.all([
      data.get(`/cooperative?&cachetick=${Math.random()}`)
    ]);
    if (p && p[0] && p[0].data) {
      const orderedCooperatives = _.orderBy(p[0].data, [el => el.name.toLowerCase()], ['asc']);
      const allOrderedCooperatives = [{
        name: 'Todas as cooperativas',
        value: 0
      }].concat(orderedCooperatives.map(cooperative => {
        return {
          name: `${cooperative.name} - ${cooperative.code}`,
          value: cooperative.code
        }
      }));
      window.cooperatives = allOrderedCooperatives;
      setCooperatives(allOrderedCooperatives);
    } else {
      console.error('No data returned from server.');
    }
  };

  return (
    <div className={`d-flex flex-row border-bottom align-content-center justify-content-between p-4`}>
      <div className="left-side d-flex flex-row">
        <div className="align-items-center d-flex p-4">
          <IconFilter fill="#657FA0" />
        </div>
        <SelectField
          label={'Região'}
          className='custom-dropdown mr-4'
          options={[allRegions]}
          defaultOption={allRegions}
          onSelect={option => {
            if(onRegionChanged) {
              onRegionChanged(option.value);
            }
          }}
          icon={<IconPin fill="#89A0BC" />}
          noBorder
        />
        <SelectField
          label={'Cooperativa'}
          className='custom-dropdown mr-4'
          options={cooperatives}
          defaultOption={selectedCooperative}
          onSelect={option => {
            setSelectedCooperative(option);
            if(onCooperativeChanged) {
              onCooperativeChanged(option);
            }
          }}
          icon={<IconProperty fill="#89A0BC" />}
          noBorder
        />
      </div>
    </div>
  );
}
