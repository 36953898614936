import { SignInPage } from './pages/account/SignInPage';
import { ForgotPassword } from './pages/account/ForgotPassword';
import { PanelWrapper } from './pages/panel/PanelWrapper';
import { ProducersWrapper } from './pages/producers/ProducersWrapper';
import { SettingsWrapper } from './pages/settings/SettingsWrapper';
import { NoPermission } from './pages/account/NoPermission';
import { TryAgainLater } from './pages/account/TryAgainLater';
import { PublicationWrapper } from './pages/publications/PublicationsWrapper';
import { NotificationsWrapper } from './pages/notifications/NotificationsWrapper';
import { DashboardWrapper } from './pages/dashboard/DashboardWrapper';

export const routes = [
  {
    path: '/account/sign-in',
    component: SignInPage,
    protected: false
  },
  {
    path: '/nopermission',
    component: NoPermission,
    protected: true
  },
  {
    path: '/trylater',
    component: TryAgainLater,
    protected: true
  },
  {
    path: '/account/forgot-password',
    component: ForgotPassword,
    protected: false
  },
  {
    path: '/dashboard',
    component: DashboardWrapper,
    protected: true
  },
  {
    path: '/panel',
    component: PanelWrapper,
    protected: true
  },
  // {
  //   path: '/posts',
  //   component: PostsWrapper,
  //   protected: true
  // },
  {
    path: '/publications',
    component: PublicationWrapper,
    protected: true
  },

  {
    path: '/producers',
    component: ProducersWrapper,
    protected: true
  },
  {
    path: '/notifications',
    component: NotificationsWrapper,
    protected: true
  },
  {
    path: '/settings',
    component: SettingsWrapper,
    protected: true
  }
];
