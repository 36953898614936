import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import moment from "moment";
import Loading from "../../components/loading/Loading";
import ActiveUsers from "./container/ActiveUsers";
import MostInteractiveUsers from "./container/MostInteractiveUsers";
import MostInteractiveUsersQualityMonth from "./container/MostInteractiveUsersQualityMonth";
import MostLikedPostAtThisMonth from "./container/MostLikedPostAtThisMonth";
import PostLikesAtThisMonth from "./container/PostLikesAtThisMonth";
import PostLikesAtThisWeek from "./container/PostLikesAtThisWeek";
import UsersThatLikedPostsCreatedAtThisMonth from "./container/UsersThatLikedPostsCreatedAtThisMonth";
import { application } from "../../helpers/axiosModules";
import {
  getUsersThatLikedPostsCreatedAtThisMonth,
  getMostLikedPostAtThisMonth,
  getPostLikesAtThisMonth,
  getPostLikesAtThisWeek,
} from "../../api/routes-app";

// Total likes on posts for the current week
// Comentário: setar as datas (from e to) para a semana atual
// Exemplo: v1/publication_count_likes?from=2020-07-20T01:54:44.359+00:00&to=2023-07-20T01:54:44.359+00:00

// Total likes on posts for the current month
// Comentário: setar as datas (from e to) para o mês atual
// Exemplo: v1/publication_count_likes?from=2020-07-20T01:54:44.359+00:00&to=2023-07-20T01:54:44.359+00:00

// Most liked post in the current month
// Comentário: setar as datas (from e to) para o mês atual
// Exemplo: v1/publication_most_liked?from=2020-07-20T01:54:44.359+00:00&to=2023-07-20T01:54:44.359+00:00&limit=1

// 20 users who most liked posts created in the current month
// Exemplo: v1/publication_users_who_most_liked?from=2020-07-20T01:54:44.359+00:00&to=2023-07-20T01:54:44.359+00:00
// Resultado: array de users (identification e name)

// Users who most interacted with the app this month
// functions/interaction-events_handler.filterInteraction
// Exemplo: v1/interactions_filter?from=2020-07-20T01:54:44.359+00:00&to=2023-07-20T01:54:44.359+00:00

// Users who used quality data the most this month
// functions/interaction-events_handler.filterInteraction
// Exemplo: v1/interactions_filter?from=2020-07-20T01:54:44.359+00:00&to=2023-07-20T01:54:44.359+00:00&screenName=quality

const analyticsDataDefaultProps = {
  postLikesAtThisWeek: 0,
  postLikesAtThisMonth: 0,
  mostLikedPostAtThisMonth: null,
  usersThatLikedPostsCreatedAtThisMonth: [],
};

export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [data, setAnalyticsData] = useState(analyticsDataDefaultProps);

  const fetchAnalyticsData = async () => {
    setLoading(true);
    const fromDateThisMonth = moment()
      .startOf("month")
      .utcOffset(0, true)
      .format();
    const toDateThisMonth = moment().endOf("month").utcOffset(0, true).format();

    const fromDateThisWeek = moment()
      .startOf("week")
      .utcOffset(0, true)
      .format();
    const toDateThisWeek = moment().endOf("week").utcOffset(0, true).format();

    try {
      const responses = await Promise.all([
        application
          .get(
            getUsersThatLikedPostsCreatedAtThisMonth(
              fromDateThisMonth,
              toDateThisMonth
            )
          )
          .catch((_) => null),
        application
          .get(getMostLikedPostAtThisMonth(fromDateThisMonth, toDateThisMonth))
          .catch((_) => null),
        application
          .get(getPostLikesAtThisMonth(fromDateThisMonth, toDateThisMonth))
          .catch((_) => null),
        application
          .get(getPostLikesAtThisWeek(fromDateThisWeek, toDateThisWeek))
          .catch((_) => null),
      ]);

      const responseData = {
        usersThatLikedPostsCreatedAtThisMonth: responses[0]
          ? responses[0].data
          : [],
        mostLikedPostAtThisMonth:
          responses[1] && responses[1].data.length
            ? responses[1].data[0]
            : null,
        postLikesAtThisMonth: responses[2] ? responses[2].data : 0,
        postLikesAtThisWeek: responses[3] ? responses[3].data : 0,
      };

      setAnalyticsData(responseData);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnalyticsData();
  }, []);

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;
  if (!data) return <p>Not found</p>;

  return (
    <Grid container spacing={3} className="mt-5">
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <PostLikesAtThisWeek error={error} loading={loading} data={data} />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <PostLikesAtThisMonth error={error} loading={loading} data={data} />
      </Grid>

      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <MostLikedPostAtThisMonth error={error} loading={loading} data={data} />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <UsersThatLikedPostsCreatedAtThisMonth
          item={data.usersThatLikedPostsCreatedAtThisMonth}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <ActiveUsers />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <MostInteractiveUsers />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <MostInteractiveUsersQualityMonth />
      </Grid>
    </Grid>
  );
}
