import React from 'react';
import PropTypes from 'prop-types';

export function RadioButton({formControl, onChange, text, value, checked, className, border}) {
  return (
    <label className={'m-0 d-flex cursor-pointer align-items-center' + (className ? ` ${className}` : '')}>
      <input
        type='radio'
        name={formControl}
        value={value}
        defaultChecked={checked}
        onChange={onChange}
        style={{position: 'absolute', zIndex: -1}}
      />
      <div style={{
        background: '#fff',
        border: border ? '1px solid green' : '1px solid white',
        borderRadius: 22,
        height: 22,
        width: 22,
        marginRight: 12,
        marginTop: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
      }>
        <div style={{
          borderRadius: 10,
          height: checked ? 10 : 0,
          width: checked ? 10 : 0,
          transition: 'all .1s'
        }}
             className='bg-color-1'
        />
      </div>
      <span>{text}</span>
    </label>
  )
}

RadioButton.propTypes = {
  formControl: PropTypes.string,
  onChange: PropTypes.func,
  text: PropTypes.any,
  value: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string
};
