export function camelCaseString(str) {
  if(!str) {
    return undefined;
  }

  if(str.length <= 0) {
    return str;
  }

  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1).toLowerCase();
  }
  return splitStr.join(' ');
}
