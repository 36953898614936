import React from "react";
import { FormattedMessage } from "react-intl";
import { IconWithText } from "../../components/IconWithText";

import { ReactComponent as NotificationsIcon } from "../../assets/svg-icons/Icon-bell.svg";

export function NotificationsSidebar() {
  return (
    <div>
      <IconWithText
        icon={<NotificationsIcon />}
        text={<FormattedMessage id="header.notifications" />}
      />
    </div>
  );
}
