const mobilePhone = '(99) 99999-9999';
const phone = '(99) 9999-9999';
const cpf = '999.999.999-99';
const cnpj = '99.999.999/9999-99';
const zipCode = '99999-999';

export default {
  mobilePhone,
  phone,
  cpf,
  cnpj,
  zipCode
}
