import React, { useContext, useState, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { SidebarContext } from "../../components/layouts/Main/Main";
import { BreadCrumb, BreadcrumbContext } from "../../components/BreadCrumb";
import { PublicationsSidebar } from "./PublicationsSidebar";
import { Publications } from "./Publications";
import EditPublications from "./container/EditPublications";

function PublicationWrapper() {
  const history = useHistory();

  const setSidebarChildren = useContext(SidebarContext);
  const [breadcrumb, setBreadcrumb] = useState({
    filter: null,
    filterIcon: null,
    onSearch: null,
  });

  useEffect(() => {
    setSidebarChildren(<PublicationsSidebar />);
  }, []);

  return (
    <BreadcrumbContext.Provider value={setBreadcrumb}>
      <BreadCrumb {...breadcrumb} />
      <div>
        <Switch>
          <Route history={history} exact path="/publications">
            <Publications />
          </Route>
          <Route history={history} exact path="/publications/edit/:id">
            <EditPublications />
          </Route>
          <Redirect to="/publications" />
        </Switch>
      </div>
    </BreadcrumbContext.Provider>
  );
}
export { PublicationWrapper };
