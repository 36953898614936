import React, { useState, useEffect, useContext } from "react";
import * as _ from "lodash";
import StringMask from "string-mask";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { SidebarContext } from "../../components/layouts/Main/Main";
import { SettingsSidebar } from "./SettingsSidebar";
import { application } from "../../helpers/axiosModules";
import Loading from "../../components/loading/Loading";
import { Table } from "../../components/Table";
import { UserImage } from "../../components/UserImage";
import { Modal } from "../../components/Modal";
import { ToggleField } from "../../components/form/ToggleField";
import { FormControl } from "../../components/form/FormContext";
import { CheckboxField } from "../../components/form/CheckboxField";
import maskPattern from "../../utils/Masks";
import { InputField } from "../../components/form/InputField";
import { variables } from "../../whitelabel/variables";
import {
  administratorsGetByIdentification,
  permissionsAll,
  administratorsUpdatePermissions,
} from "../../api/routes-app";
import localeUtils from "../../utils/locale.utils";

import { ReactComponent as InfoIcon } from "../../assets/svg-icons/Icon-info.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg-icons/Icon-trash-small.svg";
import { ReactComponent as IconFilter } from "../../assets/svg-icons/Icon-filter.svg";
import { ReactComponent as IconClose } from "../../assets/svg-icons/Icon-close.svg";

import { BreadcrumbContext } from "./SettingsWrapper";

const styles = {
  permissionBox: {
    border: "1px solid rgba(101, 127, 160, 0.33)",
    borderRadius: 6,
    margin: "0 10px",
    padding: 24,
    width: 274,
  },
  selectedPermissionBox: {
    boxShadow: "0 0 10px rgba(101, 127, 160, 0.33)",
    backgroundColor: "#41b04936",
    border: "1px solid rgba(101, 127, 160, 0.33)",
    borderRadius: 6,
    margin: "0 10px",
    padding: 24,
    width: 274,
  },
  languageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  actionsRowContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

const flagStyles = {
  nameCircle: {
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  },
};

const FlagItem = ({ name, src, alt, locale }) => {
  return (
    <div
      className="p-1 mx-2 cursor-pointer"
      onClick={() => {
        localeUtils.setLocale(name);
        window.location.replace("/dashboard");
      }}
      z
      style={{
        borderRadius: 24,
        border: locale === name ? "2px solid #40b049" : "1px solid #ccc",
      }}
    >
      {src ? (
        <img alt={alt} src={src} height={24} width={24} />
      ) : (
        <div style={flagStyles.nameCircle}>
          <span>{name.toUpperCase()}</span>
        </div>
      )}
    </div>
  );
};

export function AdministratorProfilePage(props) {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState();
  const [permissions, setPermissions] = useState();
  const [permissionsFiltered, setPermissionsFiltered] = useState();
  const [selectedPermission, setSelectedPermission] = useState();
  const [permissionToExclude, setPermissionToExclude] = useState();
  const [openPermissionModal, setOpenPermissionModal] = useState();
  const [isFilterShown, toggleFilter] = useState(false);
  const [permissionFilter, setPermissionFilter] = useState({
    administrator: "",
    permission_cooperative: "",
  });
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);
  const setSidebarChildren = useContext(SidebarContext);
  const [editingAllowedActions, setEditingAllowedActions] = useState({
    id: null,
    actions: [],
  });
  const [editingCooperatives, setEditingCooperatives] = useState({
    id: null,
    cooperatives: [],
  });
  const locale = localeUtils.getLocale();

  useEffect(() => {
    async function fetchData() {
      const p = await Promise.all([
        application.get(
          `${administratorsGetByIdentification(
            props.match.params.id
          )}?cachetick=${Math.random()}`
        ),
        application.get(`${permissionsAll()}?cachetick=${Math.random()}`),
      ]);

      setLoading(false);

      const user = p[0].data;
      setProfile({
        _id: user._id,
        photoLink: user.photoLink,
        identification: user.identification,
        identificationMasked: variables.COMPANY
          ? user.identification
          : new StringMask(maskPattern.cpf).apply(user.identification),
        name: user.name,
        email: user.emails ? user.emails[0] : undefined,
        permissions: user.permissions,
        phone: user.phone,
        area: user.area,
        groups: user.groups,
        role: user.role,
      });
      setPermissions(p[1].data);
      setPermissionsFiltered(p[1].data);
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (!profile) {
      return;
    }
    setSections();
  }, [profile]);

  useEffect(() => {
    function filterPermission() {
      const hasFilter =
        !!permissionFilter.administrator ||
        !!permissionFilter.permission_cooperative;
      if (!hasFilter) {
        setPermissionsFiltered(permissions);
        return;
      }

      const filterAdministrator =
        permissions &&
        permissionFilter.administrator &&
        permissions.filter((item) => {
          const hasMatch = item.name
            .toLowerCase()
            .match(permissionFilter.administrator.toLowerCase());
          return hasMatch && hasMatch.length;
        });
      const filterPermissions =
        permissions &&
        permissionFilter.permission_cooperative &&
        permissions.filter((item) => {
          const filterActions = item.actions.filter((action) => {
            const hasMatch = action
              .toLowerCase()
              .match(permissionFilter.permission_cooperative.toLowerCase());
            return hasMatch && hasMatch.length;
          });
          return filterActions.length;
        });
      const result = _.union(
        filterAdministrator || [],
        filterPermissions || []
      );
      (result.length || hasFilter) && setPermissionsFiltered(result);
    }

    filterPermission();
  }, [permissionFilter]);

  async function setSections() {
    setBreadcrumbRightContent({
      display: [profile.name],
      backButton: true,
      rightContent: (
        <button
          className="btn btn-light green-text px-5"
          onClick={() => setOpenPermissionModal(true)}
        >
          <FormattedMessage id="settings.add_access_permission" />
        </button>
      ),
    });

    setTimeout(() => {
      setSidebarChildren(
        <div className="d-flex flex-column align-items-center">
          <div className="py-3 pb-4">
            <UserImage src={profile.photoLink} size={56} />
          </div>
          <b className="pt-3">
            <FormattedMessage id="common.name" />
          </b>
          <span>{profile.name}</span>
          <b className="pt-3">
            <FormattedMessage id="common.email" />
          </b>
          <span>{profile.email}</span>
          {variables.COMPANY ? (
            <>
              <b className="pt-3">
                <FormattedMessage id="common.recognition" />
              </b>

              <span>{profile.identification}</span>
            </>
          ) : (
            <>
              <b className="pt-3">
                <FormattedMessage id="common.document" />
              </b>
              <span>{profile.identificationMasked}</span>
            </>
          )}
          <b className="pt-3">
            <FormattedMessage id="common.phone_number" />
          </b>
          <span>{profile.phone}</span>
          <b className="pt-3">
            <FormattedMessage id="common.sector" />
          </b>
          <span>{profile.area}</span>
          <b className="pt-3">
            <FormattedMessage id="common.groups" />
          </b>
          {(profile.groups || []).map((group) => (
            <div key={group}>
              <span>{group}</span>
            </div>
          ))}
          <b className="pt-3">
            <FormattedMessage id="common.role" />
          </b>
          <span>{profile.role}</span>
          <div className="p-4 w-100">
            <Link to={`/settings/edit-administrator/${profile.identification}`}>
              <button className="btn btn-outline-success w-100 mt-4">
                <FormattedMessage id="common.edit" />
              </button>
            </Link>
          </div>
          <div className="p-4 w-100">
            <Link
              to={`/settings/edit-pass-administrator/${profile.identification}`}
            >
              <button className="btn btn-danger w-100 mt-4">
                <FormattedMessage id="common.change_password" />
              </button>
            </Link>
          </div>
          <hr />
          <div className="p-4 w-100" style={styles.languageContainer}>
            <h6 className="pb-2">
              <FormattedMessage id="footer.languages.title" />
            </h6>
            <div className="d-flex justify-content-center my-3 flex-d-row">
              {Object.keys(localeUtils.langs).map((langKey) => {
                const lang = localeUtils.langs[langKey];
                return (
                  <FlagItem
                    key={langKey}
                    name={langKey}
                    src={lang.img}
                    alt={lang.alt}
                    locale={locale}
                  />
                );
              })}
            </div>
            <span>{localeUtils.langs[locale].dsc}</span>
          </div>
        </div>
      );
    }, 10);

    return () => {
      setSidebarChildren(<SettingsSidebar />);
    };
  }

  async function handleDeletePermission(permission) {
    if (!permission) {
      return;
    }
    setLoading(true);
    const newPermissions = (profile.permissions || []).filter((item) => {
      return item._id !== permission._id;
    });
    await application.put(
      administratorsUpdatePermissions(props.match.params.id),
      newPermissions.map((el) => el._id)
    );
    setLoading(false);
    setPermissionToExclude();
    setProfile({
      ...profile,
      permissions: newPermissions,
    });
  }

  async function handlePermissionsToAdd() {
    setLoading(true);
    if (!profile.permissions) {
      profile.permissions = [];
    }
    profile.permissions.push(selectedPermission);
    await application.put(
      administratorsUpdatePermissions(props.match.params.id),
      {
        permissions: profile.permissions.map((el) => el._id),
      }
    );
    setSelectedPermission();
    setLoading(false);
    setOpenPermissionModal(false);
  }

  function openEditActions(id, actions) {
    setEditingAllowedActions({ id, actions });
  }

  function openEditCooperatives(id, cooperatives) {
    setEditingCooperatives({
      id,
      cooperatives,
      all: cooperatives.every((cooperative) => cooperative.selected),
    });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="child-svg-color-1">
      <Table
        columns={[
          {
            title: <FormattedMessage id="settings.permission_name" />,
            field: "name",
            disableClick: true,
            sorting: false,
          },
          {
            title: <FormattedMessage id="settings.allowed_actions" />,
            field: "actions",
            sorting: false,
            render: (rowData) => (
              <div style={styles.actionsRowContainer}>
                {rowData.actions &&
                  rowData.actions.map((action) => {
                    return (
                      <p key={action}>
                        <FormattedMessage id={`permissions.${action}`} />
                      </p>
                    );
                  })}
              </div>
            ),
          },
          {
            title: "",
            field: "delete_action",
            render: (rowData) => (
              <div className="text-right pr-2">
                <button
                  className="btn"
                  onClick={() => setPermissionToExclude(rowData)}
                >
                  <DeleteIcon />
                </button>
              </div>
            ),
          },
        ]}
        data={profile && profile.permissions ? profile.permissions : []}
        paging={false}
        isLoading={loading}
      />
      <Modal
        isOpen={editingAllowedActions.id !== null}
        padding={0}
        onRequestClose={() =>
          setEditingAllowedActions({ id: null, actions: [] })
        }
      >
        <FormControl
          values={(editingAllowedActions.actions || []).reduce(
            (result, action) =>
              Object.assign(result, (result[action.name] = action.activated)),
            {}
          )}
          onChange={(values) => console.log(values)}
        >
          <div className="d-flex align-items-center justify-content-between p-4 border-bottom">
            <b>
              <FormattedMessage id="settings.allowed_actions" />
            </b>
            <button className="btn btn-success">
              <FormattedMessage id="common.confirm" />
            </button>
          </div>
          <div className="p-4">
            {(editingAllowedActions.actions || []).map((action, i) => (
              <ToggleField
                labelText={action.name}
                controlName={action.name}
                key={`editingAllowedActions-${i}`}
                spaceBetween={true}
              />
            ))}
          </div>
        </FormControl>
      </Modal>

      <Modal
        isOpen={editingCooperatives.id !== null}
        padding={0}
        onRequestClose={() =>
          setEditingCooperatives({ id: null, cooperatives: [] })
        }
      >
        <FormControl
          values={(editingCooperatives.cooperatives || []).reduce(
            (result, cooperative) =>
              Object.assign(
                result,
                (result[cooperative._id] = cooperative.selected)
              ),
            {}
          )}
        >
          <div className="d-flex align-items-center justify-content-between p-4 border-bottom">
            <b>
              <FormattedMessage id="common.cooperative" />
            </b>
            <button className="btn btn-success">
              <FormattedMessage id="common.confirm" />
            </button>
          </div>
          <div className="p-4">
            <CheckboxField labelText={<FormattedMessage id="common.all" />} />
            <div className="pl-5 pt-2">
              {(editingCooperatives.cooperatives || []).map(
                (cooperative, i) => (
                  <CheckboxField
                    labelText={cooperative.name}
                    controlName={cooperative._id}
                    key={`editingCooperatives-${i}`}
                  />
                )
              )}
            </div>
          </div>
        </FormControl>
      </Modal>

      <Modal
        isOpen={permissionToExclude}
        padding={0}
        onRequestClose={() => setPermissionToExclude()}
        onConfirm={() => handleDeletePermission(permissionToExclude)}
      >
        <div className="d-flex flex-column align-items-center text-center">
          <div
            className="px-3 py-2 border-radius mb-5"
            style={{ backgroundColor: "#f5f6fa", fill: "#657fa0" }}
          >
            <InfoIcon />
          </div>
          <h6 className="text-bold">
            <FormattedMessage id="permissions.unlink_user_permission" />
          </h6>
          <div className="py-4">
            {permissionToExclude ? permissionToExclude.name : ""}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={openPermissionModal}
        padding={0}
        onRequestClose={() => setOpenPermissionModal(false)}
        onConfirm={handlePermissionsToAdd}
        size="medium"
      >
        <div
          className="align-items-center d-flex flex-row justify-content-between pb-4 text-center"
          style={{
            backgroundColor: "rgba(210, 213, 218, 0.22)",
            margin: "-32px -32px 32px -32px",
            padding: 16,
          }}
        >
          {!isFilterShown && (
            <h6 className="text-bold">
              <FormattedMessage id="permissions.link_access_permission" />
            </h6>
          )}
          {isFilterShown && (
            <FormControl
              values={permissionFilter}
              onChange={(values) => setPermissionFilter(values)}
              onSubmit={() => console.debug("Submitting filter")}
            >
              <div className="d-flex flex-row align-items-center justify-content-around">
                <InputField
                  controlName="administrator"
                  placeholder={
                    <FormattedMessage id="permissions.administrator" />
                  }
                />
                <InputField
                  controlName="permission_cooperative"
                  placeholder={
                    <FormattedMessage id="permissions.permission_cooperative" />
                  }
                />
              </div>
            </FormControl>
          )}
          <button
            className="btn btn-outline-primary"
            onClick={() => toggleFilter(!isFilterShown)}
          >
            {isFilterShown ? (
              <IconClose fill="#41B049" />
            ) : (
              <IconFilter fill="#41B049" />
            )}
          </button>
        </div>
        <div className="d-flex flex-row">
          {permissionsFiltered &&
            permissionsFiltered.map((permission) => {
              return (
                <div
                  key={`permissionsFiltered-${permission}`}
                  onClick={() => setSelectedPermission(permission)}
                  style={
                    permission === selectedPermission
                      ? styles.selectedPermissionBox
                      : styles.permissionBox
                  }
                >
                  <div>
                    <div>
                      <span
                        style={{
                          color: "#3D5879",
                          fontSize: 16,
                          fontWeight: "500",
                        }}
                      >
                        <FormattedMessage id="permissions.name" />
                      </span>
                    </div>
                    <div
                      style={{
                        color: "#657FA0",
                        fontSize: 14,
                        marginBottom: 16,
                      }}
                    >
                      {permission.name}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        style={{
                          color: "#3D5879",
                          fontSize: 16,
                          fontWeight: "500",
                        }}
                      >
                        <FormattedMessage id="permissions.allowed_actions" />
                      </span>
                    </div>
                    <div
                      style={{
                        marginBottom: 16,
                      }}
                    >
                      {(permission.actions || []).map((el) => {
                        return (
                          <div key={`permission-actions-${el}`}>
                            <FormattedMessage id={`permissions.${el}`} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Modal>
    </div>
  );
}
