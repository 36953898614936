import React from "react";
import { FormattedMessage } from "react-intl";
import { IconWithText } from "../../components/IconWithText";
import { ReactComponent as ConfigIcon } from "../../assets/svg-icons/Icon-config.svg";

export function SettingsSidebar() {
  return (
    <div>
      <FormattedMessage id="userinfo.settings">
        {(text) => {
          return <IconWithText icon={<ConfigIcon />} text={text} />;
        }}
      </FormattedMessage>
    </div>
  );
}
