import React, { createContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const FormContext = createContext({});

export function FormControl({children, onSubmit, onChange = () => null, values = {}}) {
  const [formState, setFormState] = useState({});

  function handleInput(input) {
    const { name, value } = input;
    const formStateCopy = {...formState};

    formStateCopy[name] = value;

    setFormState(formStateCopy);
  }

  useEffect(() => {
    onChange(formState);
  }, [formState]);

  useEffect(() => {
    if (JSON.stringify(values) !== '{}') {
      setFormState(values);
    }
  }, [values]);

  return (
    <FormContext.Provider value={{formState, handleInput}}>
      <form onSubmit={onSubmit}>
        {children}
      </form>
    </FormContext.Provider>
  )
}

FormControl.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  values: PropTypes.object
};
