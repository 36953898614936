import React, { useContext, useEffect, useState } from "react";
import { NavLink, Redirect, Route, Switch, withRouter } from "react-router-dom";
import { SidebarContext } from "../../components/layouts/Main/Main";
import { IconWithText } from "../../components/IconWithText";
import { application } from "../../helpers/axiosModules";
import { FormattedMessage } from "react-intl";
import { UserImage } from "../../components/UserImage";
import PropTypes from "prop-types";
import { Modal } from "../../components/Modal";
import { ReactComponent as QualityIcon } from "../../assets/svg-icons/Icon-quality.svg";
import { ReactComponent as VolumeIcon } from "../../assets/svg-icons/Icon-graphic.svg";
import { ReactComponent as TruckIcon } from "../../assets/svg-icons/Icon-truck.svg";
import { ReactComponent as PropertyIcon } from "../../assets/svg-icons/Icon-property.svg";
import { ReactComponent as PhoneIcon } from "../../assets/svg-icons/Icon-phone-msg.svg";
import { QualitySection } from "./sections/QualitySection";
import { VolumeSection } from "./sections/VolumeSection";
import { CollectSection } from "./sections/CollectSection";
import { InvoiceSection } from "./sections/InvoiceSection";
import { PropertySection } from "./sections/PropertySection";
import { ContactSection } from "./sections/ContactSection";
import { cpfMask } from "../../utils/Formatter";
import { userGet } from "../../api/routes-app";

function HandleProducer({ match, onProducerChange = () => null }) {
  const [producer, setProducer] = useState([]);
  const [open, setOpen] = useState(false);

  const setSidebarChildren = useContext(SidebarContext);

  useEffect(() => {
    application
      .get(
        `${userGet()}?registration=${
          match.params.id
        }&cachetick=${Math.random()}`
      )
      .then(({ data }) => {
        const producer = {
          name: data.name,
          image_url: data.profile ? data.profile.photoLink : null,
          cooperative: "",
          registration: "",
          line: "",
          identification: data.identification,
        };
        setProducer(producer);
        onProducerChange(producer);
        setSidebarChildren(
          <div>
            <div className="d-flex flex-column align-items-center border-bottom p-3 pb-4">
              <div className="mb-4">
                <UserImage src={producer.image_url} size={58} />
              </div>
              <b>
                <FormattedMessage id="common.name" />:
              </b>
              <div>{producer.name}</div>
              <a
                href="#"
                className="green"
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(true);
                }}
              >
                <FormattedMessage id="producers.view_profile" />
              </a>
            </div>
            <div className="py-5 px-4">
              <b>
                <FormattedMessage id="producers.select_the_desired_item" />:
              </b>
              <NavLink to={`/producers/${match.params.id}/quality`}>
                <IconWithText
                  icon={<QualityIcon />}
                  text={<FormattedMessage id="producers.links.quality" />}
                  reset={true}
                  className="my-3"
                />
              </NavLink>
              <NavLink to={`/producers/${match.params.id}/volume`}>
                <IconWithText
                  icon={<VolumeIcon />}
                  text={<FormattedMessage id="producers.links.volume" />}
                  reset={true}
                  className="my-3"
                />
              </NavLink>
              <NavLink to={`/producers/${match.params.id}/collect`}>
                <IconWithText
                  icon={<TruckIcon />}
                  text={<FormattedMessage id="producers.links.collect" />}
                  reset={true}
                  className="my-3"
                />
              </NavLink>
              <NavLink to={`/producers/${match.params.id}/property`}>
                <IconWithText
                  icon={<PropertyIcon />}
                  text={<FormattedMessage id="producers.links.property" />}
                  reset={true}
                  className="my-3"
                />
              </NavLink>
              <NavLink to={`/producers/${match.params.id}/invoice`}>
                <IconWithText
                  icon={<QualityIcon />}
                  text={"Notas fiscais"}
                  reset={true}
                  className="my-3"
                />
              </NavLink>
              <NavLink to={`/producers/${match.params.id}/contacts`}>
                <IconWithText
                  icon={<PhoneIcon />}
                  text={<FormattedMessage id="producers.links.contacts" />}
                  reset={true}
                  className="my-3"
                />
              </NavLink>
            </div>
          </div>
        );
      });

    return () => {
      onProducerChange(null);
    };
  }, []);

  console.debug("producer: ", producer);
  return (
    <>
      <div className="card">
        <Switch>
          <Route
            exact
            path="/producers/:id"
            render={(props) => (
              <Redirect to={`${props.match.params.id}/quality`} />
            )}
          ></Route>
          <Route path="/producers/:id/quality">
            <QualitySection id={match.params.id} />
          </Route>
          <Route path="/producers/:id/volume">
            <VolumeSection id={match.params.id} />
          </Route>
          <Route path="/producers/:id/collect">
            <CollectSection id={match.params.id} />
          </Route>
          <Route path="/producers/:id/invoice">
            <InvoiceSection id={match.params.id} />
          </Route>
          <Route path="/producers/:id/property">
            <PropertySection id={match.params.id} />
          </Route>
          <Route path="/producers/:id/contacts">
            <ContactSection id={match.params.id} />
          </Route>
        </Switch>
      </div>
      <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
        <div
          style={{
            overflowX: "unset",
            overflowY: "auto",
            maxHeight: "calc(80vh - 60px)",
          }}
        >
          <div className="d-flex flex-row">
            <div className="flex-fill">
              <UserImage src={producer.image_url} size={120} />
              <a href="/producers" className="d-block green mt-4 mx-auto w-50">
                <FormattedMessage id="common.change_user_profile" />
              </a>
            </div>
            <div className="flex-fill border-left pl-4">
              <div className="d-flex flex-column mb-4">
                <b>
                  <FormattedMessage id="common.name" />:
                </b>
                <span>{producer.name}</span>
              </div>
              <div className="d-flex flex-column mb-4">
                <b>
                  <FormattedMessage id="common.cooperative" />:
                </b>
                <span>{producer.cooperative || "230 - Coopernorte"}</span>
              </div>
              <div className="d-flex flex-row mb-4">
                <div className="flex-fill">
                  <b>
                    <FormattedMessage id="common.registration" />:
                  </b>
                  <div>{producer.registration || "0449"}</div>
                </div>
                <div className="flex-fill">
                  <b>
                    <FormattedMessage id="common.line" />:
                  </b>
                  <div>{producer.line || "60"}</div>
                </div>
              </div>
              <div className="d-flex flex-column mb-4">
                <b>
                  <FormattedMessage id="common.identification" />:
                </b>
                <span>
                  {(producer.identification &&
                    cpfMask(producer.identification)) ||
                    "233.140.848/91"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

HandleProducer.propTypes = {
  onProducerChange: PropTypes.func,
};

export const HandleProducerWithRouter = withRouter(HandleProducer);
