const translation = {
  label_month_jan: 'Janeiro',
  label_month_fev: 'Fevereiro',
  label_month_mar: 'Março',
  label_month_apr: 'Abril',
  label_month_may: 'Maio',
  label_month_jun: 'Junho',
  label_month_jul: 'Julho',
  label_month_aug: 'Agosto',
  label_month_sep: 'Setembro',
  label_month_oct: 'Outubro',
  label_month_nov: 'Novembro',
  label_month_dec: 'Dezembro',
};

export function getMonths() {
  const months = [];

  months.push({
    label: translation.label_month_jan,
    value: 0
  });
  months.push({
    label: translation.label_month_fev,
    value: 1
  });
  months.push({
    label: translation.label_month_mar,
    value: 2
  });
  months.push({
    label: translation.label_month_apr,
    value: 3
  });
  months.push({
    label: translation.label_month_may,
    value: 4
  });
  months.push({
    label: translation.label_month_jun,
    value: 5
  });
  months.push({
    label: translation.label_month_jul,
    value: 6
  });
  months.push({
    label: translation.label_month_aug,
    value: 7
  });
  months.push({
    label: translation.label_month_sep,
    value: 8
  });
  months.push({
    label: translation.label_month_oct,
    value: 9
  });
  months.push({
    label: translation.label_month_nov,
    value: 10
  });
  months.push({
    label: translation.label_month_dec,
    value: 11
  });
  return months;
}
