import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import { IconWithText } from '../../../components/IconWithText';
import { CronUI } from '../../../utils/Cron';
import {ReactComponent as DeleteIcon} from '../../../assets/svg-icons/Icon-trash-small.svg';

export function CustomFrequency({notification}) {
  const [frequencies, setFrequencies] = useState(notification.frequency);

  useEffect(() => {
    if(frequencies) {
      frequencies.map((freq, index) => {
        const el = document.querySelector(`#cron_${index}`);
        if (!el.hasChildNodes()) {
          new CronUI(`#cron_${index}`, {
            initial: freq,
            changeEvent: (cronString) => {
              if(frequencies) {
                frequencies[index] = cronString;
              }
              notification.frequency = frequencies;
            }
          });
        }
      });
    }
  }, [frequencies]);

  return (
    <div>
      <h5><FormattedMessage id='settings.custom_frequency'/></h5>
      <div className='border-top'>
        {(frequencies) ? frequencies.map((freq, index) => {
          return (
            <div>
              Executar todo(a) <span id={`cron_${index}`}></span>
              <span className='text-right pr-2'>
                <button className='btn' onClick={(e) => {
                  e.preventDefault();
                  const newFreqs = JSON.parse(JSON.stringify(frequencies));
                  newFreqs.splice(index, 1);
                  setFrequencies(newFreqs);
                }}>
                  <DeleteIcon/>
                </button>
              </span>
            </div>
          );
        }) : null}
        <button
            type='button'
            onClick={(e) => {
              e.preventDefault();
              if(!frequencies) {
                setFrequencies(["* * * * * *"]);
              } else {
                frequencies.push("* * * * * *");
                setFrequencies(JSON.parse(JSON.stringify(frequencies)));
              }
            }}
            className='p-3 my-2 mb-3 d-flex align-items-center justify-content-center border-dashed border bg-white w-100'
          >
            <span className='green-text'>
              <IconWithText icon='' text={<FormattedMessage id='settings.add_frequency'/>} reset={true}/>
            </span>
        </button>
      </div>
    </div>
  )
}
