import React, {useState, createContext, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { ReactComponent as BackIcon } from '../assets/svg-icons/Icon-baseline-keyboard_backspace-white.svg';
import { ReactComponent as IconFilter } from '../assets/svg-icons/Icon-filter.svg';
import { ReactComponent as IconCloseGreen } from '../assets/svg-icons/Icon-close.svg';
import { ReactComponent as IconSearch } from '../assets/svg-icons/Icon-search.svg';

export const BreadcrumbContext = createContext(() => undefined);

function BreadCrumbComponent({
  history,
  limitPaths,
  display,
  backButton,
  backTo,
  rightContent,
  filter,
  filterIcon,
  resetTableSearchAndFilter,
  onSearch,
  searchPlaceHolder
}) {
  const { pathname } = history.location;
  const crumbs = pathname.split('/').filter(a => a);
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const searchInputRef = useRef(null);

  useEffect(() => {
    setFilterOpen(false);
  }, [pathname]);

  return (
    <div className='bg-color-3 border-radius mb-4'>
      <nav aria-label='breadcrumb'>
        <ol className='breadcrumb align-items-center mb-0' style={{position: 'relative'}}>
          {backButton && (
            <button className='btn py-0 pl-1 mr-3' onClick={() => backTo? history.push(backTo) : history.goBack()}>
              <BackIcon style={{fill: 'white'}}/>
            </button>
          )}
          {(display || crumbs).slice(0, limitPaths || crumbs.length).map((crumb, i) => (
            <li className={`breadcrumb-item ${crumbs.length === i + 1 ? 'active' : ''}`} aria-current={crumb} key={i}>
              {(!display && isNaN(Number(crumb))) && <FormattedMessage id={`breadcrumb.${crumb}`} />}
              {display  && crumb}
            </li>
          ))}
          <div className='d-flex px-5 child-svg-color-1' style={{position: 'absolute', right: 0}}>
            {onSearch && (
              <div
                className='btn btn-light mr-3 p-0'
                style={{
                  position: 'relative',
                  width: searchOpen ? 400 : 40,
                  height: 40,
                  transition: 'all .15s',
                  overflow: 'hidden'
                }}
              >
                <IconSearch style={{position: 'absolute', top: '30%', left: 12, pointerEvents: 'none'}}/>
                <input
                  ref={searchInputRef}
                  className='text-left w-100 h-100 no-focus'
                  onFocus={() => setSearchOpen(true)}
                  onBlur={() => setSearchOpen(!!searchInputRef.current.value)}
                  onChange={(e) => onSearch(e.target.value)}
                  style={{border: 'none', background: 'transparent', paddingLeft: 42, ':focus': {outline: 'none'}}}
                  placeholder={searchPlaceHolder}
                />
              </div>
            )}
            {filter && (
              <button className='btn btn-light mr-3' onClick={() => {
                setFilterOpen(!filterOpen);
                filterOpen && resetTableSearchAndFilter && resetTableSearchAndFilter();
              }}>
                {filterOpen ? <IconCloseGreen/> : filterIcon || <IconFilter fill="#41B049" />}
              </button>
            )}
            {rightContent && (
              <div>
                {rightContent}
              </div>
            )}
          </div>
        </ol>
      </nav>
      <div style={{maxHeight: filterOpen ? 100 : 0, transition: 'all .15s'}}>
        <div
          className='px-5 py-3 d-flex align-items-center'
          style={{
            opacity: filterOpen ? 1 : 0,
            transition: 'all .15s',
            pointerEvents: filterOpen ? 'all' : 'none',
            color: '#fff'
          }}
        >
          <div className='py-3 pr-4'>
            <IconFilter fill="#fff" />
          </div>
          {filter}
        </div>
      </div>
    </div>
  )
}

BreadCrumbComponent.propTypes = {
  history: PropTypes.any.isRequired,
  limitPaths: PropTypes.number,
  display: PropTypes.array,
  backButton: PropTypes.bool,
  backTo: PropTypes.string,
  rightContent: PropTypes.any,
  filter: PropTypes.any,
  filterIcon: PropTypes.any,
  onSearch: PropTypes.func
};

export const BreadCrumb = withRouter(BreadCrumbComponent);
