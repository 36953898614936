import React from 'react';
import PropTypes from 'prop-types';

const style = { marginRight: '-1rem', marginLeft: '-1rem', paddingTop: '.5rem', paddingBottom: '1.5rem' };

export function CheckedTabsWrapper({children}) {
  return (
    <div className='d-flex justify-content-between' style={style}>
      {children}
    </div>
  );
}

CheckedTabsWrapper.propTypes = {
  children: PropTypes.any.isRequired
};
