import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';
import defaultSrc from '../assets/svg-icons/Icon-profile.svg';

const defaultSize = 28;

function Parent({tooltip, children}) {
  if (tooltip) {
    return (
      <Tooltip content={tooltip} background="#1d2e44" color="#fff" distance={12}>
        {children}
      </Tooltip>
    );
  }

  return (
    <>{children}</>
  )
}

export function UserImage({src, alt = '', tooltip, size}) {

  const style = {
    borderRadius: size || defaultSize,
    height: size || defaultSize,
    width: size || defaultSize,
    margin: 'auto',
    display: 'block'
  };

  return (
    <Parent tooltip={tooltip}>
      <div>
        <img style={style} alt={alt} src={src || defaultSrc}/>
      </div>
    </Parent>
  )
}

UserImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  tooltip: PropTypes.string,
  size: PropTypes.number
};
