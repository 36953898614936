import React from "react";
import {ReactComponent as IconCowRed} from "../../assets/svg-icons/Icon-cow-red.svg";
import {ReactComponent as IconCowYellow} from "../../assets/svg-icons/Icon-cow-yellow.svg";
import {ReactComponent as IconCowGreen} from "../../assets/svg-icons/Icon-cow-green.svg";
import {ReactComponent as IconCowStar} from "../../assets/svg-icons/Icon-cow-star.svg";

const styles = {
  wrapper: {
    marginRight: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    width: 23,
    height: 23
  },
  label: {
    fontSize: 14,
    color: '#657fa0',
    marginLeft: 10,
  }
};

const QualityMonthlyLegend = () => (
  <div>
    <div className="d-flex flex-row justify-content-between">
      <div style={styles.wrapper}>
        <IconCowRed style={styles.icon}/>
        <span style={styles.label}>Desconto</span>
      </div>
      <div style={styles.wrapper}>
        <IconCowYellow style={styles.icon}/>
        <span style={styles.label}>
          Neutro
        </span>
      </div>
      <div style={styles.wrapper}>
        <IconCowGreen style={styles.icon}/>
        <span style={styles.label}>
          Bonificação
        </span>
      </div>
      <div style={styles.wrapper}>
        <IconCowStar style={styles.icon}/>
        <span style={styles.label}>
          Bonificação Máxima
        </span>
      </div>
    </div>
  </div>
);

export default QualityMonthlyLegend;
