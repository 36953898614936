import styled, { css } from "styled-components";
import {
  Button,
  makeStyles,
  withStyles,
  Badge,
  Modal,
} from "@material-ui/core";
import { Select, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ChipInput from "material-ui-chip-input";

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const SelectTypePublication = styled(Select).attrs({
  variant: "outlined",
  fullWidth: true,
})`
  && div.MuiInputBase-root {
    max-width: 125px;
  }
`;

export const InputField = styled(TextField).attrs({
  variant: "outlined",
  fullWidth: true,
})``;

export const SubmitButton = styled(Button).attrs({
  type: "submit",
  variant: "contained",
  color: "primary",
  fullWidth: true,
})``;

export const InputTags = styled(ChipInput).attrs({
  variant: "outlined",
  allowDuplicates: false,
  fullWidth: true,
  clearInputValueOnChange: true,
})``;

export const InputAutocomplete = styled(Autocomplete).attrs({
  multiple: true,
  freeSolo: true,
  limitTags: 3,
})``;

export const ContainerRadios = styled.div`
  display: flex;
`;

export const ContainerList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

export const ListCoop = styled.li`
  list-style: none;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

const dragActive = css`
  border-color: #37933c;
`;
const dragReject = css`
  border-color: #e57878;
`;
const messageColors = {
  default: "#657FA0",
  error: "#e57878",
  success: "#37933c",
};

export const DropContainer = styled.div.attrs({
  className: "dropzone",
})`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
  padding: 20px;
  /* margin: 20px 0; */
  height: 55px;
  transition: height 0.2s ease;

  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};
`;

export const UploadMessage = styled.p`
  display: flex;
  color: ${(props) => messageColors[props.type || "default"]};
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
`;

export const Container = styled.ul`
  margin-top: 20px;
  width: 100%;
  padding: 0;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #657fa0;

    & + li {
      margin-top: 15px;
    }
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;

    span {
      font-size: 12px;
      color: #999;

      button {
        border: 0;
        background: transparent;
        color: #e57878;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
`;

export const Preview = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
`;

export const WrapperModal = styled(Modal).attrs({
  hideBackdrop: true,
})`
    position: fixed !important;
    bottom: 5px !important;
    left: 5px !important;
    width: 350px;
    /* z-index: 1300px !important; */
    top: auto !important;
`;

export const useStyles = makeStyles((theme) => ({
  button: {
    height: 55,
    textTransform: "initial",
    color: "#657fa0",
  },
  icon: {
    color: "#37933C",
  },
  inputs: {
    marginBottom: theme.spacing(3),
  },
  autocomplete: {
    display: "flex",
    flexWrap: "wrap",
  },
  media: {
    height: 150,
    maxWidth: 250,
    borderRadius: 5,
    paddingTop: "25.25%", // 16:9
  },
  avatar: {
    marginRight: 5,
    marginLeft: 5,
  },
  typhography: {
    color: "#fff",
    fontSize: 16,
  },
  toSave: {
    color: "#fff",
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
    // maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  label: {
    marginLeft: 14,
    marginTop: -7,
  },
  select: {
    height: 55,
    maxHeight: 200,
    minHeight: "auto",
  },
  outlined_input: {
    display: "flex",
    flexWrap: "wrap",
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(7),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  modal: {
    position: "absolute",
    bottom: 0,
    width: 350,
    zIndex: 1300,
    right: 76,
  },
}));

export const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -15,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    background: "transparent",
    color: "#333",
    fontSize: 16,
  },
}))(Badge);
