import { variables } from "../whitelabel/variables";
const appFunctionsPath = variables.API_URL;

export const administratorsCreate = () => `${appFunctionsPath}/v1/administrators_create`;
export const administratorsGet = () => `${appFunctionsPath}/v1/administrators_get`;
export const administratorsGetById = (id) => `${appFunctionsPath}/v1/administrators/${id}`;
export const administratorsGetByIdentification = (id) => `${appFunctionsPath}/v1/administrators_identification/${id}`;
export const administratorsRemove = (id) => `${appFunctionsPath}/v1/administrators/${id}`;
export const administratorsUpdate = (id) => `${appFunctionsPath}/v1/administrators_update/${id}`;
export const administratorsUpdatePassword = (id) => `${appFunctionsPath}/v1/administrators_password/${id}`;
export const administratorsUpdatePermissions = (id) => `${appFunctionsPath}/v1/administrators_permissions/${id}`;
export const auth = () => `${appFunctionsPath}/v1/auth`;
export const authBackoffice = () => `${appFunctionsPath}/v1/backoffice/auth`;
export const authCheckExpiredVouchers = () => `${appFunctionsPath}/v1/checkexpiredvouchers`;
export const hello = () => `${appFunctionsPath}/hello`;
export const instantNotificationGet = (id) => `${appFunctionsPath}/v1/instant-notification/${id}`;
export const instantNotificationSave = () => `${appFunctionsPath}/v1/instant-notification`;
export const introspection = () => `${appFunctionsPath}/v1/token/introspection`;
export const notificationGet = (id) => `${appFunctionsPath}/v1/notification/${id}`;
export const notificationGetAll = () => `${appFunctionsPath}/v1/notification`;
export const notificationSave = () => `${appFunctionsPath}/v1/notification`;
export const notificationUpdate = (id) => `${appFunctionsPath}/v1/notification/${id}`;
export const notificationRemove = (id) => `${appFunctionsPath}/v1/notification/${id}`;
export const permissionsAll = () => `${appFunctionsPath}/v1/permissions`;
export const permissionsGet = (id) => `${appFunctionsPath}/v1/permissions/${id}`;
export const permissionsRemove = (id) => `${appFunctionsPath}/v1/permissions/${id}`;
export const permissionsSave = () => `${appFunctionsPath}/v1/permissions`;
export const permissionsUpdate = (id) => `${appFunctionsPath}/v1/permissions/${id}`;
export const propertiesAll = () => `${appFunctionsPath}/v1/properties`;
export const propertiesDataGet = (id) => ` ${appFunctionsPath}/v1/properties/${id}`;
export const propertiesUpdate = (id) => `${appFunctionsPath}/v1/properties_update/${id}`;
export const publicationGet = (id) => `${appFunctionsPath}/v1/publication/${id}`;
export const publicationGetAll = () => `${appFunctionsPath}/v1/publication`;
export const publicationGetTitle = () => `${appFunctionsPath}/v1/publication-get-title`;
export const publicationRemove = (id) => `${appFunctionsPath}/v1/publication/${id}`;
export const publicationSave = () => `${appFunctionsPath}/v1/publication`;
export const publicationUpdate = (id) => `${appFunctionsPath}/v1/publication/${id}`;
export const publicationUpdateLike = (id) => `${appFunctionsPath}/v1/publication-update-like/${id}`;
export const supportGet = () => `${appFunctionsPath}/v1/support`;
export const supportPost = () => `${appFunctionsPath}/v1/support`;
export const supportPut = (id) => `${appFunctionsPath}/v1/support/${id}`;
export const userChangePassword = () => `${appFunctionsPath}/v1/users_changepassword`;
export const userGet = () => `${appFunctionsPath}/v1/users`;
export const userGetById = (id) => `${appFunctionsPath}/v1/users/${id}`;
export const usersAll = () => `${appFunctionsPath}/v1/users_all`;
export const usersCount = () => `${appFunctionsPath}/v1/users_count`;
export const userUpdateConfig = () => `${appFunctionsPath}/v1/users_config`;
export const userUpdateProfile = () => `${appFunctionsPath}/v1/users_profile`;
export const validate = () => `${appFunctionsPath}/v1/auth/validate`;

// Total likes on posts for the current week
// Comentário: setar as datas (from e to) para a semana atual
// Exemplo: v1/publication_count_likes?from=2020-07-20T01:54:44.359+00:00&to=2023-07-20T01:54:44.359+00:00
export const getPostLikesAtThisWeek = (fromDate, toDate) => `${appFunctionsPath}/v1/publication_count_likes?from=${fromDate}&to=${toDate}`;

// Total likes on posts for the current month
// Comentário: setar as datas (from e to) para o mês atual
// Exemplo: v1/publication_count_likes?from=2020-07-20T01:54:44.359+00:00&to=2023-07-20T01:54:44.359+00:00
export const getPostLikesAtThisMonth = (fromDate, toDate) => {
  const path = `${appFunctionsPath}/v1/publication_count_likes?from=${fromDate}&to=${toDate}`;
  return path;
};

// Most liked post in the current month
// Comentário: setar as datas (from e to) para o mês atual
// Exemplo: v1/publication_most_liked?from=2020-07-20T01:54:44.359+00:00&to=2023-07-20T01:54:44.359+00:00&limit=1
export const getMostLikedPostAtThisMonth = (fromDate, toDate) => `${appFunctionsPath}/v1/publication_most_liked?from=${fromDate}&to=${toDate}&limit=1`;

// 20 users who most liked posts created in the current month
// Exemplo: v1/publication_users_who_most_liked?from=2020-07-20T01:54:44.359+00:00&to=2023-07-20T01:54:44.359+00:00
// Resultado: array de users (identification e name)
export const getUsersThatLikedPostsCreatedAtThisMonth = (fromDate, toDate) => `${appFunctionsPath}/v1/publication_users_who_most_liked?from=${fromDate}&to=${toDate}`;

// Users who most interacted with the app this month
// functions/interaction-events_handler.filterInteraction
// Exemplo: v1/interactions_filter?from=2020-07-20T01:54:44.359+00:00&to=2023-07-20T01:54:44.359+00:00

// Users who used quality data the most this month
// functions/interaction-events_handler.filterInteraction
// Exemplo: v1/interactions_filter?from=2020-07-20T01:54:44.359+00:00&to=2023-07-20T01:54:44.359+00:00&screenName=quality
