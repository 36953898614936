import { communication } from "../../helpers/axiosModules";
import * as _ from 'lodash';

async function all() {
  const { data } = await communication.get('/notificationsreg');
  return data;
}

async function sendNotification() {
  const { data } = await communication.post('/notificationsreg/send');
  return data;
}

async function get(id) {
  const { data } = await communication.get(`/notificationsreg/${id}?cachetick=${Math.random()}`);
  return data;
}

async function save(notification, type) {
  let path = '/notificationsreg';

  const method = notification._id ? 'put' : 'post';

  if (method === 'put') {
    path += `/${notification._id}`;
  }

  return await communication[method](path, _.assign({ type }, notification)).data;
}

export default { all, get, save, sendNotification };
