import * as _ from 'lodash';

export default class SafeValueUtils {
  static getCooperative(cooperativeCode) {
    if(!cooperativeCode) {
      return '-';
    }
    if(!window.cooperatives) {
      return '-';
    }
    const value = window.cooperatives.find(el => parseInt(el.value) === parseInt(cooperativeCode));
    if(!value || !value.name) {
      return '-';
    }
    return value.name;
  }

  static getMax(list, property) {
    let higherValue = {key: '-', value: 0};
    const ans1 = _.countBy(list, property);
    Object.values(ans1).forEach((value, index) => {
      if(value >= higherValue.value) {
        higherValue = {key: Object.keys(ans1)[index], value: value}
      }
    });
    return higherValue;
  }
}
