import React, { useContext, useState, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { SidebarContext } from "../../components/layouts/Main/Main";
import { BreadCrumb, BreadcrumbContext } from "../../components/BreadCrumb";
import { DashboardSidebar } from "./DashboardSidebar";
import Dashboard from "./Dashboard";
import { Box } from "@material-ui/core";

function DashboardWrapper() {
  const history = useHistory();

  const setSidebarChildren = useContext(SidebarContext);
  const [breadcrumb, setBreadcrumb] = useState({
    filter: null,
    filterIcon: null,
    onSearch: null,
  });

  useEffect(() => {
    setSidebarChildren(<DashboardSidebar />);
  }, []);

  return (
    <BreadcrumbContext.Provider value={setBreadcrumb}>
      <BreadCrumb {...breadcrumb} />
      <Box component="div">
        <Switch>
          <Route history={history} exact path="/dashboard">
            <Dashboard />
          </Route>
        </Switch>
      </Box>
    </BreadcrumbContext.Provider>
  );
}
export { DashboardWrapper };
