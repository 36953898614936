import React from "react";
import {ReactComponent as IconQuality} from "../../assets/svg-icons/Icon-list-check.svg";
import {ReactComponent as IconVolume} from "../../assets/svg-icons/Icon-graphic.svg";
import {ReactComponent as IconCollect} from "../../assets/svg-icons/Icon-truck.svg";

const noDataContainerBg = '#fff';
const iconColor = '#1F8CC7';
const iconContainerBg = 'rgb(239, 247, 251)';
const textContentColor = 'rgb(111, 135, 166)';

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    zIndex: 3,
    backgroundColor: noDataContainerBg,
    maxWidth: 300,
    height: 130,
    padding: 25,
    borderRadius: 6,
    boxShadow: '0 7px 20px #657fa0cc',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    borderRadius: 6,
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: iconContainerBg,
  },
  icon: {
    fill: iconColor,
    width: 26,
    height: 26,
  },
  content: {
    textAlign: 'left',
    color: textContentColor,
    margin: '0 15px',
  },
  boldText: {
    fontWeight: 'bold',
    display: 'inline'
  }
};

const renderIcon = (type) => {
  if (type === 'qualidade') return <IconQuality style={styles.icon}/>;
  if (type === 'volume') return <IconVolume style={styles.icon}/>;
  if (type === 'temperatura') return <IconCollect style={styles.icon}/>;
};

const ChartNoData = ({ type }) => (
  <div style={styles.container}>
    <div style={styles.iconContainer}>
      { renderIcon(type) }
    </div>
    <div style={styles.content}>
      Sem resultado de
      {' '}
      {(
        <div style={styles.boldText}>
          {type}
        </div>
      )}
      {' '}
      até o momento.
    </div>
  </div>
);

export default ChartNoData;
