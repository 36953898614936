import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SelectField } from '../../../components/form/SelectField';
import { InputField } from '../../../components/form/InputField';
import { FormContext } from '../../../components/form/FormContext';
import { CheckboxField } from '../../../components/form/CheckboxField';
import * as _ from 'lodash';

const id = () => {
  return Math.random().toString(36).substring(7);
};

export function Conditional({name, controlName}) {
  const [conditionals, setConditionals] = useState([]);

  const { formState, handleInput } = useContext(FormContext);

  const updateForm = (_conditionals) => {
    if (controlName) {
      handleInput({name: controlName, value: _conditionals});
    }
  };

  const removeConditional = (i) => {
    const _conditionals = _.filter([...conditionals], (_, idx) => idx !== i);
    updateForm(_conditionals);
    setConditionals(_conditionals);
  };

  const addConditional = () => {
    // id to prevent reuse of state when deleting
    const _conditionals = [...conditionals, {_id: id()}];
    updateForm(_conditionals);
    setConditionals(_conditionals);
  };

  const handleValue = (value, prop, i) => {
    const _conditionals = [...conditionals];

    _conditionals[i][prop] = value;

    setConditionals(_conditionals);

    updateForm(_conditionals);
  };

  useEffect(() => {
    if (formState[controlName]) {
      setConditionals(formState[controlName]);
    }
  }, [formState[controlName]]);

  return (
    <div>
      {conditionals.map((conditional, i) => (
        <div key={conditional._id || i} className='py-4 border-bottom'>
          <div className='d-flex align-items-center'>
            <div className='w-50 d-flex align-items-center justify-content-between pr-4'>
              <FormattedMessage id='settings.if' />
              <strong className='mx-2'>{name}</strong>
              <SelectField
                className='custom-dropdown w-50 mx-2'
                defaultOption={{value: conditional.signal}}
                onSelect={option => handleValue(option.value, 'signal', i)}
                options={[
                  {value: '>'},
                  {value: '=='},
                  {value: '<'}
                ]}
              />
              <FormattedMessage id='settings.value' />
            </div>
            <div className='w-50 d-flex align-items-center'>
              <InputField defaultValue={conditional.value} onChange={value => handleValue(value, 'value', i)} />
              <span className='mx-auto'>
                <FormattedMessage id='settings.and'/>
              </span>
              {/*<SelectField*/}
              {/*  className='ml-4 w-50'*/}
              {/*  defaultOption={conditional.inclusive ?*/}
              {/*    {value: 'true', name: <FormattedMessage id='settings.and'/> } :*/}
              {/*    {value: 'false', name: <FormattedMessage id='settings.or'/> }*/}
              {/*  }*/}
              {/*  onSelect={option => handleValue(Boolean(option.value), 'inclusive', i)}*/}
              {/*  options={[*/}
              {/*    {value: 'true', name: <FormattedMessage id='settings.and'/>},*/}
              {/*    {value: 'false', name: <FormattedMessage id='settings.or'/>}*/}
              {/*  ]}*/}
              {/*/>*/}
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-between mt-3'>
            <div className='d-flex align-items-center w-50'>
              <div className='mx-2 pr-5'>
                <CheckboxField
                  spacing={false}
                  labelText={<FormattedMessage id='settings.partial_result'/>}
                  defaultChecked={conditional.partialResult}
                  onChange={checked => handleValue(checked, 'partialResult', i)}
                />
              </div>
              <div className="mx-2 pr-5">
                <CheckboxField
                  spacing={false}
                  labelText={<FormattedMessage id='settings.finished_result'/>}
                  defaultChecked={!conditional.partialResult}
                  onChange={checked => handleValue(!checked, 'partialResult', i)}
                />
              </div>
            </div>
            <div className='d-flex w-50'>
              {conditionals.length !== (i + 1) && (
                <button type='button' className='btn btn-outline-secondary btn-lg w-100 text-lowercase' onClick={() => removeConditional(i)}>
                  <FormattedMessage id='common.delete'/>
                </button>
              )}
              {conditionals.length === (i + 1) && (
                <button type='button' className='btn btn-outline-success btn-lg w-100' onClick={() => addConditional()}>
                  <FormattedMessage id='settings.add_conditional'/>
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

Conditional.propTypes = {
  name: PropTypes.any,
  controlName: PropTypes.string
};
