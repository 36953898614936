import axios from "axios";
import { variables } from "../whitelabel/variables";

let application = {};
let communication = {};
let data = {};

export function dataAxiosClient() {
  const axiosInstance = axios.create({
    headers: { Authorization: sessionStorage.getItem("jwtoken") },
  });

  return axiosInstance;
}

function setupAxios() {
  application = axios.create({
    timeout: 20000,
    headers: { Authorization: sessionStorage.getItem("jwtoken") },
  });

  application.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.debug(error);
      if (error && error.response) {
        // TODO: access permission
        // TODO: timeout exception
        if (error.response.status === 401) {
          window.location.replace("/nopermission");
        } else if (error.response.status === 500) {
          throw new Error(error);
        }
      }
    }
  );

  communication = axios.create({
    baseURL: `${variables.API_URL}/com`,
    timeout: 20000,
    headers: { Authorization: sessionStorage.getItem("jwtoken") },
  });

  communication.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.debug(error);
      if (error && error.response) {
        // TODO: access permission
        // TODO: timeout exception
        if (error.response.status === 401) {
          window.location.replace("/nopermission");
        } else if (error.response.status === 500) {
          window.location.replace("/trylater");
        }
      }
    }
  );

  data = dataAxiosClient();
  data.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.debug(error);
      if (error && error.response) {
        // TODO: access permission
        // TODO: timeout exception
        if (error.response.status === 401) {
          window.location.replace("/nopermission");
        } else if (error.response.status === 500) {
          window.location.replace("/trylater");
        }
      }
    }
  );
}

export { setupAxios, application, communication, data };
