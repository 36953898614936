import React, { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from "./SettingsWrapper";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { SidebarContext } from "../../components/layouts/Main/Main";
import { SettingsSidebar } from "./SettingsSidebar";
import { application } from "../../helpers/axiosModules";
import Loading from "../../components/loading/Loading";
import { FormControl } from "../../components/form/FormContext";
import { InputField } from "../../components/form/InputField";
import { isEmail, isValidCpf } from "../../utils/Validation.utils";
import { Modal } from "../../components/Modal";
import { administratorsCreate } from "../../api/routes-app";

export function AddAdministratorsPage({ history }) {
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);
  const setSidebarChildren = useContext(SidebarContext);
  const [open, setOpen] = useState(false);
  const [accessPass, setAccessPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    setBreadcrumbRightContent({
      display: [<FormattedMessage id="settings.add_administrator" />],
      backButton: true,
    });

    setSidebarChildren(null);

    return () => {
      setSidebarChildren(<SettingsSidebar />);
    };
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    await application
      .post(administratorsCreate(), {
        name: data.name,
        emails: [data.email],
        identification: data.identification
          .replace(".", "")
          .replace("-", "")
          .replace(".", "")
          .replace(" ", ""),
        role: data.role,
        area: data.area,
        phone: data.phone,
        active: true,
      })
      .then((ans) => {
        setLoading(false);
        setAccessPass(ans.data);
        setOpen(true);
      });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="card">
        <FormControl
          onChange={(values) => setData(values)}
          values={data}
          onSubmit={handleSubmit}
        >
          <div className="d-flex p-5 mx-5">
            <div className="col-6 pl-5 ml-5">
              <div className="form-group">
                <InputField
                  maxLength="256"
                  placeholder={<FormattedMessage id="common.name" />}
                  controlName="name"
                  required={true}
                />
              </div>
              <div className="form-group">
                <InputField
                  maxLength="256"
                  placeholder="E-mail"
                  controlName="email"
                  validation={(value) => isEmail(value)}
                  required={true}
                />
              </div>
              <div className="form-group">
                <InputField
                  maxLength="256"
                  placeholder="Identification"
                  controlName="identification"
                  required={true}
                />
              </div>
              <div className="form-group">
                <InputField
                  maxLength="256"
                  placeholder={<FormattedMessage id="common.phone_number" />}
                  controlName="phone"
                  required={true}
                />
              </div>
              <div className="form-group">
                <InputField
                  maxLength="256"
                  placeholder={<FormattedMessage id="common.sector" />}
                  controlName="area"
                  required={true}
                />
              </div>
              <div className="form-group">
                <InputField
                  maxLength="256"
                  placeholder={<FormattedMessage id="common.role" />}
                  controlName="role"
                  required={true}
                />
              </div>
            </div>
            <div className="col-6 pr-5 mr-5">&nbsp;</div>
          </div>
          <div className="border-top p-4 d-flex justify-content-around">
            <Link to={`/settings/administrators`}>
              <button
                className="btn btn-outline-secondary p-4"
                style={{ width: 270 }}
              >
                <FormattedMessage id="common.cancel" />
              </button>
            </Link>
            <button className="btn btn-success p-4" style={{ width: 270 }}>
              <FormattedMessage id="common.confirm" />
            </button>
          </div>
        </FormControl>
      </div>
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        loading={loading}
      >
        <div
          style={{
            overflowX: "unset",
            overflowY: "auto",
            maxHeight: "calc(80vh - 60px)",
          }}
        >
          <h6>
            <FormattedMessage id="common.password" />
          </h6>
          <div className="pt-4 pb-2">{accessPass}</div>
          <div className="pt-5 text-right">
            <button
              className="btn btn-success px-5"
              onClick={() => {
                history.push("/settings/administrators");
              }}
            >
              OK
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
