import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ReactComponent as IconWarning } from "../../assets/svg-icons/Icon-exclamation.svg";

export function TryAgainLater() {
  return (
    <div className="no-permission d-flex flex-column justify-content-center align-content-center">
      <div className="d-flex justify-content-center">
        <IconWarning fill="#ffc866" />
      </div>
      <span
        style={{
          color: "#025481",
          fontWeight: "bold",
          fontSize: 28,
          marginBottom: 10,
          textAlign: "center",
        }}
      >
        <FormattedMessage id="tryagain_later.title" />
      </span>
      <div className="h-100 d-flex flex-column align-items-center mt-5">
        <h1>404</h1>
        <Link to="/dashboard">
          <FormattedMessage id="tryagain_later.action" />
        </Link>
      </div>
    </div>
  );
}
