import Range from "strange";
export const triggerRuleToFormRange = (data) =>
  data.map(({ metric, metricRange }) => {
    const ranges = metricRange.map((e) => {
      const r = Range.parse(
        `${e?.start && !e.start.inclusive ? "(" : "["}${
          e?.start?.value || ""
        },${e?.end?.value || ""}${e?.end && !e.end.inclusive ? ")" : "]"}`
      );
      let condition = "";
      if (r.end === Infinity || !r.end) {
        condition = r.contains(r.begin) ? "GTE" : "GT";
      } else if (r.end === r.begin) {
        condition = "EQ";
      } else if (r.begin === Infinity || !r.begin) {
        condition = r.contains(r.end) ? "LTE" : "LT";
      } else {
        condition = "BETWEEN";
      }
      return {
        condition: `${condition}_${r.bounds}`,
        start: r.begin === Infinity || !r.begin ? undefined : r.begin,
        end: r.end === Infinity || !r.end ? undefined : r.end,
      };
    });
    return { metric, ranges };
  });

export function prepareTriggerRuleData({ triggerRules }) {
  return triggerRules
    ? triggerRules.map(({ ranges, metric }) => {
        const metricRange = ranges.map((e) => {
          const [operation, bounds] = e.condition.split("_");
          const start = e?.start;
          const end = operation === "EQ" ? start : e?.end;
          const range = new Range(start, end, bounds);
          const parseRange = (value) =>
            value === Infinity || !value
              ? null
              : { value, inclusive: range.contains(value) || bounds === "[]" };
          const interv = {
            ...(["GT", "GTE", "EQ", "BETWEEN"].includes(operation)
              ? { start: parseRange(start) }
              : {}),
            ...(["LT", "LTE", "EQ", "BETWEEN"].includes(operation)
              ? { end: parseRange(end) }
              : {}),
          };
          return interv;
        });
        return { metricRange, metric };
      })
    : undefined;
}
