import React from "react";
import { StyledBadge } from "./styles";
import Img from "react-image";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Divider,
  Tooltip,
} from "@material-ui/core";
import { useIntl, FormattedMessage } from "react-intl";
import { CustomCardItem } from "../../../components/CustomCardItem";
import { MdCreate, MdDelete, MdGetApp, MdThumbUp } from "react-icons/md";
import moment from "moment";
import { Link } from "react-router-dom";

const howLongPosted = (date) => moment(date).toNow(true);

const YOUTUBE_PATTERN =
  '^((http\\:\\/\\/|https\\:\\/\\/)?((www|m)\\.youtube\\.com\\/|youtu\\.be\\/)(watch\\?v=[^"&?\\/ ]{11}|[^"&?\\/ ]{11})|(watch\\?v=[^"&?\\/ ]{11}|[^"&?\\/ ]{11}))';

const extractYoutubeId = (url) => {
  const matched = url && url.match(new RegExp(YOUTUBE_PATTERN));
  const id =
    matched && matched[5]
      ? matched[5].replace("watch?v=", "")
      : matched && matched[6]
      ? matched[6].replace("watch?v=", "")
      : "";
  console.debug(id);
  return id;
};

const ItemPublications = ({ item = {}, deletePublication }) => {
  const youtubeId = extractYoutubeId(item.videoUrl);
  const intl = useIntl();

  return (
    <>
      <CustomCardItem>
        <Card key={item._id} className="my-3">
          <CardContent>
            <Typography variant="h5" color="textSecondary" className="mb-4">
              {item.title}
            </Typography>
            <div className="mb-3"></div>
            {item.type === "MAGAZINE" ? (
              <>
                <Typography
                  color="textSecondary"
                  component="p"
                  className="mb-2"
                >
                  {item.subtitle}
                </Typography>
                <Typography
                  color="textSecondary"
                  component="p"
                  className="mb-3"
                >
                  {intl.formatMessage({
                    id: "publication.form.edition_prefix",
                  })}{" "}
                  {item.edition}
                </Typography>
              </>
            ) : (
              <Typography color="textSecondary" component="p" className="mb-3">
                {item.description}
              </Typography>
            )}

            <div className="mb-3 d-flex overflow-auto">
              {item.type === "NEWS" ? (
                <>
                  {(item.images || []).map((img, index) => (
                    <Img
                      key={index}
                      className="mx-3 rounded"
                      alt={`${img}`}
                      height={156}
                      src={`${img}`}
                    />
                  ))}
                </>
              ) : (
                <Img
                  className="mx-3 rounded"
                  alt={`${item.cover}`}
                  height={156}
                  src={item.cover}
                />
              )}
            </div>
            {item.type === "NEWS" && (
              <div className="py-1" style={{ textAlign: "center" }}>
                {youtubeId && (
                  <div className="py-1" style={{ textAlign: "center" }}>
                    {youtubeId ? (
                      <iframe
                        width="420"
                        height="315"
                        src={`https://www.youtube.com/embed/${youtubeId}?rel=0&autoplay=0&showinfo=0&controls=0`}
                      ></iframe>
                    ) : null}
                  </div>
                )}
              </div>
            )}
          </CardContent>
          <Divider />
          <CardActions
            disableSpacing
            className="d-flex align-items-center justify-content-between"
          >
            <div className="d-flex align-items-center">
              <Tooltip
                placement="top"
                title={`${item.likesCount} ${intl.formatMessage({
                  id: "publication.form.likes",
                })}`}
                arrow
              >
                <IconButton>
                  <StyledBadge badgeContent={item.likesCount} color="secondary">
                    <MdThumbUp size={26} color="#379" />
                  </StyledBadge>
                </IconButton>
              </Tooltip>
              <Typography className="ml-5">
                {`${howLongPosted(item.updatedAt || item.createdAt)}`}
              </Typography>
            </div>
            <div>
              {item.type === "NEWS" ? (
                <>
                  <Tooltip placement="top" title="Edit" arrow>
                    <Link to={`publications/edit/${item._id}`}>
                      <IconButton>
                        <MdCreate size={22} color="#37933c" />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={
                      <FormattedMessage id="publication.form.tooltip_title_remove" />
                    }
                    arrow
                  >
                    <IconButton
                      onClick={() => {
                        deletePublication(item._id);
                      }}
                    >
                      <MdDelete size={22} color="#37933c" />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  {item.files &&
                    (item.files || []).map((file, index) => (
                      <a target="_blank" href={file} key={index}>
                        <Tooltip
                          placement="top"
                          title={
                            <FormattedMessage id="publication.form.tooltip_title_download_magazine" />
                          }
                          arrow
                        >
                          <IconButton>
                            <MdGetApp size={22} color="#37933c" />
                          </IconButton>
                        </Tooltip>
                      </a>
                    ))}

                  <Tooltip
                    placement="top"
                    title={
                      <FormattedMessage id="publication.form.tooltip_title_edit" />
                    }
                    arrow
                  >
                    <Link to={`publications/edit/${item._id}`}>
                      <IconButton>
                        <MdCreate size={22} color="#37933c" />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={
                      <FormattedMessage id="publication.form.tooltip_title_remove" />
                    }
                    arrow
                  >
                    <IconButton
                      onClick={() => {
                        deletePublication(item._id);
                      }}
                    >
                      <MdDelete size={22} color="#37933c" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          </CardActions>
        </Card>
      </CustomCardItem>
    </>
  );
};
export { ItemPublications };
