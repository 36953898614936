import React from 'react';
import {SettingsSidebar} from "../SettingsSidebar";
import {FormattedMessage} from "react-intl";
import {NavLink} from "react-router-dom";

const CategoriesSidebar = ({categories}) => (
  <div>
    <SettingsSidebar/>
    <div className='py-4 px-5'>
      <div className='border-top'/>
      <div className='pt-5 pb-3'>
        <b><FormattedMessage id='settings.select_the_desired_category' values={{br: <br/>}}/>:</b>
      </div>
      <div>
        {categories.map((category, i) => (
          <NavLink
            key={i}
            activeClassName='btn btn-success'
            to={`/settings/notifications/${category}`}
            className='d-block text-left my-2 mx-n3 py-3 btn'
          >
            <FormattedMessage id={`settings.categories.${category}`}/>
          </NavLink>
        ))}
      </div>
    </div>
  </div>
);

export default CategoriesSidebar;
