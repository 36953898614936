import React, { useState, useEffect } from "react";
import { FiUser } from "react-icons/fi";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";

import {
  Card,
  CardContent,
  Typography,
  Box,
  CardActionArea,
  Divider,
  List,
  ListItem,
  ListItemText,
  Slide,
  Dialog,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { whois } from "../../../api/routes-data";
import { ReactComponent as CloseIcon } from "../../../assets/svg-icons/Icon-close.svg";

const useStyles = makeStyles((theme) => ({
  user: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    height: 250,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  dialog: {
    width: "100%",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function UsersThatLikedPostsCreatedAtThisMonth({ item }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [identification, setIdentification] = useState([]);
  const parsedToken = sessionStorage.getItem("jwtoken");

  console.log("item", item);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: parsedToken,
      },
      body: JSON.stringify(item),
    };

    fetch(whois(), requestOptions)
      .then((response) => response.json())
      .then((data) => setIdentification(data))
      .catch((error) => console.log(error));
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={handleClickOpen}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CardContent>
          <FiUser size={50} color="#41b049" />
        </CardContent>
        <CardContent
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box component="div">
            <Typography
              className={classes.title}
              color="textSecondary"
              variant="subtitle2"
              gutterBottom
            >
              <FormattedMessage id="dashboard.users_liked_posts_created_month" />
            </Typography>
          </Box>
          <Box
            component="div"
            style={{
              width: 100,
              height: 50,
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography color="secondary" variant="h3">
              {item.length}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
      <Dialog
        className={classes.dialog}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <>
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <FormattedMessage id="dashboard.users_liked_posts_created_month" />
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <List className={classes.user}>
            <ListItem className="d-flex justify-content-between">
              <Box component="div" className="mx-2">
                <ListItemText
                  primary={<FormattedMessage id="dashboard.identification" />}
                />
              </Box>
              <Divider />
              <Box component="div" className="mx-2">
                <ListItemText
                  primary={<FormattedMessage id="dashboard.name" />}
                />
              </Box>
            </ListItem>
            {item.map((item) => (
              <>
                <Divider />
                <ListItem
                  key={item.identification}
                  className="d-flex justify-content-between"
                >
                  <Box component="div" className="mx-2">
                    <ListItemText primary={`${item.identification}`} />
                  </Box>
                  <Box component="div" className="mx-2">
                    <ListItemText primary={`${item.name}`} />
                  </Box>
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        </>
      </Dialog>
    </Card>
  );
}
