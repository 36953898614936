const discountColor = '#E93F6633';
const neutralColor =  '#FFC86633';
const goodColor = '#15CE9A33';
const extraBonusColor = '#4976EF33';

export default {
  SCC: [
    {
      value: 250000,
      prize: 0.0600,
      tier: extraBonusColor
    },
    {
      value: 260000,
      prize: 0.0374,
      tier: goodColor
    },
    {
      value: 280000,
      prize: 0.0320,
      tier: goodColor
    },
    {
      value: 300000,
      prize: 0.0267,
      tier: goodColor
    },
    {
      value: 340000,
      prize: 0.0160,
      tier: goodColor
    },
    {
      value: 342000,
      prize: 0.0155,
      tier: goodColor
    },
    {
      value: 345000,
      prize: 0.0147,
      tier: goodColor
    },
    {
      value: 348000,
      prize: 0.0139,
      tier: goodColor
    },
    {
      value: 350000,
      prize: 0.0133,
      tier: goodColor
    },
    {
      value: 400000,
      prize: 0.000,
      tier: neutralColor
    },
    {
      value: 450000,
      prize: 0.000,
      tier: neutralColor
    },
    {
      value: 500000,
      prize: 0.000,
      tier: neutralColor
    },
    {
      value: 550000,
      prize: 0.000,
      tier: neutralColor
    },
    {
      value: 600000,
      prize: -0.0100,
      tier: discountColor
    },
    {
      value: 650000,
      prize: -0.0133,
      tier: discountColor
    },
    {
      value: 700000,
      prize: -0.0167,
      tier: discountColor
    },
  ],
  TBC: [
    {
      value: 20000,
      prize: 0.0400,
      tier: extraBonusColor,
    },
    {
      value: 30000,
      prize: 0.0386,
      tier: goodColor,
    },
    {
      value: 50000,
      prize: 0.0357,
      tier: goodColor,
    },
    {
      value: 60000,
      prize: 0.0343,
      tier: goodColor,
    },
    {
      value: 90000,
      prize: 0.0300,
      tier: goodColor,
    },
    {
      value: 92000,
      prize: 0.0297,
      tier: goodColor,
    },
    {
      value: 95000,
      prize: 0.0293,
      tier: goodColor,
    },
    {
      value: 98000,
      prize: 0.0289,
      tier: goodColor,
    },
    {
      value: 100000,
      prize: 0.0286,
      tier: goodColor,
    },
    {
      value: 150000,
      prize: 0.0214,
      tier: goodColor,
    },
    {
      value: 180000,
      prize: 0.0171,
      tier: goodColor,
    },
    {
      value: 200000,
      prize: 0.0143,
      tier: goodColor,
    },
    {
      value: 240000,
      prize: 0.0086,
      tier: goodColor,
    },
    {
      value: 250000,
      prize: 0.00071,
      tier: goodColor,
    },
    {
      value: 280000,
      prize: 0.0029,
      tier: goodColor,
    },
    {
      value: 300000,
      prize: 0.0000,
      tier: neutralColor,
    },
    {
      value: 350000,
      prize: 0.0000,
      tier: neutralColor,
    },
    {
      value: 400000,
      prize: 0.0000,
      tier: neutralColor,
    },
    {
      value: 450000,
      prize: 0.000,
      tier: neutralColor,
    },
    {
      value: 500000,
      prize: 0.0000,
      tier: neutralColor,
    },
    {
      value: 550000,
      prize: 0.0000,
      tier: neutralColor,
    },
    {
      value: 600000,
      prize: -0.0100,
      tier: discountColor,
    },
    {
      value: 650000,
      prize: -0.0167,
      tier: discountColor,
    },
    {
      value: 700000,
      prize: -0.0233,
      tier: discountColor,
    },
    {
      value: 750000,
      prize: 0.0300,
      tier: discountColor,
    },
  ],
  FAT: [
    {
      value: 2.00,
      prize: -0.0600,
      tier: discountColor,
    },
    {
      value: 2.05,
      prize: -0.0573,
      tier: discountColor,
    },
    {
      value: 2.10,
      prize: -0.0545,
      tier: discountColor,
    },
    {
      value: 2.20,
      prize: -0.0491,
      tier: discountColor,
    },
    {
      value: 2.30,
      prize: -0.0436,
      tier: discountColor,
    },
    {
      value: 2.40,
      prize: -0.0382,
      tier: discountColor,
    },
    {
      value: 2.50,
      prize: -0.0327,
      tier: discountColor,
    },
    {
      value: 2.60,
      prize: -0.0273,
      tier: discountColor,
    },
    {
      value: 2.70,
      prize: -0.0218,
      tier: discountColor,
    },
    {
      value: 2.80,
      prize: -0.0164,
      tier: discountColor,
    },
    {
      value: 2.90,
      prize: -0.0109,
      tier: discountColor,
    },
    {
      value: 3.00,
      prize: -0.0055,
      tier: discountColor,
    },
    {
      value: 3.10,
      prize: 0.000,
      tier: neutralColor,
    },
    {
      value: 3.20,
      prize: 0.0045,
      tier: goodColor,
    },
    {
      value: 3.30,
      prize: 0.0091,
      tier: goodColor,
    },
    {
      value: 3.40,
      prize: 0.0136,
      tier: goodColor,
    },
    {
      value: 3.50,
      prize: 0.0182,
      tier: goodColor,
    },
    {
      value: 3.60,
      prize: 0.0227,
      tier: goodColor,
    },
    {
      value: 3.70,
      prize: 0.0273,
      tier: goodColor,
    },
    {
      value: 3.80,
      prize: 0.0318,
      tier: goodColor,
    },
    {
      value: 3.90,
      prize: 0.0364,
      tier: goodColor,
    },
    {
      value: 4.00,
      prize: 0.0409,
      tier: goodColor,
    },
    {
      value: 4.10,
      prize: 0.0455,
      tier: goodColor,
    },
    {
      value: 4.20,
      prize: 0.0500,
      tier: goodColor,
    },
    {
      value: 4.30,
      prize: 0.0533,
      tier: goodColor,
    },
    {
      value: 4.40,
      prize: 0.0567,
      tier: goodColor,
    },
    {
      value: 4.5,
      prize: 0.0600,
      tier: extraBonusColor,
    },
  ],
  PROTEIN: [
    {
      value: 2.00,
      prize: -0.0600,
      tier: discountColor,
    },
    {
      value: 2.05,
      prize: -0.0573,
      tier: discountColor,
    },
    {
      value: 2.10,
      prize: -0.0545,
      tier: discountColor,
    },
    {
      value: 2.20,
      prize: -0.0491,
      tier: discountColor,
    },
    {
      value: 2.30,
      prize: -0.0436,
      tier: discountColor,
    },
    {
      value: 2.40,
      prize: -0.0382,
      tier: discountColor,
    },
    {
      value: 2.50,
      prize: -0.0327,
      tier: discountColor,
    },
    {
      value: 2.60,
      prize: -0.0273,
      tier: discountColor,
    },
    {
      value: 2.70,
      prize: -0.0218,
      tier: discountColor,
    },
    {
      value: 2.80,
      prize: -0.0164,
      tier: discountColor,
    },
    {
      value: 2.90,
      prize: -0.0109,
      tier: discountColor,
    },
    {
      value: 3.00,
      prize: 0.000,
      tier: neutralColor,
    },
    {
      value: 3.10,
      prize: 0.0100,
      tier: goodColor,
    },
    {
      value: 3.20,
      prize: 0.0200,
      tier: goodColor,
    },
    {
      value: 3.30,
      prize: 0.0300,
      tier: goodColor,
    },
    {
      value: 3.40,
      prize: 0.0400,
      tier: goodColor,
    },
    {
      value: 3.50,
      prize: 0.0500,
      tier: goodColor,
    },
    {
      value: 3.60,
      prize: 0.0567,
      tier: goodColor,
    },
    {
      value: 3.70,
      prize: 0.0633,
      tier: goodColor,
    },
    {
      value: 3.80,
      prize: 0.0700,
      tier: extraBonusColor,
    },
  ]
};
