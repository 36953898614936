import React, { useState, useEffect } from "react";
import { routes } from "./routes";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { IntlProvider, defineMessages } from "react-intl";
import Layout from "./components/layouts";
import "simplebar";
import "simplebar/dist/simplebar.css";
import "react-toastify/dist/ReactToastify.css";
import "react-circular-progressbar/dist/styles.css";
import moment from "moment";
import "moment/locale/pt";
import "moment/locale/es";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/nl";

import Toaster from "./components/Toaster";
import { themeConfig } from "./components/layouts/themeConfig";
import localeUtils from "./utils/locale.utils";

localeUtils.setDefaultLocale();

function App() {
  const [messages, setMessages] = useState(null);
  const localeName = localeUtils.getLocale();
  const localeMessages = import(`./assets/locales/${localeName}.js`);
  moment.locale(localeName);

  useEffect(() => {
    localeMessages
      .then((messages) => {
        setMessages(
          defineMessages(localeUtils.convertLocale(messages.default))
        );
      })
      .catch(() => localeUtils.setDefaultLocale());
  }, []);

  if (!messages) {
    return <span>Loading...</span>;
  }

  const isLogged = sessionStorage.getItem("jwtoken");

  return (
    <IntlProvider locale={localeName} messages={messages}>
      <BrowserRouter>
        <ThemeProvider theme={themeConfig}>
          <Switch>
            {!isLogged ? (
              <Route exact path="/">
                <Redirect to="account/sign-in" />
              </Route>
            ) : null}

            <Route path="/account">
              <Layout.SignedOut>
                {routes
                  .filter((route) => !route.protected)
                  .map(({ path, component }, i) => (
                    <Route path={path} component={component} key={i} />
                  ))}
              </Layout.SignedOut>
            </Route>

            <Route path="/">
              <Layout.Main>
                <>
                  {routes
                    .filter((route) => route.protected)
                    .map(({ path, component }, i) => (
                      <Route path={path} component={component} key={i} />
                    ))}
                </>
              </Layout.Main>
            </Route>
            <Toaster />
          </Switch>
        </ThemeProvider>
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
