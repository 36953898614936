import React from 'react';
import PropTypes from 'prop-types';
import './Sidebar.scss';

export default function Sidebar({children, hide = false}) {
  return (
    <div className={`sidebar py-4 ${hide ? ' hide' : ''}`}>
      <div className='sidebar__inner'>
        {children}
      </div>
    </div>
  )
}

Sidebar.propTypes = {
  children: PropTypes.object,
  hide: PropTypes.bool
};
