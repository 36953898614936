import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import "./NavItem.scss";
import { Tooltip } from "@material-ui/core";

export function NavItem({ icon, to, children, disabled, title, placement }) {
  const [opened, setOpened] = useState(false);

  return (
    <Tooltip placement={placement} title={title} arrow>
      <div
        className={`navitem ${opened ? "active" : ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        <NavLink
          to={to}
          activeClassName="active"
          onClick={(event) => (children ? event.preventDefault() : null)}
        >
          <div
            className="navitem__icon"
            onClick={() => setOpened(children && true)}
          >
            {icon}
          </div>
        </NavLink>
        {children && (
          <div
            className={`navitem__dropdown pl-0 py-3 pr-4 ${
              opened ? "active" : ""
            }`}
            onClick={() => setOpened(false)}
          >
            {children}
          </div>
        )}
        {opened && (
          <div className="outlayer" onClick={() => setOpened(false)}></div>
        )}
      </div>
    </Tooltip>
  );
}

NavItem.propTypes = {
  icon: PropTypes.object.isRequired,
  to: PropTypes.string,
  disabled: PropTypes.bool,
  placement: PropTypes.node,
  title: PropTypes.node
};
