import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import { ReactComponent as ArrowDown } from '../../../assets/svg-icons/Icon-arrow-down.svg';

export function RankedCollapsible({trigger, numeration, children}) {
  return (
    <Collapsible trigger={(
      <div className='d-flex p-4 align-items-center justify-content-between'>
        <div className='d-flex'>
          {numeration && <div className='pr-3 pl-2'>{numeration}</div>}
          {trigger}
        </div>
        <ArrowDown className='icon'/>
      </div>
    )}>
      {children}
    </Collapsible>
  )
}

RankedCollapsible.propTypes = {
  children: PropTypes.any,
  trigger: PropTypes.any,
  numeration: PropTypes.number
};
