import React from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTooltip } from 'victory';
import Theme from '../../utils/Chart.theme';
import ChartNoData from "./ChartNoData";
import ChartTooltipFlyout from "./ChartTooltipFlyout";
import {ChartTooltip} from "./ChartTooltip";

/* TODO: miss non-compliant milk */
const getCSSColor = () => 'url(#blueGrad)';
const blueStartColor = '#BBCAF1';
const blueEndColor = '#4976EF';
const averageColor = '#15CE9A';


// const getAverage = (arr) => {
//   let sum = 0;
//   const noZeroData = arr.filter(a => a.y > 0);
//   noZeroData.map((item) => sum += item.y);
//   return sum / noZeroData.length;
// };

const CHART_WIDTH = 600;
const CHART_HEIGHT = 400;

const VolumeMonthlyChart = ({ data, average }) => (
  <div
    style={{
      flex: 1,
      paddingBottom: 20
    }}
  >
    {
      data.length === 0 && <ChartNoData type={'volume'} />
    }
    <div>
      <VictoryChart
        width={CHART_WIDTH}
        height={CHART_HEIGHT}
        domain={{ y: [0, 8000] }}
        domainPadding={{ x: 10 }}
        theme={Theme}
      >
        <defs>
          <linearGradient id="blueGrad" x1="0" y1="0" x2="0" y2="2">
            <stop offset="0" stopColor={blueStartColor} stopOpacity="1" />
            <stop offset="1" stopColor={blueEndColor} stopOpacity="1" />
          </linearGradient>
        </defs>
        <VictoryBar
          animate={{duration: 300}}
          style={{
            data: {
              fill: () => getCSSColor(),
              stroke: 'rgba(73, 118, 239, 0.26)',
              fillOpacity: 0.25,
              width: 15
            },
            labels: {
              fontSize: 15,
              fill: () => getCSSColor()
            }
          }}
          cornerRadius={{ topLeft: 4, topRight: 4 }}
          labelComponent={
            <VictoryTooltip
              flyoutComponent={<ChartTooltipFlyout chartHeight={CHART_HEIGHT} arrowOrientation="horizontal" />}
              labelComponent={<ChartTooltip chartHeight={CHART_HEIGHT} unit="litros" />}
            />
          }
          events={[{
            target: 'data',
            eventHandlers: {
              onClick: () => [
                {
                  target: 'data',
                  mutation: () => ({active: true})
                }
              ]
            }
          }]}
          data={data}
        />
        <VictoryAxis
          dependentAxis
          style={{
            axis: {
              stroke: 'transparent',
            },
            grid: {
              stroke: 'grey',
              strokeDasharray: '1,3',
            },
            tickLabels: {
              fontSize: 10,
              fill: '#657FA0'
            }
          }}
          tickValues={[
            0,
            1000,
            2000,
            3000,
            4000,
            5000,
            6000,
            7000,
            8000,
          ]}
          tickFormat={d => (d >= 1000 ? `${d / 1000}k L` : d)}
        />
        <VictoryAxis
          scale="time"
          standalone={false}
          style={{
            axis: {
              stroke: 'transparent'
            },
            ticks: {
              stroke: 'black',
              strokeWidth: 1
            },
            tickLabels: {
              fontSize: 8,
              fill: '#657FA0',
              padding: 5
            },
          }}
          tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
            22, 23, 24, 25, 26, 27, 28, 29, 30, 31]}
          tickFormat={(t) => t < 10 ? `0${t}`: t }
        />
        <VictoryAxis
          standalone={false}
          style={{
            axis: {
              stroke: averageColor,
              strokeWidth: 2
            },
            ticks: {
              fill: 'transparent',
              size: 0
            },
            tickLabels: {
              fill: 'transparent',
              size: 0
            }
          }}
          axisValue={average}
        />
      </VictoryChart>
    </div>

  </div>
);

export default VolumeMonthlyChart;
