import React from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTooltip } from 'victory';
import Theme from '../../utils/Chart.theme';
import ChartNoData from "./ChartNoData";
import ChartTooltipFlyout from "./ChartTooltipFlyout";
import {ChartTooltip} from "./ChartTooltip";

const CHART_WIDTH = 800;
const CHART_HEIGHT = 400;

const tickXValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31];

export const TemperatureChart = ({
  data,
  getCSSColor,
  getOpacity,
  average
}) => (
  <div style={{ flex: 1 }}>
    {
      data.length === 0 && <ChartNoData type={'temperatura'}/>}
    <div>
      <VictoryChart
        maxDomain={{ y: 6 }}
        domainPadding={{x: 10, y: 0}}
        width={CHART_WIDTH}
        height={CHART_HEIGHT}
        theme={Theme}
      >
        <defs>
          <linearGradient id="minor" gradientTransform="rotate(90)">
            <stop offset="0" stopColor="rgba(233, 199, 143, 0.19)" stopOpacity="1" />
            <stop offset="1" stopColor="#c93838" stopOpacity="1" />
          </linearGradient>
          <linearGradient id="major" gradientTransform="rotate(90)">
            <stop offset="0" stopColor="rgba(233,191,63,0.46)" stopOpacity="1" />
            <stop offset="1" stopColor="rgb(233,63,102)" stopOpacity="1" />
          </linearGradient>
        </defs>
        <VictoryBar
          animate={{duration: 2000}}
          style={{
            data: {
              fill: d => getCSSColor(d.y),
              fillOpacity: d => getOpacity(d.y),
              width: 15,
              stroke: 'pink',
              strokeWidth: d => (d.y > 0 ? 1 : 0),
            },
            labels: {
              fontSize: 15,
              fill: d => getCSSColor(d.y)
            }
          }}
          events={[{
            target: 'data',
            eventHandlers: {
              onClick: () => [
                {
                  target: 'data',
                  mutation: () => ({ active: true})
                }
              ]
            }
          }]}
          data={data}
          cornerRadius={{ topLeft: 4, topRight: 4 }}
          labelComponent={
            <VictoryTooltip
              flyoutComponent={<ChartTooltipFlyout chartHeight={CHART_HEIGHT} arrowOrientation="horizontal" />}
              labelComponent={<ChartTooltip unit={'°C'} />}
            />
          }
        />
        <VictoryAxis
          dependentAxis
          standalone={false}
          style={{
            axis: {
              stroke: 'transparent',
            },
            grid: {
              stroke: 'grey',
              strokeDasharray: '1,3',
            },
            tickLabel: {
              color: 'grey'
            }
          }}
          tickValues={[0, 1, 2, 3, 4, 5, 6]}
          tickFormat={t => `${t}°`}
          domain={[0, 6]}
        />
        <VictoryAxis
          scale="time"
          standalone={false}
          style={{
            axis: {
              stroke: 'transparent'
            },
            ticks: {
              stroke: 'black',
              strokeWidth: 1
            },
            tickLabels: {
              fontSize: 12,
              fill: 'grey',
              padding: 5
            },
          }}

          tickValues={tickXValues}
        />
        <VictoryAxis
          standalone={false}
          style={{
            axis: {
              stroke: '#15CE9A',
              strokeWidth: 2
            },
            ticks: {
              fill: 'transparent',
              size: 0
            },
            tickLabels: {
              fill: 'transparent',
              size: 0
            }
          }}
          axisValue={average}
        />
      </VictoryChart>
    </div>
  </div>
);

