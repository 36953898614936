import React from "react";

export function CustomCardItem({ styles, shadow = true, children }) {
  return (
    <div
      className={styles}
      style={{
        boxShadow: shadow
          ? "rgba(101, 127, 169, 0.3) 0px 4px 10px 0px"
          : "none",
      }}
    >
      <div className="card w-100">
        <div className="card-body w-100">{children}</div>
      </div>
    </div>
  );
}
