import React, { useRef, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormContext } from './FormContext';
import InputMask from 'react-input-mask';

const style = {
  label: {
    pointerEvents: 'none',
    position: 'absolute', left: '1rem',
    padding: '.2rem .3rem', background: '#fff',
    WebkitTransition: 'all 0.15s ease 0s'
  }
};

export function InputField({placeholder, controlName, disabled, defaultValue, onChange, className, mask, maskChar, onBlur, validation, validationMessage, ...rest}) {
  const [active, setActive] = useState();
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [valid, setValid] = useState(true);
  const inputRef = useRef(null);

  const { formState, handleInput } = useContext(FormContext);

  useEffect(() => {
    if (!value && formState[controlName]) {
      setActive(true);
      setValue(formState[controlName]);
    }
  }, [formState[controlName]]);

  function handleValue(value) {
    setValue(value);

    if (controlName) {
      handleInput({name: controlName, value: value});
    }
  }

  return (
    <div style={{position: 'relative'}} className={className}>
      <InputMask
        mask={mask}
        maskChar={maskChar}
        className={`form-control ${ !valid ? 'is-invalid' : ''}`}
        ref={inputRef}
        value={defaultValue || value}
        onFocus={() => setActive(true)}
        onBlur={async (e) => {
          if (onBlur) {
            await onBlur(e);
          }
          const { current } = inputRef;
          if(!current) {
            return;
          }
          const input = current.getInputDOMNode();
          setActive(current.getInputValue() || false);

          if (validation) {
            const isValid = input.checkValidity() && validation(input.value);
            setValid(isValid);
            if (!isValid) {
              setErrorMessage(validationMessage);
            }
          } else {
            setValid(input.checkValidity());
            if (!input.checkValidity()) {
              setErrorMessage('Preencha o campo!');
            }
          }
        }}
        onChange={(e) => onChange && onChange(e.target.value) || handleValue(e.target.value)}
        onInvalid={() => {
          const { current: { value }} = inputRef;
          if (value === '') {
            setErrorMessage('Preencha o campo!');
          }
        }}
        disabled={!!disabled}
        {...rest}
      />
      <span
          style={{ top: active ? '-1rem' : '.8rem', ...style.label }}
        >
          {placeholder}
      </span>
    </div>
  )
}

InputField.propTypes = {
  controlName: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  validation: PropTypes.func,
  validationMessage: PropTypes.string
};
