import { Grid, TextField } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Select from "react-select";
import { CustomCard } from "../../../components/CustomCard";
import styled from "styled-components";
import { application } from "../../../helpers/axiosModules";
import { parseJwt } from "../../../helpers/parseJwt";
import { administratorsGetByIdentification } from "../../../api/routes-app";

const CustomAudience = styled(Select).attrs({})`
  && .mySelect__value-container {
    height: 58px;
  }
`;
export function NotificationForm({ formSubmit, hookForm }) {
  const [user, setUser] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleSubmit, register, control, watch, setValue, formState } =
    hookForm;
  useEffect(() => {
    async function fetchData() {
      const parsedToken = parseJwt(sessionStorage.getItem("jwtoken"));

      const response = await application.get(
        administratorsGetByIdentification(parsedToken.identification)
      );
      setUser(response.data);
      const filteredOptions = [];

      [user.groups].forEach((item) => {
        filteredOptions.push({ label: item, value: item });
      });
    }

    fetchData().then();
  }, []);
  useEffect(() => {}, [user]);
  const submitWrapper = async (d) => {
    try {
      setIsSubmitting(true);
      await formSubmit({
        title: d.title,
        message: d.message,
        audience: d.audience,
      });
    } catch (e) {
      setIsSubmitting(false);
      throw e;
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(submitWrapper)} className="p-5">
        <CustomCard styles="mb-5">
          <h6 className="card-title dashed-green mb-5">
            <FormattedMessage id="instant_notification.form.label" />
          </h6>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Controller
                as={TextField}
                name="title"
                label={<FormattedMessage id="instant_notification.form.title" />}
                variant="outlined"
                fullWidth
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Controller
                className="w-100"
                style={{ height: 55 }}
                as={TextField}
                name="message"
                label={<FormattedMessage id="instant_notification.form.message" />}
                variant="outlined"
                fullWidth
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <CustomAudience
                    style={{ height: 58 }}
                    defaultValue={user.groups}
                    isMulti
                    placeholder={<FormattedMessage id="instant_notification.form.audience" />}
                    options={user.groups}
                    getOptionLabel={(option) => option}
                    getOptionValue={(option) => option}
                    classNamePrefix="mySelect"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                }
                // onChange={([, data]) => data}
                name="audience"
                control={control}
              />
            </Grid>
          </Grid>
        </CustomCard>
        <Grid container spacing={3} className="mt-5">
          <Grid item xs={12} sm={12} md={12}>
            <div className="border-top p-4 d-flex justify-content-around">
              <Link to={`/notifications`}>
                <button
                  className="btn btn-outline-secondary p-4"
                  style={{ width: 270 }}
                >
                  <FormattedMessage id="common.cancel" />
                </button>
              </Link>
              <button
                type="submit"
                className="btn btn-success p-4"
                style={{ width: 270 }}
              >
                {isSubmitting && <CircularProgress />}
                {!isSubmitting && <FormattedMessage id="common.confirm" />}
              </button>
            </div>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
