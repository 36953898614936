import React, { useContext, useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { ActionMenu } from "../../components/ActionMenu";
import { ReactComponent as MoreIcon } from "../../assets/svg-icons/Icon-more.svg";
import { IconWithText } from "../../components/IconWithText";
import { ReactComponent as EditIcon } from "../../assets/svg-icons/Icon-edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg-icons/Icon-trash.svg";
import { Table } from "../../components/Table";
import { ReactComponent as InfoIcon } from "../../assets/svg-icons/Icon-info.svg";
import { Modal } from "../../components/Modal";
import { BreadcrumbContext } from "./SettingsWrapper";
import { SidebarContext } from "../../components/layouts/Main/Main";
import service from "./notificationsService";
import { communication } from "../../helpers/axiosModules";
import AddNotificationButton from "./components/AddNotificationButton";
import CustomFilter, {
  resetTableSearchAndFilter,
} from "../../components/CustomFilter";
import { SettingsSidebar } from "./SettingsSidebar";
import Loading from "../../components/loading/Loading";
import { categories } from "./categories/types";
import CategoriesSidebar from "./categories/CategoriesSidebar";

const getQuality = (quality) => {
  switch (quality.toLowerCase()) {
    case "quality":
      return "Qualidade";
    case "collect":
      return "Coleta";
    case "temperature":
      return "Temperatura";
    case "magazine":
      return "Revistas";
    case "magazines":
      return "Revistas";
    case "other":
      return "Outros";
    default:
      return "";
  }
};

export function NotificationList({ location }) {
  const [allNotifications, setAllNotifications] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notificationToDelete, setNotificationToDelete] = useState();
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);
  const setSidebarChildren = useContext(SidebarContext);
  const tableRef = useRef(null);
  const intl = useIntl();

  const filterNotifications = (_notifications, pathname) => {
    const lastDir = _.last(pathname.split("/"));

    if (
      _.includes(
        ["quality", "collect", "temperature", "magazines", "other"],
        lastDir
      )
    ) {
      return _.filter(
        _notifications,
        ({ type }) => type === lastDir.toUpperCase()
      );
    }

    return _notifications;
  };

  const loadNotifications = async () => {
    const _notifications = await service.all();
    setLoading(false);
    setAllNotifications(_notifications);
    setNotifications(filterNotifications(_notifications, location.pathname));
  };

  function handleDelete() {
    setLoading(true);
    communication
      .delete(`/notificationsreg/${notificationToDelete._id}`)
      .then(() => {
        setNotificationToDelete(null);
        setLoading(false);
        loadNotifications();
      });
  }

  useEffect(() => {
    loadNotifications()
      .then(() => {
        setBreadcrumbRightContent({
          rightContent: <AddNotificationButton />,
          onSearch: (value) => tableRef.current.onSearchChange(value),
          filter: <CustomFilter tableRef={tableRef} intl={intl} />,
          resetTableSearchAndFilter: () => resetTableSearchAndFilter(tableRef),
        });

        setSidebarChildren(<CategoriesSidebar categories={categories} />);
      })
      .catch((err) => console.error(err));

    return () => {
      setSidebarChildren(<SettingsSidebar />);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/settings/notifications") {
      loadNotifications();
    } else {
      setNotifications(
        filterNotifications(allNotifications, location.pathname)
      );
    }
  }, [location]);

  if (loading) {
    return <Loading />;
  }

  console.debug("table ref notifications: ", tableRef);
  return (
    <>
      <Table
        tableRef={tableRef}
        columns={[
          {
            title: <FormattedMessage id="common.notification" />,
            field: "title",
            disableClick: true,
            sorting: false,
          },
          {
            title: <FormattedMessage id="common.type" />,
            field: "type",
            disableClick: true,
            sorting: false,
            render: (rowData) => <span>{getQuality(rowData.type)}</span>,
          },
          {
            title: <FormattedMessage id="common.description" />,
            field: "description",
            disableClick: true,
            sorting: false,
          },
          {
            title: "",
            field: "actions",
            sorting: false,
            render: (rowData) => (
              <div className="d-flex justify-content-end">
                {JSON.stringify(rowData)}
                <ActionMenu
                  trigger={
                    <button className="btn px-3 py-2">
                      <MoreIcon />
                    </button>
                  }
                  content={
                    <div className="px-3 py-2 child-svg-color-1">
                      <Link
                        className="btn w-100"
                        to={`/settings/notifications/${rowData.type.toLowerCase()}/${
                          rowData._id
                        }`}
                      >
                        <IconWithText
                          icon={<EditIcon />}
                          text={<FormattedMessage id="common.edit" />}
                          reset={true}
                        />
                      </Link>
                      <button
                        className="btn w-100"
                        onClick={() => setNotificationToDelete(rowData)}
                      >
                        <IconWithText
                          icon={<DeleteIcon />}
                          text={<FormattedMessage id="common.delete" />}
                          reset={true}
                        />
                      </button>
                    </div>
                  }
                />
              </div>
            ),
          },
        ]}
        isLoading={loading}
        data={notifications}
        search={true}
      />
      <Modal
        isOpen={!!notificationToDelete}
        onRequestClose={() => {
          setNotificationToDelete(null);
        }}
        onConfirm={handleDelete}
      >
        <div className="d-flex flex-column align-items-center text-center">
          <div
            className="px-3 py-2 border-radius mb-5"
            style={{ backgroundColor: "#f5f6fa", fill: "#657fa0" }}
          >
            <InfoIcon />
          </div>
          <h6 className="text-bold">
            <FormattedMessage id="settings.remove_notification_confirm" />
          </h6>
          <div className="py-4">
            {notificationToDelete ? notificationToDelete.headerTitle : ""}
          </div>
        </div>
      </Modal>
    </>
  );
}
