import React, {useState, useEffect, useContext} from 'react';
import {FormattedMessage, FormattedTime, FormattedNumber} from "react-intl";
import {TemperatureChart} from '../../../components/charts/TemperatureChart';
import {ResultsTicketTemp, TICKET, TEMP} from '../components/ResultsTicketTemp';
import {data} from "../../../helpers/axiosModules";
import {BreadcrumbContext} from '../../../components/BreadCrumb';
import {generalLoading} from '../../../helpers/skeletonSchemas';
import {PeriodSelector} from '../components/PeriodSelector';
import * as moment from 'moment';
import * as _ from 'lodash';
import DatePicker from 'react-date-picker';

import {ReactComponent as ClockIcon} from '../../../assets/svg-icons/Icon-clock-blue.svg';
import {ReactComponent as RedCircle} from '../../../assets/svg-icons/Icon-temperature-bad.svg';
import {ReactComponent as PinkCircle} from '../../../assets/svg-icons/Icon-temperature-good.svg';

const DetailRow = ({name, text, className, space = 130}) => (
  <div className={'d-flex align-items-center' + (className ? ` ${className}` : '')}>
    <div className='text-bold' style={{width: space}}>{name}:</div>
    <div>{text}</div>
  </div>
);

const TemperatureAverageBox = ({average}) => (
  <div
    className='d-flex flex-row align-content-center justify-content-center'
    style={{
      boxShadow: '0 7px 20px 0 rgba(101, 127, 160, 0.12)',
      borderRadius: 6,
      padding: 12,
      margin: '12px 0 20px'
    }}
  >
    <div
      style={{
        backgroundColor: '#ed7f751a',
        padding: '6px 12px',
        margin: 6,
        borderRadius: 6
      }}
    >
      <RedCircle/>
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <span
        style={{
          color: '#3d5879',
          fontSize: 16
        }}
      >
        <FormattedMessage id='common.average'/>{':'}
      </span>
      <span
        style={{
          color: '#3d5879',
          fontWeight: 'bold',
          fontSize: 16
        }}
      >
        {
          average
            ? <><FormattedNumber value={average}/>°celsius</>
            : <>-</>
        }
      </span>
    </div>
  </div>
);

const MIN_DATE = new Date('2017-01-01');
const TODAY = new Date();

const getCSSColor = (y) => {
  if (y > 4) {
    return 'url(#major)';
  }
  return 'url(#minor)';
};

const getOpacity = (y) => {
  if (y > 4) {
    return 0.75;
  }
  return 0.25;
};

const filter = (month, year, collectData) => {
  const ans = [];

  if (!collectData) {
    return [];
  }

  let hasItems = false;
  collectData.map((data) => {
    if (!data.date) {
      return;
    }

    const date = moment(data.date);

    if (date.month() !== month || date.year() !== year) {
      return;
    }

    const dayOfMonth = moment(data.date).date();

    if (data.volume) {
      hasItems = true;
      ans.push({
        x: dayOfMonth,
        y: data.temperature,
        label: ''
      });
    }
  });

  return hasItems ? ans : [];
};

const CURRENT_MONTH = moment().month();
const CURRENT_YEAR = moment().year();
const getAverage = arr => _.meanBy(arr, value => value.y);

export function CollectSection(props) {
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);

  // all tabs
  const [years, setYears] = useState([]);
  const months = moment.months().map((name, i) => ({name, value: i}));
  const [resultsView, setResultsView] = useState(TICKET);
  const [loading, setLoading] = useState(true);
  const [collectData, setCollectData] = useState([]);

  // first tab
  const [info, setInfo] = useState([]);
  const [date, setDate] = useState(null);

  // second tab
  const [currentMonth, setCurrentMonth] = useState(CURRENT_MONTH);
  const [currentYear, setCurrentYear] = useState(CURRENT_YEAR);
  const [currentData, setCurrentData] = useState(true);

  function loadCollect() {
    data.get(`/collections/${props.id}?cachetick=${Math.random()}`)
      .then(({data}) => {
        setCollectData([...collectData, ...data]);
        setInfo({});
        setCurrentData(filter(currentMonth, currentYear, data));
        setLoading(false);
      }).catch(err => console.error(err));
  }

  useEffect(
    () => {
      loadCollect();
    }, []
  );

  useEffect(() => {
    setYears(_.map(_.keys(_.groupBy(collectData, item => moment(item.date).year())), value => ({value: Number(value)})));
    // setDataMonth(_filter(collectData, currentMonth, currentYear));
  }, [collectData, resultsView, currentMonth, currentYear]);

  useEffect(() => {
    setBreadcrumbRightContent({
      rightContent: <ResultsTicketTemp onChange={setResultsView} view={resultsView}/>
    });

    return () => {
      setBreadcrumbRightContent({
        rightContent: null
      });
    }
  }, [resultsView, setBreadcrumbRightContent]);

  if (loading) {
    return generalLoading(1);
  }

  const futureDate = currentMonth > CURRENT_MONTH && currentYear >= CURRENT_YEAR;
  const isPartialsPeriod = !futureDate && (CURRENT_YEAR === currentYear && CURRENT_MONTH <= currentMonth);
  const isAccomplishedPeriod = !futureDate && !isPartialsPeriod;
  const average = getAverage(currentData);

  return (
    <div>
      {resultsView === TICKET && (
        <>
          <div className='p-4 pl-5 border-bottom d-flex align-items-center justify-content-between'>
            <div className='text-bold'>
              <FormattedMessage id='producers.milk_supplier'/>
            </div>
            <div className='d-flex align-items-center'>
              <div className="mx-2" style={{whiteSpace: 'nowrap'}}>
                <FormattedMessage id='producers.desired_date'/>
              </div>
              <div className='form-control d-flex align-items-center' style={{height: 46}}>
                <FormattedMessage id='common.date'/>:
                <DatePicker
                  calendarIcon={null}
                  locale='pt-BR'
                  clearIcon={null}
                  value={date}
                  minDate={MIN_DATE}
                  maxDate={TODAY}
                  onChange={date => {
                    setDate(date);
                    const value = collectData.filter(el => moment(date).diff(moment(el.date), "days") === 0);
                    setInfo(value[0] ? value[0] : {});
                  }}
                  formatMonthYear={(locale, date) => moment(date).format('MMM YYYY')}
                  className='date-picker-borderless'
                />
              </div>
            </div>
          </div>
          <div className='row m-0'>
            <div className='col-5 border-right p-0'>
              <div className='p-5'>
                <h6 className='text-bold pb-3'><FormattedMessage id='producers.travel_details'/></h6>
                <DetailRow name={<FormattedMessage id='producers.travel'/>} text={info.route} className='my-4'/>
                <DetailRow name={<FormattedMessage id='producers.route'/>} text={info.route} className='my-4'/>
                <DetailRow name={<FormattedMessage id='producers.transport'/>} text={info.transp} className='my-4'/>
                <DetailRow name={<FormattedMessage id='producers.driver'/>} text={info.driver} className='my-4'/>
              </div>
            </div>
            <div className='col-7 p-5'>
              <div className='d-flex'>
                <b><FormattedMessage id='producers.links.collect'/></b>
                <div className='shadow w-100 mr-3 ml-5 rounded d-flex align-items-center justify-content-center'
                     style={{height: 57}}>
                  <ClockIcon className='mr-3 mb-2'/>
                  {
                    info.date && <FormattedTime id='producers.time' value={info.date}/>
                  }
                </div>
                <div className='alert alert-warning w-100 ml-3 text-center p-4'>
                  Parcial
                </div>
              </div>
              <div className='pt-3'>
                <DetailRow
                  name={<FormattedMessage id='producers.sample'/>}
                  text={info.sample}
                  className='py-4 px-5'
                />
                <DetailRow
                  name={<FormattedMessage id='producers.compartment'/>}
                  text={info.compartment}
                  className='py-4 px-5 bg-light rounded'
                />
                <DetailRow
                  name='Volume'
                  text={info.volume}
                  className='py-4 px-5'/>
                <DetailRow
                  name={<FormattedMessage id='producers.ruler'
                  />}
                  text={info.rule}
                  className='py-4 px-5 bg-light rounded'/>
                <DetailRow
                  name={<FormattedMessage id='common.temperature'/>}
                  text={info.temperature}
                  className='py-4 px-5'
                />
              </div>
            </div>
          </div>
        </>
      )}

      {resultsView === TEMP && (
        <>
          <div className='p-4 border-bottom d-flex justify-content-between'>
            <div>
              <div className="d-flex flex-row justify-content-between align-items-center m-3">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <PinkCircle/>
                  <div
                    className="mx-3"
                    style={{
                      fontSize: 14,
                      color: '#657FA0',
                    }}
                  >
                    Menor que 4°
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <RedCircle/>
                  <div
                    className="mx-3"
                    style={{
                      marginHorizontal: 5,
                      fontSize: 14,
                      color: '#657FA0',
                    }}
                  >
                    Maior que 4°
                  </div>
                </div>
              </div>
            </div>
            <PeriodSelector
              years={years}
              months={months}
              currentYear={currentYear}
              currentMonth={currentMonth}
              setCurrentYear={setCurrentYear}
              setCurrentMonth={setCurrentMonth}
              onSelectMonth={(options) => {
                setCurrentData(filter(options.value, currentYear, collectData));
              }}
              onSelectYear={(options) => {
                setCurrentData(filter(currentMonth, options.value, collectData));
              }}
              // hideMonth={resultsView === 'year'}
            />
          </div>
          <div className='row m-0'>
            <div className='col-8 border-right p-0'>
              <div className='p-4'>
                <TemperatureChart
                  data={currentData}
                  getCSSColor={getCSSColor}
                  getOpacity={getOpacity}
                  average={average}
                />
              </div>
            </div>
            <div className='col-4 p-4'>
              {
                isPartialsPeriod && (
                  <>
                    <div>
                      <b><FormattedMessage id='producers.partials'/></b>
                      <TemperatureAverageBox/>
                    </div>
                    <div>
                      <b><FormattedMessage id='producers.accomplished'/></b>
                      <div style={{backgroundColor: '#FFF4E0', borderRadius: 6, padding: 16, textAlign: 'center'}}>
                          <span style={{color: '#BB744A'}}>
                            <FormattedMessage id='common.in_progress'/>
                          </span>
                      </div>
                    </div>
                  </>
                )
              }

              {
                isAccomplishedPeriod && (
                  <div>
                    <b><FormattedMessage id='producers.accomplished'/></b>
                    <TemperatureAverageBox average={average}/>
                  </div>
                )
              }

              {
                futureDate && (
                  (
                    <div>
                      <b>-</b>
                      <TemperatureAverageBox average={average}/>
                    </div>
                  )
                )
              }
            </div>
          </div>
        </>
      )}
    </div>
  )
}
