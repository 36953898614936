import React, {useEffect, useState} from 'react';
import {data} from '../../helpers/axiosModules';
import {VictoryAxis, VictoryBar, VictoryChart, VictoryTooltip} from 'victory';
import Theme from '../../utils/Chart.theme';
import {CheckedTab} from '../../components/tabs/CheckedTab';
import {Table} from '../../components/Table';
import * as _ from 'lodash';
import {CheckedTabsWrapper} from '../../components/tabs/CheckedTabsWrapper';
import defaultPic from '../../assets/svg-icons/Icon-profile.svg';
import {ReactComponent as ListCheckIcon} from '../../assets/svg-icons/Icon-list-check.svg';
import {ReactComponent as IncreaseIcon} from '../../assets/svg-icons/Icon-increase.svg';
import {ReactComponent as TruckIcon} from '../../assets/svg-icons/Icon-truck.svg';
import {ReactComponent as DocIcon} from '../../assets/svg-icons/Icon-documents.svg';
import {ReactComponent as TicketIcon} from '../../assets/svg-icons/Icon-ticket.svg';
import {FormattedMessage} from 'react-intl';
import {BreadCrumbDate} from '../../components/BreadCrumbDate';
import moment from 'moment';
import ChartTooltipFlyout from "../../components/charts/ChartTooltipFlyout";
import AnalyticsLabelFlyout from "../../components/charts/AnalyticsFlyout";
import {RegionCooperativePicker} from './components/RegionCooperativePicker';

const CHART_WIDTH = 600;
const CHART_HEIGHT = 300;
const dateFormat = 'YYYY-MM-DD';

const getCSSColor = () => 'url(#blueGrad)';
const blueStartColor = '#BBCAF1';
const blueEndColor = '#4976EF';

const randomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const CustomTickLabel = ({x, y, data, datum}) => {
  const extractedData = data[datum - 1];
  const imageURL = extractedData ? extractedData.tooltipData.user.photoLink : undefined;
  const username = extractedData ? extractedData.tooltipData.user.name : '';
  return (
    <g>
      <svg x={x - 11} y={y}>
        <defs>
          <clipPath id="myCircle">
            <circle cx="11" cy="11" r="11"/>
          </clipPath>
        </defs>
        {
          imageURL && (
            <image
              x={0}
              y={0}
              href={imageURL}
              height={22}
              width={22}
              clipPath="url(#myCircle)"
              preserveAspectRatio="xMidYMid slice"
            />
          )
        }
        {
          data.length && !imageURL && (
            <g>
              <circle cx="11" cy="11" r="11"/>
              <text
                x={11}
                y={11}
                textAnchor="middle"
                alignmentBaseline="central"
                style={{fontSize: 8, fill: '#fff'}}
              >
                {username.charAt(0)}
              </text>
            </g>
          )
        }
      </svg>
    </g>
  )
};

export function Collection() {
  const [enabledTab, setEnabledTab] = useState('quality');
  const [dataQuality, setDataQuality] = useState([]);
  const [dataVolume, setDataVolume] = useState([]);
  const [dataCollect, setDataCollect] = useState([]);
  const [dataTicket, setDataTicket] = useState([]);
  const [dataDocuments, setDataDocuments] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [loading, setLoading] = useState(true);
  let firstLoad = true;
  const [searchDate, setSearchDate] = useState({
    initialDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
    finalDate: moment().format('YYYY-MM-DD')
  });

  const fetchData = async () => {
    setLoading(true);
    const p = await Promise.all([
      data.get(`/analytics/screenview?initdate=${searchDate.initialDate}&enddate=${searchDate.finalDate}&screen=Quality&cachetick=${Math.random()}`),
      data.get(`/analytics/screenview?initdate=${searchDate.initialDate}&enddate=${searchDate.finalDate}&screen=Volume&cachetick=${Math.random()}`),
      data.get(`/analytics/screenview?initdate=${searchDate.initialDate}&enddate=${searchDate.finalDate}&screen=Collect&cachetick=${Math.random()}`),
      data.get(`/analytics/screenview?initdate=${searchDate.initialDate}&enddate=${searchDate.finalDate}&screen=Ticket&cachetick=${Math.random()}`),
      data.get(`/analytics/screenview?initdate=${searchDate.initialDate}&enddate=${searchDate.finalDate}&screen=Documents&cachetick=${Math.random()}`),
    ]);

    if (p && p[0] && p[0].data) {
      setCurrentData(p[0].data.map(quality => {
        return {
          x: quality.name,
          y: quality.views,
          label: 'label 1',
          tooltipData: {
            user: {
              name: quality.name,
              photoLink: (quality.profile && quality.profile.photoLink) ? (quality.profile && quality.profile.photoLink) : defaultPic,
              color: randomColor()
            },
            cooperative: quality.cooperative
          }
        }
      }));
      setDataQuality(p[0].data.map(quality => {
        return {
          x: quality.name,
          y: quality.views,
          label: 'label 1',
          tooltipData: {
            user: {
              name: quality.name,
              photoLink: (quality.profile && quality.profile.photoLink) ? (quality.profile && quality.profile.photoLink) : defaultPic,
              color: randomColor()
            },
            cooperative: quality.cooperative
          }
        }
      }));
      setDataVolume(p[1].data.map(volume => {
        return {
          x: volume.name,
          y: volume.views,
          label: 'label 1',
          tooltipData: {
            user: {
              name: volume.name,
              photoLink: (volume.profile && volume.profile.photoLink) ? (volume.profile && volume.profile.photoLink) : defaultPic,
              color: randomColor()
            },
            cooperative: volume.cooperative
          }
        }
      }));
      setDataCollect(p[2].data.map(collect => {
        return {
          x: collect.name,
          y: collect.views,
          label: 'label 1',
          tooltipData: {
            user: {
              name: collect.name,
              photoLink: (collect.profile && collect.profile.photoLink) ? (collect.profile && collect.profile.photoLink) : defaultPic,
              color: randomColor()
            },
            cooperative: collect.cooperative
          }
        }
      }));
      setDataTicket(p[3].data.map(ticket => {
        return {
          x: ticket.name,
          y: ticket.views,
          label: 'label 1',
          tooltipData: {
            user: {
              name: ticket.name,
              photoLink: (ticket.profile && ticket.profile.photoLink) ? (ticket.profile && ticket.profile.photoLink) : defaultPic,
              color: randomColor()
            },
            cooperative: ticket.cooperative
          }
        }
      }));
      setDataDocuments(p[4].data.map(document => {
        return {
          x: document.name,
          y: document.views,
          label: 'label 1',
          tooltipData: {
            user: {
              name: document.name,
              photoLink: (document.profile && document.profile.photoLink) ? (document.profile && document.profile.photoLink) : defaultPic,
              color: randomColor()
            },
            cooperative: document.cooperative
          }
        }
      }));

      setLoading(false);
    } else {
      console.debug('Error no data was returned from service. /analytics/')
    }
  };

  function selectCurrentData() {
    switch (enabledTab) {
      case 'quality': setCurrentData(dataQuality); return dataQuality;
      case 'volume': setCurrentData(dataVolume); return dataVolume;
      case 'collect': setCurrentData(dataCollect); return dataCollect;
      case 'ticket': setCurrentData(dataTicket); return dataTicket;
      case 'document': setCurrentData(dataDocuments); return dataDocuments;
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [searchDate, setSearchDate]);

  useEffect(() => {
    selectCurrentData();
  }, [enabledTab]);

  return (
    <>
      <BreadCrumbDate
        onChange={(value) => {
          if (firstLoad && value && value.setFinalDate && value.setInitialDate) {
            firstLoad = false;
            value.setFinalDate(null);
            value.setInitialDate(null);
          } else if (value.initialDate && value.finalDate) {
            setSearchDate({
              initialDate: moment(value.initialDate).format(dateFormat),
              finalDate: moment(value.finalDate).format(dateFormat)
            });
          }
        }}
      />
      <CheckedTabsWrapper>
        <CheckedTab
          icon={<ListCheckIcon fill={enabledTab === 'quality' ? '#41b049' : '#657FA0'} width={18}/>}
          active={enabledTab === 'quality'}
          onClick={() => setEnabledTab('quality')}>
          <FormattedMessage id='tabs.quality'/>
        </CheckedTab>
        <CheckedTab
          icon={<IncreaseIcon fill={enabledTab === 'volume' ? '#41b049' : '#657FA0'} width={18}/>}
          active={enabledTab === 'volume'}
          onClick={() => setEnabledTab('volume')}>
          <FormattedMessage id='tabs.volume'/>
        </CheckedTab>
        <CheckedTab
          icon={<TruckIcon fill={enabledTab === 'collect' ? '#41b049' : '#657FA0'} width={18}/>}
          active={enabledTab === 'collect'}
          onClick={() => setEnabledTab('collect')}>
          <FormattedMessage id='tabs.collect'/>
        </CheckedTab>
        <CheckedTab
          icon={<TicketIcon fill={enabledTab === 'ticket' ? '#41b049' : '#657FA0'} width={22}/>}
          active={enabledTab === 'ticket'}
          onClick={() => setEnabledTab('ticket')}>
          <FormattedMessage id='tabs.ticket'/>
        </CheckedTab>
        <CheckedTab
          icon={<DocIcon fill={enabledTab === 'document' ? '#41b049' : '#657FA0'} width={18}/>}
          active={enabledTab === 'document'}
          onClick={() => setEnabledTab('document')}>
          <FormattedMessage id='tabs.document'/>
        </CheckedTab>
      </CheckedTabsWrapper>

      {
        loading ?
          (
            <div className='row'>
              <div className='col-9'>
                <div className='card'>
                  <Table
                    columns={[]}
                    isLoading={true}
                    data={[]}
                    offsetTop={345}
                  />
                </div>
              </div>
            </div>
          ) :
          <div className='card'>
            <RegionCooperativePicker
              onCooperativeChanged={(ans) => {
                if (!ans) {
                  return;
                }
                // All
                if (ans.value === 0) {
                  selectCurrentData();
                  return;
                }
                const filteredData = _.filter(selectCurrentData(), (el) => {
                  return el.tooltipData.cooperative === ans.value;
                });
                setCurrentData(filteredData);
              }}
              // onRegionChanged={onRegionChanged}
            />
            <div style={{padding: '0 40px'}}>
              {
                currentData && (
                  <VictoryChart
                    width={CHART_WIDTH}
                    height={CHART_HEIGHT}
                    domain={{y: [0, 100]}}
                    domainPadding={{x: 10, y: 0}}
                    theme={Theme}
                  >
                    <defs>
                      <linearGradient id="blueGrad" x1="0" y1="0" x2="0" y2="2">
                        <stop offset="0" stopColor={blueStartColor} stopOpacity="1"/>
                        <stop offset="1" stopColor={blueEndColor} stopOpacity="1"/>
                      </linearGradient>
                    </defs>
                    <VictoryBar
                      animate={{duration: 300}}
                      style={{
                        data: {
                          fill: () => getCSSColor(),
                          fillOpacity: 0.25,
                          width: 15
                        },
                        labels: {
                          fontSize: 15,
                          fill: () => getCSSColor()
                        }
                      }}
                      cornerRadius={{topLeft: 4, topRight: 4}}
                      events={[{
                        target: 'data',
                        eventHandlers: {
                          onClick: () => [
                            {
                              target: 'data',
                              mutation: () => ({active: true})
                            }
                          ]
                        }
                      }]}
                      data={currentData}
                      labelComponent={<VictoryTooltip
                        flyoutComponent={<ChartTooltipFlyout chartHeight={CHART_HEIGHT} arrowOrientation="horizontal"/>}
                        labelComponent={<AnalyticsLabelFlyout chartHeight={CHART_HEIGHT} data={currentData} />}
                      />}
                    />
                    <VictoryAxis
                      dependentAxis
                      style={{
                        axis: {
                          stroke: 'transparent',
                        },
                        grid: {
                          stroke: 'grey',
                          strokeDasharray: '1,3',
                        },
                        tickLabels: {
                          fontSize: 10,
                          fill: '#657FA0'
                        },
                        axisLabel: {
                          fontSize: 10,
                          fill: '#657FA0',
                          padding: 35
                        }
                      }}
                      tickValues={[
                        0,
                        10,
                        20,
                        30,
                        40,
                        50,
                        60,
                        70,
                        80,
                        90,
                        100
                      ]}
                      label="Number of access"
                    />
                    <VictoryAxis
                      style={{
                        axis: {
                          stroke: 'transparent'
                        },
                        ticks: {
                          stroke: 'black',
                          strokeWidth: 1
                        },
                        tickLabels: {
                          fontSize: 8,
                          fill: '#657FA0',
                          padding: 5
                        },
                      }}
                      tickCount={currentData.length}
                      tickLabelComponent={<CustomTickLabel data={currentData}/>}
                    />
                  </VictoryChart>
                )
              }
            </div>
          </div>
      }
    </>
  )
}
