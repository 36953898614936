import React from 'react';

const style = {
  minWidth: 432,
  boxShadow: 'rgba(101, 127, 169, 0.3) 0px 20px 36px -15px',
  marginTop: 60,
  marginBottom: -16,
  overflow: 'visible',
  border: 'none'
};

export function CardSignedOut({children}) {
  return (
    <div className='card px-5 py-4' style={style}>
      {children}
      <div className='p-4'></div>
    </div>
  )
}
