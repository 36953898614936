import React from "react";

const arrowPath = {
  vertical: {
    normal: '65,79 72.5,90 80,79',
    variant: '65,79 72.5,90 80,79'
  },
  horizontal: {
    normal: '5,42.5 15,35 15,50',
    variant: '10,42.5 0,35 0,50'
  },
};

const CHART_PADDING = 50;

class ChartLineTooltipFlyout extends React.Component {
  render() {
    const { x, y, arrowOrientation, chartHeight, datum: { color }} = this.props;
    const posX = x - 58;
    const posY = y - 92;
    const trianglePath = arrowPath[arrowOrientation].normal;
    return (
      <g>
        <filter id="dropshadow" height="130%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3"/> {/* stdDeviation is how much to blur */}
          <feOffset dx="2" dy="2" result="offsetblur"/> {/* how much to offset */}
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.5"/> {/* slope is the opacity of the shadow */}
          </feComponentTransfer>
          <feMerge>
            <feMergeNode/> {/* this contains the offset blurred image */}
            <feMergeNode in="SourceGraphic"/> {/* this contains the element that the filter is applied to */}
          </feMerge>
        </filter>
        <filter id="dropshadowTriangle" height="130%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="1"/> {/* stdDeviation is how much to blur */}
          <feOffset dx="-2" dy="2" result="offsetblur"/> {/* how much to offset */}
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.5"/> {/* slope is the opacity of the shadow */}
          </feComponentTransfer>
          <feMerge>
            <feMergeNode/> {/* this contains the offset blurred image */}
            <feMergeNode in="SourceGraphic"/> {/* this contains the element that the filter is applied to */}
          </feMerge>
        </filter>
        <rect
          className='flyout'
          fill="white"
          style={{ filter:'url(#dropshadow)' }}
          x={posX}
          y={posY}
          rx={6}
          ry={6}
          width={120}
          height={76}
        />
        <svg
          x={posX - 14}
          y={posY - 4}
        >
          <polygon
            fill={'white'}
            points={trianglePath}
            style={{
              filter:'url(#dropshadowTriangle)',
            }}
          />
        </svg>
        <line x1={x} y1={y} x2={x} y2={y + (chartHeight - y - CHART_PADDING)} stroke={`${color}4d` }/>
      </g>
    )
  }
}

export default ChartLineTooltipFlyout;
