import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CardSignedOut } from "../../components/CardSignedOut";
import logo from "../../whitelabel/logo.jpg";
import { application } from "../../helpers/axiosModules";
import Loading from "../../components/loading/Loading";
import { FormattedMessage } from "react-intl";
import { Modal } from "../../components/Modal";
import { ReactComponent as IconBack } from "../../assets/svg-icons/Icon-baseline-keyboard_backspace-white.svg";
import { authBackoffice } from "../../api/routes-app";

export function ForgotPassword({ history }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const sendToken = (e) => {
    e.preventDefault();
    console.debug(email);
    setLoading(true);
    application
      .post(`${authBackoffice()}?sendlink=true&email=${email}`, {})
      .then(() => {
        setLoading(false);
        setOpen(true);
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div>
        <CardSignedOut>
          <Link className="floating-back-btn" to="/account/sign-in">
            <IconBack fill="#41B049" />
          </Link>
          <div className="text-center">
            <div className="p-1">
              <img alt="" src={logo} />
            </div>
            <div className="p-1">
              <h6>
                <FormattedMessage id="recover_password.title" />
              </h6>
              <div
                style={{
                  maxWidth: "80%",
                  margin: "28px auto",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    width: 45,
                    backgroundColor: "#6BC171",
                    height: 2,
                    margin: "20px auto",
                  }}
                />
                <FormattedMessage id="recover_password.message" />
              </div>
            </div>
          </div>
          <form onSubmit={sendToken}>
            <div>
              <FormattedMessage id="recover_password.email">
                {(text) => {
                  return (
                    <input
                      className="form-control"
                      onChange={({ target }) => setEmail(target.value)}
                      placeholder={text}
                      required
                    />
                  );
                }}
              </FormattedMessage>
            </div>
            <div className="pt-3 pb-5">
              <button className="btn btn-success py-3 w-100">
                <FormattedMessage id="recover_password.request_new_password" />
              </button>
            </div>
          </form>
        </CardSignedOut>
      </div>
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        loading={loading}
      >
        <div
          style={{
            overflowX: "unset",
            overflowY: "auto",
            maxHeight: "calc(80vh - 60px)",
          }}
        >
          <h6>
            <FormattedMessage id="recover_password.warn" />
          </h6>
          <div className="pt-4 pb-2">
            <FormattedMessage id="recover_password.warn_info" />
          </div>
          <div className="pt-5 text-right">
            <button
              className="btn btn-success px-5"
              onClick={() => history.goBack()}
            >
              <FormattedMessage id="recover_password.back" />
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
