import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Switch,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { CustomCard } from "../../../components/CustomCard";
import RulesFieldArray from "./nested-field-array";
import { prepareTriggerRuleData } from "./range";
import { variables } from "../../../whitelabel/variables";
export { triggerRuleToFormRange } from "./range";

export function NotificationForm({ formSubmit, hookForm, allowedOptions }) {
  const intl = useIntl();

  const metricsOptions = [
    { value: "QUALITY_CCS", label: "CCS" },
    {
      value: "QUALITY_TBC",
      label: variables.COMPANY === "DPA" ? "UFC" : "CBT",
    },
    {
      value: "QUALITY_FAT",
      label: intl.formatMessage({ id: "notification.fat" }),
    },
    {
      value: "QUALITY_PROTEIN",
      label: intl.formatMessage({ id: "notification.protein" }),
    },
    {
      value: "COLLECT_TEMPERATURE",
      label: intl.formatMessage({ id: "notification.temperature" }),
    },
  ];
  const prefixOptions = [
    {
      value: "QUALITY",
      label: intl.formatMessage({ id: "notification.quality" }),
    },
    {
      value: "COLLECT",
      label: intl.formatMessage({ id: "notification.collect" }),
    },
  ];

  allowedOptions = !allowedOptions ? metricsOptions : allowedOptions;

  const { handleSubmit, register, control, watch, setValue, formState } =
    hookForm;

  const [metricOptions, setMetricOptions] = useState(metricsOptions);
  const [prefixTarget, setPrefixTarget] = useState("");

  const {
    fields: triggerRulesFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "triggerRules",
  });


  const triggerRules = watch("triggerRules");

  const submitWrapper = (d) => {
    formSubmit({
      title: d.title,
      body: d.body,
      kind: d.kind,
      triggerRules: prepareTriggerRuleData({
        triggerRules: d?.triggerRules,
      }),
    });
  };

  useEffect(() => {
    if (triggerRules?.length) {
      if (triggerRules[0]?.metric) {
        setPrefixTarget(triggerRules[0]?.metric.split("_")[0]);
      }
    }
  }, [triggerRules]);

  useEffect(() => {
    if (triggerRules?.length) {
      if (
        triggerRules.find(
          (e) => e?.metric && e?.metric.split("_")[0] !== prefixTarget
        )
      ) {
        setValue("triggerRules", []);
      }
    }
  }, [prefixTarget]);

  return (
    <>
      <form onSubmit={handleSubmit(submitWrapper)} className="p-5">
        <CustomCard styles="mb-5">
          <h6 className="card-title dashed-green mb-5">
            <FormattedMessage id="notification.form.label" />
          </h6>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Controller
                as={TextField}
                name="title"
                label={<FormattedMessage id="notification.form.title" />}
                variant="outlined"
                fullWidth
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Controller
                className="w-100"
                style={{ height: 55 }}
                as={TextField}
                name="body"
                label={<FormattedMessage id="notification.form.message" />}
                variant="outlined"
                fullWidth
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Controller
                render={({ onChange, onBlur, value, name }) => (
                  <Switch
                    checked={value === "PUSH"}
                    name={name}
                    onBlur={onBlur}
                    onChange={(e) =>
                      onChange(e.target.checked ? "PUSH" : "ALERT")
                    }
                  />
                )}
                name="kind"
                fullWidth
                control={control}
              />
              <label>Push</label>
            </Grid>
          </Grid>
        </CustomCard>
        <CustomCard style={{ height: 280 }}>
          <div
            className="d-flex align-items-center justify-content-between h-50 w-100"
            style={{ marginBottom: 20 }}
          >
            <h6 className="card-title dashed-green mb-0">
              <FormattedMessage id="notification.form.label_rules" />
            </h6>
            <Grid container>
              <Grid
                item
                justify="center"
                alignItems="center"
                xs={12}
                sm={12}
                md={2}
                container
              >
                <Select
                  value={prefixTarget}
                  onChange={(e) => {
                    setPrefixTarget(e.target.value);
                  }}
                >
                  {prefixOptions.map(({ value, label }) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Tooltip
              placement="top"
              title={<FormattedMessage id="notification.form.add_rules" />}
              arrow
            >
              <IconButton
                onClick={() => {
                  append();
                }}
                color="secondary"
              >
                <MdAddCircleOutline size={32} color="#37933c" />
              </IconButton>
            </Tooltip>
          </div>
          {triggerRulesFields.map((item, index) => (
            <Grid container spacing={3} key={item.id}>
              <Grid
                item
                justify="center"
                alignItems="center"
                xs={12}
                sm={12}
                md={1}
                container
              >
                <Tooltip
                  placement="top"
                  title={
                    <FormattedMessage id="notification.form.remove_rules" />
                  }
                  arrow
                >
                  <IconButton
                    onClick={() => {
                      remove(index);
                    }}
                    color="secondary"
                  >
                    <MdRemoveCircleOutline size={32} color="#c0392b" />
                  </IconButton>
                </Tooltip>
                <Typography>
                  {index > 0
                    ? intl.formatMessage({
                        id: "condition.elseif",
                      })
                    : intl.formatMessage({ id: "condition.if" })}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <Controller
                  as={
                    <Select
                      placeholder={intl.formatMessage({
                        id: "notification.indicador",
                      })}
                    >
                      {metricOptions.map(({ value, label }) => {
                        return (
                          value.includes(prefixTarget) && (
                            <MenuItem value={value} key={value}>
                              {label}
                            </MenuItem>
                          )
                        );
                      })}
                    </Select>
                  }
                  fullWidth
                  variant="outlined"
                  name={`triggerRules[${index}].metric`}
                  control={control}
                  defaultValue={item.metric}
                />
              </Grid>
              {triggerRules && triggerRules[index]?.metric && (
                <RulesFieldArray
                  parentName="triggerRules"
                  nestIndex={index}
                  control={control}
                  watch={watch}
                />
              )}
            </Grid>
          ))}
        </CustomCard>
        <Grid container spacing={3} className="mt-5">
          <Grid item xs={12} sm={12} md={12}>
            <div className="border-top p-4 d-flex justify-content-around">
              <Link to={`/notifications`}>
                <button
                  className="btn btn-outline-secondary p-4"
                  style={{ width: 270 }}
                >
                  <FormattedMessage id="common.cancel" />
                </button>
              </Link>
              <button
                type="submit"
                className="btn btn-success p-4"
                style={{ width: 270 }}
              >
                {formState.isSubmitting && <CircularProgress />}
                {!formState.isSubmitting && (
                  <FormattedMessage id="common.confirm" />
                )}
              </button>
            </div>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
