import React, { useContext, useEffect, useState, useRef } from "react";
import { Table } from "../../components/Table";
import { FormattedMessage, useIntl } from "react-intl";
import { User } from "../../components/User";
import { BreadcrumbContext } from "./SettingsWrapper";
import { ReactComponent as EditIcon } from "../../assets/svg-icons/Icon-edit.svg";
import { Link } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../assets/svg-icons/Icon-trash.svg";
import { ActionMenu } from "../../components/ActionMenu";
import { IconWithText } from "../../components/IconWithText";
import { application } from "../../helpers/axiosModules";
import { ReactComponent as MoreIcon } from "../../assets/svg-icons/Icon-more.svg";
import { Modal } from "../../components/Modal";

import { ReactComponent as InfoIcon } from "../../assets/svg-icons/Icon-info.svg";
import CustomFilter, {
  resetTableSearchAndFilter,
} from "../../components/CustomFilter";
import { permissionsAll, permissionsRemove } from "../../api/routes-app";

export function PermissionsPage({ history }) {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [permissionToDelete, setPermissionToDelete] = useState();
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);
  const intl = useIntl();
  const tableRef = useRef(null);

  useEffect(() => {
    (async () => {
      setPermissions(
        (
          await application.get(
            `${permissionsAll()}?cachetick=${Math.random()}`
          )
        ).data
      );
      setLoading(false);
    })();

    setBreadcrumbRightContent({
      rightContent: (
        <Link className="btn btn-light green-text px-5" to="add-permission">
          <FormattedMessage id="settings.add_permission" />
        </Link>
      ),
      resetTableSearchAndFilter: () => resetTableSearchAndFilter(tableRef),
    });
  }, []);

  function handleDelete() {
    setLoading(true);
    application.delete(permissionsRemove(permissionToDelete._id)).then(() => {
      setPermissionToDelete(null);
      setLoading(false);
      history.push("/permissions");
      setTimeout(() => {
        history.push("/settings/permissions");
      }, 100);
    });
  }

  return (
    <>
      <Table
        tableRef={tableRef}
        columns={[
          {
            title: <FormattedMessage id="common.name" />,
            field: "name",
            disableClick: true,
            sorting: false,
            render: (props) => <User user={props} />,
          },
          {
            title: <FormattedMessage id="common.users" />,
            disableClick: true,
            sorting: false,
            field: "users",
          },
          {
            title: "",
            field: "actions",
            sorting: false,
            render: (rowData) => (
              <div className="d-flex justify-content-end">
                <ActionMenu
                  trigger={
                    <button className="btn px-3 py-2">
                      <MoreIcon />
                    </button>
                  }
                  content={
                    <div className="px-3 py-2 child-svg-color-1">
                      <Link
                        className="btn w-100"
                        to={`/settings/edit-permission/${rowData._id}`}
                      >
                        <IconWithText
                          icon={<EditIcon />}
                          text={<FormattedMessage id="common.edit" />}
                          reset={true}
                        />
                      </Link>
                      <button
                        className="btn w-100"
                        onClick={() => setPermissionToDelete(rowData)}
                      >
                        <IconWithText
                          icon={<DeleteIcon />}
                          text={<FormattedMessage id="common.delete" />}
                          reset={true}
                        />
                      </button>
                    </div>
                  }
                />
              </div>
            ),
          },
        ]}
        toolbar={false}
        isLoading={loading}
        data={permissions}
      />
      <Modal
        isOpen={!!permissionToDelete}
        onRequestClose={() => {
          setPermissionToDelete(null);
        }}
        onConfirm={handleDelete}
      >
        <div className="d-flex flex-column align-items-center text-center">
          <div
            className="px-3 py-2 border-radius mb-5"
            style={{ backgroundColor: "#f5f6fa", fill: "#657fa0" }}
          >
            <InfoIcon />
          </div>
          <h6 className="text-bold">
            <FormattedMessage id="settings.remove_notification_confirm" />
          </h6>
          <div className="py-4">
            {permissionToDelete ? permissionToDelete.name : ""}
          </div>
        </div>
      </Modal>
    </>
  );
}
