import React from 'react';
import {VictoryAxis, VictoryChart, VictoryLine, VictoryScatter, VictoryTooltip, VictoryVoronoiContainer} from 'victory';
import Theme from '../../utils/Chart.theme';
import {BadgeTooltip} from './ChartTooltip';
import ChartNoData from "./ChartNoData";
import ChartLineTooltipFlyout from './ChartLineTooltipFlyout';
import {ChartLineTooltip} from './ChartLineTooltip';


const lateYearColor = '#3BD6AA';
const lastYearColor = '#4976EF';
const currentYearColor = '#F26894';

const getLastMonthWithValue = (dataYear) => {
  let lastMonth = 0;
  dataYear.forEach((item) => {
    if (item.y > 0 && item.x >= lastMonth) {
      lastMonth = item.x;
    }
  });
  return lastMonth;
};

const setHundredTick = (type, t) => ((type === 'SCC' || type === 'TBC') ? `${t / 1000}k` : t);

const renderLateScatter = (dataLateYear, selectedYear) => {
  const monthWithValue = getLastMonthWithValue(dataLateYear);
  if (monthWithValue) {
    return (
      <VictoryScatter
        data={dataLateYear}
        dataComponent={(
          <BadgeTooltip
            lastMonth={monthWithValue}
            color={lateYearColor}
            year={selectedYear - 2}
          />
        )}
        style={{labels: {fill: 'none'}}}
      />
    );
  }
  return null;
};

const renderLastScatter = (dataLastYear, selectedYear) => {
  const monthWithValue = getLastMonthWithValue(dataLastYear);
  if (monthWithValue) {
    return (
      <VictoryScatter
        data={dataLastYear}
        dataComponent={(
          <BadgeTooltip
            lastMonth={monthWithValue}
            color={lastYearColor}
            year={selectedYear - 1}
          />
        )}
        style={{labels: {fill: 'none'}}}
      />
    );
  }
  return null;
};

const renderCurrentScatter = (dataYear, selectedYear) => {
  const monthWithValue = getLastMonthWithValue(dataYear);
  if (monthWithValue) {
    return (
      <VictoryScatter
        data={dataYear}
        dataComponent={(
          <BadgeTooltip
            lastMonth={monthWithValue}
            color={currentYearColor}
            year={selectedYear}
          />
        )}
        style={{labels: {fill: 'none'}}}
      />
    );
  }
  return null;
};

const getYTickValues = (type) => {
  switch (type) {
    case 'SCC':
      return [
        0,
        200000,
        400000,
        600000,
        800000,
        1000000,
        1200000,
        1400000,
        1600000,
        1800000,
        2000000,
        2200000,
      ];
    case 'TBC':
      return [
        0,
        60000,
        120000,
        180000,
        240000,
        300000,
        360000,
        420000,
        480000,
        540000,
        600000,
        660000,
      ];
    case 'FAT':
      return [
        0.0,
        0.5,
        1.0,
        1.5,
        2.0,
        2.5,
        3.0,
        3.5,
        4.0,
        4.5,
        5.0,
        5.5,
        6.0
      ];
    case 'PROTEIN':
      return [
        0.0,
        0.5,
        1.0,
        1.5,
        2.0,
        2.5,
        3.0,
        3.5,
        4.0,
        4.5,
        5.0,
        5.5,
        6.0
      ];
    default:
      return undefined;
  }
};

const getXTickValues = () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const getXTickFormat = () => [
  'jan',
  'fev',
  'mar',
  'abr',
  'mai',
  'jun',
  'jul',
  'ago',
  'set',
  'out',
  'nov',
  'dec',
];

// const getDomain = (type) => {
//   switch (type) {
//     case 'SCC':
//       return {y: [0, 800000]};
//     case 'TBC':
//       return {y: [0, 1200000]};
//     case 'FAT':
//       return {y: [0, 6]};
//     case 'PROTEIN':
//       return {y: [0, 5]};
//     default:
//       return undefined;
//   }
// };

const getMaxDomain = (type) => {
  switch (type) {
    case 'SCC':
      return {y: 2200000};
    case 'TBC':
      return {y: 660000};
    case 'FAT':
      return {y: 6};
    case 'PROTEIN':
      return {y: 6};
    default:
      return undefined;
  }
};

const CHART_WIDTH = 600;
const CHART_HEIGHT = 400;

const QualityYearlyChart = ({
  type,
  selectedYear,
  dataYear,
  dataLastYear,
  dataLateYear,
}) => (
  <div
    style={{
      position: 'relative'
    }}
  >
    {getLastMonthWithValue(dataYear) === 0 && <ChartNoData type={'qualidade'}/>}
    <VictoryChart
      maxDomain={getMaxDomain(type)}
      domainPadding={{y: 30}}
      theme={Theme}
      events={[{
        target: 'data',
        eventHandlers: {
          onClick: () => [
            {
              target: 'data',
              mutation: () => ({active: true})
            }
          ]
        }
      }]}
      containerComponent={(
        <VictoryVoronoiContainer
          labels={({y}) => `${y}`}
          labelComponent={(
            <VictoryTooltip
              flyoutComponent={(
                <ChartLineTooltipFlyout
                  chartHeight={CHART_HEIGHT}
                  arrowOrientation={'vertical'}
                />
              )}
              labelComponent={<ChartLineTooltip chartHeight={CHART_HEIGHT} />}
            />
          )}
        />
      )}
      width={CHART_WIDTH}
      height={CHART_HEIGHT}
    >
      {
        dataYear && (
          <VictoryAxis
            dependentAxis
            standalone={false}
            style={{
              axis: {
                stroke: 'transparent',
              },
              grid: {
                stroke: 'grey',
                strokeDasharray: '1,3',
              },
              tickLabels: {
                fontSize: 11,
                padding: 3,
                color: 'grey'
              }
            }}
            tickValues={getYTickValues(type)}
            tickFormat={t => setHundredTick(type, t)}
          />
        )
      }

      {
        dataYear && (
          <VictoryAxis
            standalone={false}
            style={{
              axis: {
                stroke: 'transparent'
              },
              ticks: {
                stroke: 'black',
                strokeWidth: 1
              },
              tickLabels: {
                fontSize: 12,
                fill: 'grey',
                padding: 5
              },
            }}
            tickValues={getXTickValues()}
            tickFormat={getXTickFormat()}
          />
        )
      }


      {
        dataLateYear && (
          <VictoryLine
            animate={{duration: 200}}
            data={dataLateYear}
            style={{
              data: {stroke: lateYearColor},
            }}
          />
        )
      }

      {
        dataLastYear && (
          <VictoryLine
            animate={{duration: 200}}
            data={dataLastYear}
            style={{
              data: {stroke: lastYearColor},
            }}
          />
        )
      }

      {
        dataYear && (
          <VictoryLine
            animate={{duration: 200}}
            data={dataYear}
            style={{
              data: {stroke: currentYearColor},
            }}
            labelComponent={(
              <VictoryTooltip
                cornerRadius={20}
                pointerLength={20}
              />
            )}
          />
        )
      }

      {
        dataLateYear && renderLateScatter(dataLateYear, selectedYear)
      }

      {
        dataLastYear && renderLastScatter(dataLastYear, selectedYear)
      }

      {
        dataYear && renderCurrentScatter(dataYear, selectedYear)
      }

      <VictoryScatter
        data={dataLateYear}
        style={{
          data: {
            fill: (datum, active) => active ? "white" : "transparent",
            stroke: (datum, active) => active ? lateYearColor : "transparent",
            strokeWidth: 2
          }
        }}
      />

      <VictoryScatter
        data={dataLastYear}
        style={{
          data: {
            fill: (datum, active) => active ? "white" : "transparent",
            stroke: (datum, active) => active ? lastYearColor : "transparent",
            strokeWidth: 2
          }
        }}
      />

      <VictoryScatter
        data={dataYear}
        style={{
          data: {
            fill: (datum, active) => active ? "white" : "transparent",
            stroke: (datum, active) => active ? currentYearColor : "transparent",
            strokeWidth: 2
          }
        }}
      />

    </VictoryChart>
  </div>
);

export default QualityYearlyChart;
