import React from 'react';
import { InputField } from '../../../components/form/InputField';
import { TextareaField } from '../../../components/form/TextareaField';
import { FormattedMessage } from 'react-intl';

export function NotificationInfo() {
  return (
    <fieldset className='pb-5'>
      <legend className='mb-4'><FormattedMessage id='settings.notification_description'/>*</legend>
      <div className='form-group'>
        <InputField maxLength="256" placeholder={<FormattedMessage id='common.title'/>} controlName='title' required={true}/>
      </div>
      <TextareaField placeholder={<FormattedMessage id='settings.notification_text'/>} controlName='description' required={true}/>
    </fieldset>
  )
}
