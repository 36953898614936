import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { RadioButton } from '../../../components/form/RadioButton';

export const TICKET = 'ticket';
export const TEMP = 'temp';

export function ResultsTicketTemp({onChange, view: defaultView}) {
  const [view, setView] = useState(defaultView);

  useEffect(() => {
    if (typeof onChange === 'function') onChange(view);
  }, [view]);

  return (
    <div className='d-flex align-items-center'>     
      <span><FormattedMessage id='common.results'/>:</span>
      <RadioButton
        className='mr-4 ml-4'
        text={<FormattedMessage id='common.ticket'/>}
        formControl='view'
        value={TICKET}
        onChange={e => setView(e.target.value)}
        checked={view === TICKET}
      />
      <RadioButton
        text={<FormattedMessage id='common.temperature'/>}
        formControl='view'
        value={TEMP}
        onChange={e => setView(e.target.value)}
        checked={view === TEMP}
      />
    </div>
  )
}

ResultsTicketTemp.propTypes = {
  onChange: PropTypes.func,
  view: PropTypes.string
};

