import React from "react";

const arrowPath = {
  vertical: {
    normal: '65,79 72.5,90 80,79',
    variant: '65,79 72.5,90 80,79'
  },
  horizontal: {
    normal: '5,42.5 15,35 15,50',
    variant: '10,42.5 0,35 0,50'
  },
};

class ChartTooltipFlyout extends React.Component {
  render() {
    const { x, y, datum: data, getFakePosY, type, chartHeight, arrowOrientation } = this.props;
    const paddingChart = 120;
    const isOverflowingX = (600 - (x + 15 + 120)) <= 0;
    const fakeY = getFakePosY ? getFakePosY(data._y, y, type) : y;
    const barHeight = chartHeight - paddingChart - fakeY;
    const posX = !isOverflowingX ? x + 15 : x - 130;
    const posY = fakeY + (barHeight / 2) ;
    const trianglePath = !isOverflowingX ? arrowPath[arrowOrientation].normal : arrowPath[arrowOrientation].variant;
    return (
      <g>
        <filter id="dropshadow" height="130%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3"/> {/* stdDeviation is how much to blur */}
          <feOffset dx="2" dy="2" result="offsetblur"/> {/* how much to offset */}
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.5"/> {/* slope is the opacity of the shadow */}
          </feComponentTransfer>
          <feMerge>
            <feMergeNode/> {/* this contains the offset blurred image */}
            <feMergeNode in="SourceGraphic"/> {/* this contains the element that the filter is applied to */}
          </feMerge>
        </filter>
        <filter id="dropshadowTriangle" height="130%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="1"/> {/* stdDeviation is how much to blur */}
          <feOffset dx="-2" dy="2" result="offsetblur"/> {/* how much to offset */}
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.5"/> {/* slope is the opacity of the shadow */}
          </feComponentTransfer>
          <feMerge>
            <feMergeNode/> {/* this contains the offset blurred image */}
            <feMergeNode in="SourceGraphic"/> {/* this contains the element that the filter is applied to */}
          </feMerge>
        </filter>
        <rect
          className='flyout'
          fill="white"
          style={{ filter:'url(#dropshadow)' }}
          x={posX}
          y={posY}
          rx={2}
          ry={2}
          width={120}
          height={76}
        />
        <svg
          x={!isOverflowingX ? posX - 14 : posX + 120}
          y={posY - 4}
        >
          <polygon
            fill={'white'}
            points={trianglePath}
            style={{
              filter:'url(#dropshadowTriangle)',
            }}
          />
        </svg>
      </g>
    )
  }
}

export default ChartTooltipFlyout;
