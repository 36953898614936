import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ToggleField } from '../../../components/form/ToggleField';

export function CommunicationType(props) {
  return (
    <fieldset className='pb-3'>
      <legend><FormattedMessage id='settings.communication_type'/>*</legend>
      <div className='d-flex mt-4'>
        <div className='mr-5'>
          <ToggleField controlName='alert' labelText={<FormattedMessage id='common.alert'/>}/>
        </div>
        <div className='ml-3'>
          <ToggleField controlName='push' labelText='Push'/>
        </div>
      </div>
    </fieldset>
  )
}

CommunicationType.propTypes = {

};
