import React, { useState } from "react";
import { FiThumbsUp } from "react-icons/fi";
import CardAnalytcs from "./CardAnalytics";
import { FormattedMessage } from "react-intl";

import {
  Card,
  CardContent,
  Typography,
  Box,
  CardActionArea,
  Slide,
  Dialog,
  makeStyles,
  CircularProgress,
  CardMedia,
  CardHeader,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { ReactComponent as CloseIcon } from "../../../assets/svg-icons/Icon-close.svg";

const useStyles = makeStyles((theme) => ({
  user: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    height: "100%",
  },
  card: {
    height: "100%",
    width: "100%",
    minWidth: 500,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  dialog: {
    width: "100%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
export default function MostLikedPostAtThisMonth({ loading, error, data }) {
  console.log("MostLikedPostAtThisMonth", { loading, error, data });
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading)
    return (
      <div className="d-flex justify-content-center">
        <CircularProgress color="secondary" />;
      </div>
    );

  if (error) {
    return `Error! ${error.message}`;
  }

  if (!data)
    return (
      <CardAnalytcs
        title={<FormattedMessage id="dashboard.without_content" />}
      ></CardAnalytcs>
    );

  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={handleClickOpen}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CardContent>
          <FiThumbsUp size={50} color="#41b049" />
        </CardContent>
        <CardContent
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box component="div">
            <Typography
              className={classes.title}
              color="textSecondary"
              variant="subtitle2"
              gutterBottom
            >
              <FormattedMessage id="dashboard.most_liked_post_month" />
            </Typography>
          </Box>
          <Box
            component="div"
            style={{
              width: 100,
              height: 50,
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography color="secondary" variant="h3">
              {(data?.mostLikedPostAtThisMonth &&
                data?.mostLikedPostAtThisMonth?.likesCount) ||
                "-"}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
      {data?.mostLikedPostAtThisMonth && (
        <Dialog
          className={classes.dialog}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          {data.mostLikedPostAtThisMonth.type === "MAGAZINE" ? (
            <>
              <DialogTitle disableTypography className={classes.dialogTitle}>
                <FormattedMessage id="dashboard.most_liked_post_month" />
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Card className={classes.card}>
                <CardHeader
                  title={`${data.mostLikedPostAtThisMonth.title}`}
                  subheader={`Edição de: ${data.mostLikedPostAtThisMonth.edition}`}
                />
                <CardMedia
                  className={classes.media}
                  image={`${data.mostLikedPostAtThisMonth.cover}`}
                  title="Cover"
                />
                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {data.mostLikedPostAtThisMonth.subtitle}
                  </Typography>
                </CardContent>
              </Card>
            </>
          ) : (
            <>
              <DialogTitle disableTypography className={classes.dialogTitle}>
                <FormattedMessage id="dashboard.most_liked_post_month" />
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Card>
                <CardHeader title={`${data.mostLikedPostAtThisMonth.title}`} />
                {data.mostLikedPostAtThisMonth.images && (
                  <CardMedia
                    className={classes.media}
                    image={`${data.mostLikedPostAtThisMonth.images[0]}`}
                    title="Cover"
                  />
                )}
                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {data.mostLikedPostAtThisMonth.description}
                  </Typography>
                </CardContent>
              </Card>
            </>
          )}
        </Dialog>
      )}
    </Card>
  );
}
