import React, { useRef, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormContext } from './FormContext';

const style = {
  label: {
    pointerEvents: 'none',
    position: 'absolute', left: '1rem',
    padding: '.2rem .3rem', background: '#fff',
    WebkitTransition: 'all 0.15s ease 0s'
  }
};

export function TextareaField({placeholder, controlName, ...rest}) {
  const [active, setActive] = useState();
  const [value, setValue] = useState('');
  const inputRef = useRef(null);

  const { formState, handleInput } = useContext(FormContext);

  useEffect(() => {
    if (!value && formState[controlName]) {
      setActive(true);
      setValue(formState[controlName]);
    }
  }, [formState[controlName]]);

  function handleValue(value) {
    setValue(value);
    handleInput({name: controlName, value: value});
  }

  return (
    <div style={{position: 'relative'}}>
      <textarea
        className='form-control'
        ref={inputRef}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(inputRef.current.value || false)}
        value={value}
        onChange={(e) => handleValue(e.target.value)}
        {...rest}
      />
      <span
        style={{ top: active ? '-1rem' : '.8rem', ...style.label }}
      >
        {placeholder}
      </span>
    </div>
  )
}

TextareaField.propTypes = {
  controlName: PropTypes.string
};
