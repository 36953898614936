import React, { useState, useEffect } from "react";
import { parseJwt } from "../../../helpers/parseJwt";
import { ReactComponent as ArrowDownIcon } from "../../../assets/svg-icons/Icon-arrow-down.svg";
import { NavLink, withRouter } from "react-router-dom";
import { camelCaseString } from "../../../utils/String.utils";
import { UserImage } from "../../UserImage";
import { application } from "../../../helpers/axiosModules";
import { FormattedMessage } from "react-intl";

import defaultPic from "../../../assets/svg-icons/Icon-profile.svg";

import { ReactComponent as IconUser } from "../../../assets/svg-icons/Icon-user.svg";
// import { ReactComponent as IconSettings } from "../../../assets/svg-icons/Icon-settings.svg";
// import { ReactComponent as IconInfo } from "../../../assets/svg-icons/Icon-info.svg";
// import { ReactComponent as IconContract } from "../../../assets/svg-icons/Icon-contract.svg";
import { ReactComponent as IconExit } from "../../../assets/svg-icons/Icon-exit.svg";

import { administratorsGetByIdentification } from "../../../api/routes-app";

import "./UserInfo.scss";

function MenuItem({ text, icon, onClick }) {
  return (
    <div
      className="d-flex justify-content-between p-4 border-top cursor-pointer"
      onClick={onClick}
    >
      <span className="ml-2">{text}</span>
      <span className="mr-2">{icon}</span>
    </div>
  );
}

function UserInfoComponent({ history }) {
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);

  const logOut = () => {
    sessionStorage.removeItem("jwtoken");
    history.push("/account/sign-in");
  };

  useEffect(() => {
    const parsedToken = parseJwt(sessionStorage.getItem("jwtoken"));
    application
      .get(
        `${administratorsGetByIdentification(
          parsedToken.identification
        )}?cachetick=${Math.random()}`
      )
      .then(({ data }) => {
        setUser(data || {});
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div style={{ position: "relative", paddingTop: 2 }}>
      <div
        className="user-info d-flex align-items-center p-1 pr-3 cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <div className="mx-3">
          <UserImage
            src={user && user.photoLink ? user.photoLink : defaultPic}
          />
        </div>
        <div>
          <b>{camelCaseString(user.name)}</b>
        </div>
        <div className="pl-4 child-svg-color-1">
          <ArrowDownIcon />
        </div>
      </div>

      <div
        className="card child-svg-color-1"
        onClick={() => setOpen(false)}
        style={{
          width: 264,
          right: 0,
          boxShadow: "rgba(3, 40, 60, 0.25) 0px 10px 24px 0px",
          position: "absolute",
          zIndex: 100,
          opacity: open ? "1" : "0",
          pointerEvents: open ? "all" : "none",
          top: open ? 44 : 50,
          transition: "all .1s",
        }}
      >
        <div
          className="d-flex flex-column align-items-center py-4"
          style={{ backgroundColor: "#f0f0f5" }}
        >
          <UserImage
            src={user && user.photoLink ? user.photoLink : defaultPic}
            size={60}
          />
          <div className="text-bold mt-3">{camelCaseString(user.name)}</div>
          <small>{user.role || "Administrador"}</small>
        </div>
        <NavLink
          to={`/settings/administrators/${user ? user.identification : ""}`}
        >
          <MenuItem
            text={<FormattedMessage id="userinfo.view_edit_profile" />}
            icon={<IconUser />}
          />
        </NavLink>
        {/* <NavLink to='settings' className='disabled'><MenuItem text={<FormattedMessage id='userinfo.settings'/>} icon={<IconSettings/>}/></NavLink> */}
        {/* <NavLink to='settings' className='disabled'><MenuItem text={<FormattedMessage id='userinfo.privacy'/>} icon={<IconInfo/>}/></NavLink>
        <NavLink to='settings' className='disabled'><MenuItem text={<FormattedMessage id='userinfo.user_contract'/>} icon={<IconContract/>}/></NavLink> */}
        <MenuItem
          text={<FormattedMessage id="userinfo.log_out" />}
          icon={<IconExit />}
          onClick={() => logOut()}
        />
      </div>

      {open && (
        <div
          onClick={() => setOpen(false)}
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 50,
          }}
        ></div>
      )}
    </div>
  );
}

export const UserInfo = withRouter(UserInfoComponent);
