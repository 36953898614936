import React, { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from "./SettingsWrapper";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { SidebarContext } from "../../components/layouts/Main/Main";
import Loading from "../../components/loading/Loading";
import { SettingsSidebar } from "./SettingsSidebar";
import { FormControl } from "../../components/form/FormContext";
import { application } from "../../helpers/axiosModules";
import { InputField } from "../../components/form/InputField";
import { administratorsUpdatePassword } from "../../api/routes-app";

export function EditPassAdministratorPage({ match, history }) {
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);
  const setSidebarChildren = useContext(SidebarContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const intl = useIntl();

  async function handleSubmit(e) {
    e.preventDefault();
    console.debug(data);
    setLoading(true);
    debugger;
    await application
      .put(administratorsUpdatePassword(match.params.id), {
        oldPassword: data.oldPassword,
        password: data.password,
      })
      .then(() => {
        setLoading(false);
        history.push("/settings/administrators");
      });
  }

  useEffect(() => {
    setBreadcrumbRightContent({
      display: [
        intl.formatMessage({
          id: "recover_password.title",
        }),
      ],
      backButton: true,
    });

    setSidebarChildren(null);

    return () => {
      setSidebarChildren(<SettingsSidebar />);
    };
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="card">
      <FormControl
        onChange={(values) => setData(values)}
        values={data}
        onSubmit={handleSubmit}
      >
        <div className="d-flex p-5 mx-5">
          <div className="col-6 pl-5 ml-5">
            <div className="form-group">
              <FormattedMessage id="recover_password.old_password">
                {(text) => {
                  return (
                    <InputField
                      type="password"
                      maxLength="256"
                      placeholder={text}
                      controlName="oldPassword"
                      required={true}
                    />
                  );
                }}
              </FormattedMessage>
            </div>
            <div className="form-group">
              <FormattedMessage id="recover_password.new_password">
                {(text) => {
                  return (
                    <InputField
                      type="password"
                      maxLength="256"
                      placeholder={text}
                      controlName="password"
                      required={true}
                    />
                  );
                }}
              </FormattedMessage>
            </div>
            <div className="form-group">
              <FormattedMessage id="recover_password.confirm_new_password">
                {(text) => {
                  return (
                    <InputField
                      type="password"
                      maxLength="256"
                      placeholder={intl.formatMessage({
                        id: "recover_password.confirm_new_password",
                      })}
                      controlName="newPasswordConfirm"
                      required={true}
                    />
                  );
                }}
              </FormattedMessage>
            </div>
          </div>
        </div>
        <div className="border-top p-4 d-flex justify-content-around">
          <Link to={`/settings/administrators`}>
            <button
              className="btn btn-outline-secondary p-4"
              style={{ width: 270 }}
            >
              <FormattedMessage id="common.cancel" />
            </button>
          </Link>
          <button className="btn btn-success p-4" style={{ width: 270 }}>
            <FormattedMessage id="common.confirm" />
          </button>
        </div>
      </FormControl>
    </div>
  );
}
