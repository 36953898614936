import ptFlag from "../assets/pt-br-flag.png";
import esFlag from "../assets/es-flag.png";
import usFlag from "../assets/en-us-flag.png";
import deFlag from "../assets/de-flag.png";
import frFlag from "../assets/fr-flag.png";
import nlFlag from "../assets/nl-flag.png";

export const langs = {
  pt: { dsc: "Portuguese", img: ptFlag, alt: "Brazil's flag" },
  es: { dsc: "Spanish", img: esFlag, alt: "Spanish flag" },
  en: { dsc: "English", img: usFlag, alt: "United states's flag" },
  de: { dsc: "German", img: deFlag, alt: "German flag" },
  fr: { dsc: "French", img: frFlag, alt: "French Flag" },
  nl: { dsc: "Dutch", img: nlFlag, alt: "Dutch flag" },
};

export const DEFAULT_LOCALE = "en";

export const setLocale = (name) => {
  sessionStorage.setItem("locale", name);
};

export const getLocale = () => {
  return sessionStorage.getItem("locale");
};

const hasLocale = () => {
  return !!getLocale();
};

export const setDefaultLocale = () => {
  if (!hasLocale()) setLocale(DEFAULT_LOCALE);
};

export const convertLocale = (obj, prefix = "") => {
  return Object.entries(obj).reduce((result, [path, value]) => {
    if (typeof value === "string") {
      result[prefix ? `${prefix}.${path}` : path] = value;
    }

    if (typeof value === "object") {
      Object.assign(
        result,
        convertLocale(value, prefix ? `${prefix}.${path}` : path)
      );
    }

    return result;
  }, {});
};

export default {
  DEFAULT_LOCALE,
  setLocale,
  getLocale,
  hasLocale,
  setDefaultLocale,
  convertLocale,
  langs,
};
