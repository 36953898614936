import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  CardActionArea,
  IconButton,
  Box,
  Divider,
  Collapse,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core";

import { MdHome } from "react-icons/md";

const useStyles = makeStyles({
  root: {
    height: 250,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function CardAnalytcs({
  title,
  description,
  children,
  value,
  checked,
  onClick
}) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
        onClick={onClick}
      >
        <CardContent>{children}</CardContent>
        {/* <Divider orientation="vertical" style={{height: 70}} /> */}
        <CardContent
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box component="div">
            <Typography
              className={classes.title}
              color="textSecondary"
              variant="subtitle2"
              gutterBottom
            >
              {title}
            </Typography>
          </Box>
          <Box component="div">
            <Typography variant="h5" component="h2">
              {description}
            </Typography>
          </Box>
          <Box
            component="div"
            style={{
              width: 100,
              height: 50,
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography color="secondary" variant="h3">
              {value}
            </Typography>
          </Box>
          <Box>
            <Collapse in={checked}>
              <Paper elevation={4}>
                <List className={classes.root}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Brunch this weekend?"
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            Ali Connors
                          </Typography>
                          {
                            " — I'll be in your neighborhood doing errands this…"
                          }
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Paper>
            </Collapse>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
