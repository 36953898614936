import React from 'react';

import { Container, CenteredContainer, SpinnerContainer } from './styles';
import Spinner from '../Spinner';
// import { logo } from '../../whitelabel/styles/images';

function Loading() {
  return (
    <Container>
      <CenteredContainer>
        {/*<img*/}
        {/*  src={logo}*/}
        {/*  alt="Ícone reduzido da empresa"*/}
        {/*  width="50px"*/}
        {/*/>*/}
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      </CenteredContainer>
    </Container>
  );
}

export default Loading;
