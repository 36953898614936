import React from "react";

export function CustomCard({ children, styles, custom }) {
  return (
    <div className={styles} style={custom}>
      <div className="card">
        <div className="card-body">{children}</div>
      </div>
    </div>
  );
}
