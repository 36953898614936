import React, { createContext, useState } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { Redirect } from 'react-router-dom';

import './Main.scss';

export const SidebarContext = createContext(() => undefined);

export default React.memo(function Main({children}) {
  const jwtoken = sessionStorage.getItem('jwtoken');
  const [sidebarChildren, setSidebarChildren] = useState(null);

  // let theme = useContext(SidebarContext);

  if (!jwtoken) {
    return <Redirect to='/'/>;
  }

  return (
    <SidebarContext.Provider value={setSidebarChildren}>
      <div className='main-layout'>
        <Header />
        <div className='d-flex content'>
          <Sidebar hide={!sidebarChildren}>
            {sidebarChildren}
          </Sidebar>
          <main className='m-2 p-4 w-100'>
            <div className={!sidebarChildren ? 'container' : ''}>
              {children}
            </div>
          </main>
        </div>
      </div>
    </SidebarContext.Provider>
  )
});
