import React from 'react';
import { FormattedMessage } from 'react-intl';
import NotificationPhone from '../components/NotificationPhone';
import { Link } from 'react-router-dom';

export function CategoryLayout ({children, title, description, icon})  {
  return (
    <div className='card'>
      <div className='d-flex p-5'>
        <div className='col-7'>
          <div className='py-2'>
            {children}
          </div>
        </div>
        <div className='col-5'>
          <NotificationPhone title={title} description={description} icon={icon} />
        </div>
      </div>
      <div className='border-top p-4 d-flex justify-content-around'>
        <Link to={`/settings/notifications`}><button className='btn btn-outline-secondary p-4' style={{width: 270}}><FormattedMessage id='common.cancel'/></button></Link>
        <button type='submit' className='btn btn-success p-4' style={{width: 270}}><FormattedMessage id='common.confirm'/></button>
      </div>
    </div>
  )
}
