import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckBoxIcon } from '../../assets/svg-icons/Icon-check-box.svg';
import { ReactComponent as CheckBoxGreenIcon } from '../../assets/svg-icons/Icon-check-box-green.svg';

export function CheckedTab({children, icon, active, disabled, alignCheckIcon, onClick}) {
  return (
    <div
      className={`card w-100 cursor-pointer ${disabled ? 'disabled' : ''} ${active ? 'active' : ''}`}
      style={{marginRight: '1rem', marginLeft: '1rem'}}
      onClick={onClick}
    >
      <div className={`d-flex justify-content-between align-items-center p-4 ${disabled ? 'disabled' : ''}`}>
        <div className='d-flex w-100 align-items-center'>
          <div className='ml-2 mr-3' style={{marginTop: alignCheckIcon ? '.5rem' : 0}}>
            {active ? <CheckBoxGreenIcon/> : <CheckBoxIcon/>}
          </div>
          <div style={{marginTop: 2, width: '100%'}}>{children}</div>
        </div>
        {icon}
      </div>
    </div>
  )
}

CheckedTab.propTypes = {
  icon: PropTypes.any,
  disabled: PropTypes.bool,
  alignCheckIcon: PropTypes.bool,
  onClick: PropTypes.func
};
