import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import cuid from "cuid";
export default function ({
  parentName,
  nestIndex,
  control,
  register,
  watch,
  errors,
}) {
  const fieldName = `${parentName}[${nestIndex}].ranges`;

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });
  const fieldValues = watch(fieldName);

  return (
    <>
      <Tooltip placement="top" title="Adicionar intervalo" arrow>
        <IconButton
          onClick={() => {
            append();
          }}
          color="secondary"
        >
          <MdAddCircleOutline size={32} color="#37933c" />
        </IconButton>
      </Tooltip>
      {fields.map((item, k) => (
        <Grid
          container
          xs={12}
          sm={12}
          key={item.id}
          alignItems="center"
          style={{ paddingBottom: "1em", paddingLeft: "3rem" }}
        >
          <Grid item>
            <Tooltip placement="top" title="Remover intervalo" arrow>
              <IconButton
                onClick={() => {
                  remove(k);
                }}
                color="secondary"
              >
                <MdRemoveCircleOutline size={32} color="#c0392b" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={12} md={3} container>
            <Controller
              as={
                <Select>
                  {/* <MenuItem value="GT_(]">
                    <FormattedMessage id="condition.biggerthanthe" />
                  </MenuItem> */}
                  <MenuItem value="GTE_[]">
                    <FormattedMessage id="condition.greaterorequalto" />
                  </MenuItem>
                  {/* <MenuItem value="EQ_[]">
                    <FormattedMessage id="condition.sameas" />
                  </MenuItem> */}
                  {/* <MenuItem value="LT_(]">
                    <FormattedMessage id="condition.smallerthanthe" />
                  </MenuItem> */}
                  <MenuItem value="LTE_[]">
                    <FormattedMessage id="condition.lessthanorequalto" />
                  </MenuItem>
                  <MenuItem value="BETWEEN_[]">
                    <FormattedMessage id="condition.isbetweenthe" />
                  </MenuItem>
                </Select>
              }
              fullWidth
              variant="outlined"
              name={`${fieldName}[${k}].condition`}
              control={control}
              defaultValue={item.condition}
            />
          </Grid>
          {fieldValues && fieldValues[k] && fieldValues[k].condition && (
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={1}
              justify="center"
              alignItems="center"
            >
              <Typography>
                {fieldValues[k].condition.startsWith("BETWEEN") ? (
                  <FormattedMessage id="condition.rangeof" />
                ) : (
                  <FormattedMessage id="condition.valueof" />
                )}
              </Typography>
            </Grid>
          )}
          {fieldValues &&
            fieldValues[k] &&
            ["GT", "GTE", "EQ", "BETWEEN"].includes(
              fieldValues[k].condition
                ? fieldValues[k].condition.split("_")[0]
                : false
            ) && (
              <Grid item xs={12} sm={12} md={3} container>
                <Controller
                  as={TextField}
                  name={`${fieldName}[${k}].start`}
                  variant="outlined"
                  fullWidth
                  control={control}
                  defaultValue={item.start}
                />
              </Grid>
            )}
          {fieldValues &&
            fieldValues[k] &&
            ["BETWEEN"].includes(
              fieldValues[k].condition
                ? fieldValues[k].condition.split("_")[0]
                : false
            ) && (
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={1}
                justify="center"
                alignItems="center"
              >
                <Typography>
                  <FormattedMessage id="condition.until" />
                </Typography>
              </Grid>
            )}
          {fieldValues &&
            fieldValues[k] &&
            ["LT", "LTE", "BETWEEN"].includes(
              fieldValues[k].condition
                ? fieldValues[k].condition.split("_")[0]
                : false
            ) && (
              <Grid item xs={12} sm={12} md={3} container>
                <Controller
                  as={TextField}
                  name={`${fieldName}[${k}].end`}
                  variant="outlined"
                  fullWidth
                  control={control}
                  defaultValue={item.end}
                />
              </Grid>
            )}
          {fieldValues &&
            k !== fieldValues.length - 1 &&
            fieldValues[k] &&
            fieldValues[k].condition && (
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={1}
                justify="center"
                alignItems="center"
              >
                <Typography>
                  <FormattedMessage id="condition.or" />
                </Typography>
              </Grid>
            )}
        </Grid>
      ))}
    </>
  );
}
