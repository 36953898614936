import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {QualityCategory} from './categories/QualityCategory';
import {VolumeCategory} from './categories/VolumeCategory';
import {TemperatureCategory} from './categories/TemperatureCategory';
import {MagazinesCategory} from './categories/MagazinesCategory';
import {OthersCategory} from './categories/OthersCategory';
import {NotificationList} from './NotificationList';

export function NotificationsPage() {

  return (
    <>
      <Switch>
        <Route path='/settings/notifications/quality/:id' component={QualityCategory}/>
        <Route path='/settings/notifications/collect/:id' component={VolumeCategory}/>
        <Route path='/settings/notifications/temperature/:id' component={TemperatureCategory}/>
        <Route path='/settings/notifications/magazines/:id' component={MagazinesCategory}/>
        <Route path='/settings/notifications/other/:id' component={OthersCategory}/>
        <Route path='/settings/notifications/quality/add' component={QualityCategory}/>
        <Route path='/settings/notifications/collect/add' component={VolumeCategory}/>
        <Route path='/settings/notifications/temperature/add' component={TemperatureCategory}/>
        <Route path='/settings/notifications/magazines/add' component={MagazinesCategory}/>
        <Route path='/settings/notifications/other/add' component={OthersCategory}/>
        <Route path='/settings/notifications' component={NotificationList} />
      </Switch>
    </>
  )
}
