import React, { useState, useEffect } from "react";
import { CheckedTab } from "../../components/tabs/CheckedTab";
import { CheckedTabsWrapper } from "../../components/tabs/CheckedTabsWrapper";
import { data, communication } from "../../helpers/axiosModules";
import { FormattedMessage } from "react-intl";
import { Table } from "../../components/Table";
import { camelCaseString } from "../../utils/String.utils";
import { RankedCollapsible } from "./components/RankedCollapsible";
import { ReactComponent as CalendarIcon } from "../../assets/svg-icons/icon-calendar-grey.svg";
import { ReactComponent as MagazineIcon } from "../../assets/svg-icons/Icon-magazine.svg";
import { ReactComponent as DownloadIcon } from "../../assets/svg-icons/Icon-download.svg";
import Moment from "react-moment";
import { BreadCrumbDate } from "../../components/BreadCrumbDate";
import moment from "moment";

const CheckedTabAccess = ({ children, text, icon, active, onClick }) => (
  <CheckedTab active={active} onClick={onClick}>
    <div className="d-flex justify-content-between align-items-center">
      {text}
      <div style={{ display: "flex", flex: 1, margin: "0 10%" }}>
        {children}
      </div>
      {icon}
    </div>
  </CheckedTab>
);

export function MagazinesPage() {
  const [totals, setTotals] = useState([
    {
      total_publications: "-",
      downloaded_magazines: "-",
    },
  ]);
  let firstLoad = true;
  const [enabledTab, setEnabledTab] = useState("total_publications");
  const [magazines, setMagazines] = useState([]);
  const [topPublications, setTopPublications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchDate, setSearchDate] = useState({
    initialDate: moment().subtract(6, "months").format("YYYY-MM-DD"),
    finalDate: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    fetchData();
  }, [searchDate, setSearchDate]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const p = await Promise.all([
      communication.get(`/documents?cachetick=${Math.random()}`),
      data.get(
        `/analytics/magazinedownload?initdate=${
          searchDate.initialDate
        }&enddate=${searchDate.finalDate}&cachetick=${Math.random()}`
      ),
    ]);
    setLoading(false);
    setMagazines(
      p[0].data.map((magazine) => {
        return {
          _id: magazine._id,
          title: camelCaseString(magazine.title),
          edition: camelCaseString(magazine.edition),
          createDate: magazine.createDate,
        };
      })
    );

    setTopPublications(
      p[1].data.map((magazine) => {
        return {
          _id: magazine._id,
          title: camelCaseString(magazine.title),
          edition: camelCaseString(magazine.edition),
          createDate: magazine.createDate,
          image_url: magazine.photoLink,
        };
      })
    );
  };

  useEffect(() => {
    setTotals({
      total_publications: magazines.length,
      downloaded_magazines: topPublications.length,
    });
  }, [magazines]);

  return (
    <>
      <BreadCrumbDate
        onChange={(value) => {
          if (
            firstLoad &&
            value &&
            value.setFinalDate &&
            value.setInitialDate
          ) {
            firstLoad = false;
            value.setFinalDate(null);
            value.setInitialDate(null);
          } else if (value.initialDate && value.finalDate) {
            setSearchDate({
              initialDate: moment(value.initialDate).format("YYYY-MM-DD"),
              finalDate: moment(value.finalDate).format("YYYY-MM-DD"),
            });
          }
        }}
      />
      <CheckedTabsWrapper>
        <CheckedTabAccess
          text={<FormattedMessage id="magazines.total_magazines" />}
          active={enabledTab === "total_publications"}
          onClick={() => setEnabledTab("total_publications")}
          icon={
            <MagazineIcon
              fill={enabledTab === "total_publications" ? "#41B049" : "#657FA0"}
            />
          }
        >
          <b style={{ color: "#3D5879", fontSize: 22 }}>
            {totals.total_publications || 0}
          </b>
        </CheckedTabAccess>
        <CheckedTabAccess
          text={<FormattedMessage id="magazines.more_downloaded" />}
          active={enabledTab === "downloaded_magazines"}
          onClick={() => setEnabledTab("downloaded_magazines")}
          icon={
            <DownloadIcon
              fill={
                enabledTab === "downloaded_magazines" ? "#41B049" : "#657FA0"
              }
            />
          }
        >
          <b style={{ color: "#3D5879", fontSize: 22 }}>
            {totals.downloaded_magazines || 0}
          </b>
        </CheckedTabAccess>
      </CheckedTabsWrapper>

      <div className="row pb-3">
        <div className="col-9">
          <div className="card">
            <Table
              columns={[
                {
                  title: "#",
                  disableClick: true,
                  sorting: false,
                  render: (rowData) => <span>{rowData.tableData.id + 1}º</span>,
                },
                {
                  title: <FormattedMessage id="common.title" />,
                  field: "title",
                  disableClick: true,
                  sorting: false,
                },
                {
                  title: <FormattedMessage id="common.edition" />,
                  field: "edition",
                  disableClick: true,
                  sorting: false,
                },
                {
                  title: <FormattedMessage id="common.date" />,
                  disableClick: true,
                  sorting: false,
                  render: (rowData) => (
                    <Moment format="DD-MM-YYYY">{rowData.createDate}</Moment>
                  ),
                  cellStyle: { minWidth: 80 },
                },
              ]}
              isLoading={loading}
              data={
                enabledTab === "total_publications"
                  ? magazines
                  : topPublications
              }
              offsetTop={345}
              toolbar={true}
              search={true}
              pickerFilters={false}
            />
          </div>
        </div>
        <div className="col-3">
          <div className="card full-height">
            <div className="text-fold p-4 text-center text-bold border-bottom">
              <FormattedMessage id="magazines.more_downloaded" />
            </div>
            <div>
              {topPublications.map((publication, i) => (
                <RankedCollapsible
                  trigger={publication.title}
                  numeration={i + 1}
                  key={i}
                >
                  <div className="pl-5 pb-4" style={{ textAlign: "center" }}>
                    <div className="pl-1">
                      <img
                        src={publication.image_url}
                        height={120}
                        alt="imagem de publicação"
                      />
                    </div>
                    <div className="d-flex mt-3">
                      <CalendarIcon className="ml-1 mr-4" />
                      <Moment format="DD-MM-YYYY">
                        {publication.createDate}
                      </Moment>
                    </div>
                  </div>
                </RankedCollapsible>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
