import React, { useEffect, useState } from "react";
import CardAnalytcs from "./CardAnalytics";
import { MdPersonPin } from "react-icons/md";
import { usersCount } from "../../../api/routes-app";
import { FormattedMessage, useIntl } from "react-intl";


export default function ActiveUsers() {
  const [users, setUsers] = useState([]);
  const parsedToken = sessionStorage.getItem("jwtoken");
  const intl = useIntl();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: parsedToken,
      },
    };

    fetch(usersCount(), requestOptions)
      .then((response) => response.json())
      .then((data) =>
        data ===
        intl.formatMessage({ id: "dashboard.unable_to_find_user_count" })
          ? setUsers("-")
          : setUsers(data.count)
      );
  }, []);

  return (
    <CardAnalytcs
      title={<FormattedMessage id="dashboard.count_active_users" />}
      value={users}
    >
      <MdPersonPin size={50} color="#41b049" />
    </CardAnalytcs>
  );
}
