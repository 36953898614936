import React, { useEffect, useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import NotificationsTable from "./NotificationsTable";
import { BreadcrumbContext } from "../../components/BreadCrumb";
import { application } from "../../helpers/axiosModules";
import { notificationGetAll, notificationRemove } from "../../api/routes-app";

const classBtnConfirm = "btn btn-outline-secondary border btn-lg mt-5";
const classBtnCancel = "btn btn-success btn-lg ml-5 mt-5";

Swal.mixin({
  customClass: {
    confirmButton: classBtnCancel,
    cancelButton: classBtnConfirm,
  },
  focusCancel: true,
  buttonsStyling: false,
});

export function Notifications() {
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState({});
  const intl = useIntl();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await application.get(notificationGetAll());
      setData(response.data || []);
    } catch (err) {
      console.log(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: intl.formatMessage({ id: "notification.delete_title" }),
      text: intl.formatMessage({ id: "notification.delete_text" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      reverseButtons: true,
      confirmButtonText: intl.formatMessage({ id: "common.confirm" }),
      backdrop: true,
      focusCancel: true,
    }).then((result) => {
      if (result.value) {
        application.delete(notificationRemove(id)).then(() => {
          Swal.fire(
            intl.formatMessage({ id: "notification.delete_excluded" }),
            intl.formatMessage({ id: "notification.delete_successfully" }),
            "success"
          );
          fetchData();
        });
      }
    });
  };

  useEffect(() => {
    setBreadcrumbRightContent({
      rightContent: (
        <>
          <Link to={`notifications/send`}>
            <div className="btn btn-light green-text px-5 mx-3">
              <FormattedMessage id="settings.send_instant_notification" />
            </div>
          </Link>
          <Link to={`notifications/add`}>
            <div className="btn btn-light green-text px-5">
              <FormattedMessage id="settings.add_notification" />
            </div>
          </Link>
        </>
      ),
    });
  }, []);

  return (
    <NotificationsTable
      data={data}
      loading={loading}
      handleDelete={handleDelete}
    />
  );
}
