import React, { useContext, useEffect, createContext, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { BreadCrumb } from '../../components/BreadCrumb';
import { SidebarContext } from '../../components/layouts/Main/Main';
import { AdministratorsPage } from './AdministratorsPage';
import { PermissionsPage } from './PermissionsPage';
import { NotificationsPage } from './NotificationsPage';
import { SettingsSidebar } from './SettingsSidebar';
import { AddAdministratorsPage } from './AddAdministratorsPage';
import { EditAdministratorPage } from './EditAdministratorPage';
import { EditPermissionPage } from './EditPermissionPage';
import { AddPermissionPage } from './AddPermissionPage';
import { AdministratorProfilePage } from './AdministratorProfilePage';
import { Support } from './Support';
import { EditPassAdministratorPage } from './EditPassAdministratorPage';

export const BreadcrumbContext = createContext(() => undefined);

const pathsToBeIgnored = [
  '/settings/notifications',
  '/settings/add-administrator',
  '/settings/add-permission'
];

export function SettingsWrapper({history}) {
  const [breadcrumb, setBreadcrumb] = useState({
    rightContent: null,
  });

  const setSidebarChildren = useContext(SidebarContext);

  useEffect(() => {
    if (!pathsToBeIgnored.some(path => history.location.pathname.includes(path))) {
      setSidebarChildren(<SettingsSidebar/>);
    }
  }, []);

  return (
    <div>
      <BreadCrumb
        rightContent={breadcrumb.rightContent}
        display={breadcrumb.display}
        backButton={breadcrumb.backButton}
        limitPaths={3}
        onSearch={breadcrumb.onSearch}
        filter={breadcrumb.filter}
        resetTableSearchAndFilter={breadcrumb.resetTableSearchAndFilter}
      />
      <BreadcrumbContext.Provider value={setBreadcrumb}>
        <div className='card'>
          <Switch>
            <Route exact path='/settings/administrators' component={AdministratorsPage}/>
            <Route path='/settings/add-administrator' component={AddAdministratorsPage}/>
            <Route path='/settings/edit-administrator/:id' component={EditAdministratorPage}/>
            <Route exact path='/settings/edit-pass-administrator/:id' component={EditPassAdministratorPage}/>
            <Route path='/settings/administrators/:id' component={AdministratorProfilePage}/>
            <Route exact path='/settings/permissions' component={PermissionsPage}/>
            <Route path='/settings/add-permission' component={AddPermissionPage}/>
            <Route path='/settings/edit-permission/:id' component={EditPermissionPage}/>
            <Route path='/settings/notifications' component={NotificationsPage}/>
            <Route path='/settings/support' component={Support}/>
          </Switch>
        </div>
      </BreadcrumbContext.Provider>
    </div>
  )
}
