import React, {useContext, useEffect, useState} from 'react';
import * as _ from 'lodash';
import {CategoryLayout} from './CategoryLayout';
import {FormControl} from '../../../components/form/FormContext';
import {NotificationInfo} from './NotificationInfo';
import {CommunicationType} from './CommunicationType';
import Loading from '../../../components/loading/Loading';
import {SidebarContext} from "../../../components/layouts/Main/Main";
import service from '../notificationsService';
import types, {categories} from './types';
import {BreadcrumbContext} from "../SettingsWrapper";
import CategoriesSidebar from "./CategoriesSidebar";

let inDebounce;

const debounce = function (func, delay) {
  const context = this;
  const args = arguments;
  clearTimeout(inDebounce);
  inDebounce = setTimeout(() => func.apply(context, args), delay);
}

export function MagazinesCategory({match, history, location}) {
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const setBreadcrumbRightContent = useContext(BreadcrumbContext);
  const setSidebarChildren = useContext(SidebarContext);

  const saveNotification = (e) => {
    e.preventDefault();
    setLoading(true);
    service.save(notification, types.COLLECT).then(() => {
      setLoading(false);
      history.push('/settings/notifications');
    });
  };

  const handleNotification = (_notification) => {
    // if (_notification.push !== notification.push) {
    //   _notification.alert = !_notification.push;
    // }

    // if (_notification.alert !== notification.alert) {
    //   _notification.push = !_notification.alert;
    // }
    debounce(function() {setNotification(_notification)}, 100);
  };

  useEffect(() => {
    const { id } = match.params;

    if (id === 'add') {
      setNotification({
        type: types.MAGAZINES,
        title: "",
        description: "",
        alert: true,
        push: false
      });
      setLoading(false);
      return;
    } else if (id) {
      service.get(id).then(data => {
        setNotification(data);
        setLoading(false);
      });
    }

    setBreadcrumbRightContent({});

    setSidebarChildren(<CategoriesSidebar categories={categories} />);

  }, []);

  if(loading) {
    return <Loading />
  }

  return (
    <FormControl values={notification} onChange={handleNotification} onSubmit={saveNotification}>
      <CategoryLayout
        title={notification.title}
        description={notification.description}
        icon={notification.type}
      >
        <NotificationInfo/>
        {/* <fieldset className='pb-5'>
          <legend><FormattedMessage id='settings.conditional'/>*</legend>
        </fieldset> */}
        <CommunicationType/>
      </CategoryLayout>
    </FormControl>
  )
}
