
function upperCase(str) {
  return str.toUpperCase();
}

export const cpfMask = value => value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');

export const cnpjMask = value => value.replace(
  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
  '$1.$2.$3/$4-$5'
);

export const thousandNumber = value => value.toString().replace(
  /(\d)(?=(\d{3})+(?!\d))/g,
  '$1.'
);

export const capitalize = value => value.charAt(0).toUpperCase()
  + value.toLowerCase().slice(1).replace(/\s[a-z]/g, upperCase);
