import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";

import { CustomCard } from "../../../components/CustomCard";
import { ContainerTitle, useStyles } from "./styles";
import { ItemPublications } from "./ItemPublications";
import { Typography } from "@material-ui/core";

export default function ListPublications({
  showActions = true,
  currentPublication,
  listAll,
  deletePublication,
}) {
  const scrollRef = useRef(null);
  const classes = useStyles();

  return (
    <CustomCard>
      <div className="mt-4">
        <ContainerTitle>
          <h6 className="card-title dashed-green mb-0">
            <FormattedMessage id="common.publications" />
          </h6>
        </ContainerTitle>
      </div>
      <div
        className=""
        style={{
          maxHeight: "calc(100vh - 300px)",
          overflow: "auto",
          height: "100%",
        }}
        ref={scrollRef}
        data-simplebar
      >
        {listAll.length > 0 ? (
          listAll.map((item, index) => (
            <ItemPublications
              key={index}
              item={item}
              currentPublication={currentPublication}
              showActions={showActions}
              deletePublication={deletePublication}
            />
          ))
        ) : (
          <Typography>
            <FormattedMessage id="publication.form.list_empty" />
          </Typography>
        )}
      </div>
    </CustomCard>
  );
}
